import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';

import ProductState from '@/store/models/ProductState';

import store from '../index';

import modulesNames from '../moduleNames';

import BoundingBox from '@/services/api/models/BoundingBox';
import FeedBalance from '@/services/api/models/FeedBalance';
import PaginationLinks from '@/services/api/models/PaginationLinks';
import PaginationMetadata from '@/services/api/models/PaginationMetadata';
import ProductRequest from '@/services/api/models/requests/ProductRequest';
import TankTimeseries from '@/services/api/models/TankTimeseries';
import {
  FetchEntityStates,
  FetchFeedBalanceGraphData,
  FetchFuelBurnGraphData,
  FetchFuelGraphData,
  FetchWaterGraphData
} from '@/services/api/ProductApi';
import { FetchUsers } from '@/services/api/UsersApi';
import CurrentRoute from '@/store/models/CurrentRoute';
import CurrentUser from '@/store/models/CurrentUser';
import { Constants } from '@/utils/Constants';
import { Product } from '../models/Product';
import User from './User';
import { UsageTimeseries } from '@/services/api/models/UsageTimeseries';
import FeedLevel from '@/services/api/models/FeedLevel';

// Refer to https://github.com/championswimmer/vuex-module-decorators
// We can probably simplify this store a lot.
@Module({ dynamic: true, namespaced: true, store, name: modulesNames.product })
class ProductStore extends VuexModule implements ProductState {
  private _currentProducts?: Product[] = [];
  private _currentProduct?: Product | undefined = undefined;
  private _currentEntityIndex = -1;
  private _currentAllProducts?: Product[] = [];
  private _currentRoute: CurrentRoute = {};
  private _currentUsers: CurrentUser[] = [];
  private _links?: PaginationLinks | null = null;
  private _paginationMeta?: PaginationMetadata | null = null;

  get currentProducts(): Product[] | undefined {
    return this._currentProducts;
  }

  get currentProduct(): Product | undefined {
    return this._currentProduct;
  }

  get currentEntity(): Product | undefined {
    return this._currentProduct?.entities
      ? this._currentProduct?.entities[this._currentEntityIndex]
      : undefined;
  }

  get currentEntityIndex(): number | undefined {
    return this._currentEntityIndex;
  }

  get currentAllProducts(): Product[] | undefined {
    return this._currentAllProducts;
  }

  get currentRoute(): CurrentRoute {
    return this._currentRoute;
  }

  get currentUsers(): CurrentUser[] {
    return this._currentUsers;
  }

  get links(): PaginationLinks | null {
    return this._links ?? null;
  }

  get paginationMeta(): PaginationMetadata | null {
    return this._paginationMeta ?? null;
  }

  @Mutation
  private UPDATE_CURRENT_PRODUCTS(products: Product[]): void {
    this._currentProducts = products;
  }

  @Mutation
  private UPDATE_CURRENT_PRODUCT(product: Product | undefined): void {
    this._currentProduct = product;
  }

  @Mutation
  private UPDATE_CURRENT_ENTITY_INDEX(index: number): void {
    this._currentEntityIndex = index;
  }

  @Mutation
  private UPDATE_CURRENT_ALL_PRODUCTS(products: Product[]): void {
    this._currentAllProducts = products;
  }

  @Mutation
  private UPDATE_CURRENT_ROUTE(route: CurrentRoute): void {
    this._currentRoute = route;
  }

  @Mutation
  private UPDATE_CURRENT_USERS(users: CurrentUser[]): void {
    this._currentUsers = users;
  }

  @Mutation
  private UPDATE_LINKS(links: PaginationLinks): void {
    this._links = links;
  }

  @Mutation
  private UPDATE_PAGINATION_META(paginationMeta: PaginationMetadata): void {
    this._paginationMeta = paginationMeta;
  }

  @Action({ rawError: true })
  public updateProductState(products: Product[]): void {
    this.UPDATE_CURRENT_PRODUCTS(products);
  }

  @Action({ rawError: true })
  public clearProductState(): void {
    this.UPDATE_CURRENT_PRODUCTS([]);
  }

  @Action({ rawError: true })
  public updateCurrentProductState(product: Product | undefined): void {
    this.UPDATE_CURRENT_PRODUCT(product);
  }

  @Action({ rawError: true })
  public clearCurrentProductState(): void {
    this.UPDATE_CURRENT_PRODUCT(undefined);
  }

  @Action({ rawError: true })
  public updateCurrentEntityIndexState(index: number): void {
    this.UPDATE_CURRENT_ENTITY_INDEX(index);
  }

  @Action({ rawError: true })
  public updateAllProductState(products: Product[]): void {
    this.UPDATE_CURRENT_ALL_PRODUCTS(products);
  }

  @Action({ rawError: true })
  public updateRouteState(route: CurrentRoute): void {
    this.UPDATE_CURRENT_ROUTE(route);
  }

  @Action({ rawError: true })
  public updateUsersState(users: CurrentUser[]): void {
    this.UPDATE_CURRENT_USERS(users);
  }

  // @Action({ rawError: true })
  // public async fetchProducts(
  //   request: {
  //     partnerId: number,
  //     productRequest: ProductRequest
  //   }
  // ): Promise<Product[]> {
  //   return await FetchFilteredProducts(request).then(productResponse => {

  //     const products = productResponse.data.map((product: ProductAPI) => {
  //       return { ...product, metrics: JSON.parse(product.metrics) };
  //     });
  //     this.updateProductState(products);
  //     return products;
  //   });
  // }

  @Action({ rawError: true })
  public async fetchEntityStates(request: {
    partnerId: number;
    productRequest: ProductRequest;
  }): Promise<Product[]> {
    return await FetchEntityStates(request).then((entityState: any) => {
      this.updateProductState(entityState);

      return entityState;
    });
  }

  @Action({ rawError: true })
  public async fetchAllEntityStates(productType: string): Promise<Product[]> {
    const allProductBoundingBox: BoundingBox = Constants.ALLPRODUCTBOUNDINGBOX;
    return await FetchEntityStates({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      productRequest: {
        boundingBox: allProductBoundingBox,
        productType: productType // This shold take productType as a param to generalise to one view.
      }
    }).then((entityState: any) => {
      this.updateAllProductState(entityState);
      return entityState;
    });
  }

  @Action({ rawError: true })
  public async fetchUsers(request: {
    partnerId: number;
  }): Promise<CurrentUser[]> {
    return await FetchUsers(request).then((users: any) => {
      this.updateUsersState(users);
      return users;
    });
  }

  @Action({ rawError: true })
  public async fetchActivityGraphData(request: {
    appName: string;
    entityId: number;
    to: number;
    from: number;
    disabled: boolean;
    source: string;
  }): Promise<TankTimeseries | { balance: FeedBalance[]; level: FeedLevel[] }> {
    if (request.appName == Constants.PRODUCT_TYPE_FEED) {
      return await FetchFeedBalanceGraphData(
        request.entityId.toString(),
        request.from.toString(),
        request.to.toString(),
        request.disabled,
        'activity',
        request.source
      );
    } else {
      return await FetchFuelBurnGraphData(
        request.entityId.toString(),
        request.from.toString(),
        request.to.toString(),
        request.disabled,
        request.source
      );
    }
  }

  @Action({ rawError: true })
  public async fetchUsageGraphData(request: {
    appName: string;
    entityId: number;
    to: number;
    from: number;
    disabled: boolean;
    source: string;
    series?: string;
  }): Promise<UsageTimeseries> {
    switch (request.appName) {
      case Constants.PRODUCT_TYPE_FEED:
        return (
          (
            await FetchFeedBalanceGraphData(
              request.entityId.toString(),
              request.from.toString(),
              request.to.toString(),
              request.disabled,
              'usage',
              request.source,
              request.series
            )
          ).balance ?? []
        );
      case Constants.PRODUCT_TYPE_WATER:
        return await FetchWaterGraphData(
          request.entityId.toString(),
          request.from.toString(),
          request.to.toString(),
          request.disabled,
          request.source
        );
      default:
        return await FetchFuelGraphData(
          request.entityId.toString(),
          request.from.toString(),
          request.to.toString(),
          request.disabled,
          request.source
        );
    }
  }
}

export default getModule(ProductStore);
