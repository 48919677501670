import { Product } from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import { getListItem, setListItem } from '../LocalStorageUtils';
import { SelectedRoute } from './SelectedRoute';

export class MilkSelectedRoute extends SelectedRoute {
  constructor() {
    super('milk');
  }

  public addToRoute(item: string): string {
    if (!this.checked.includes(item)) {
      if (
        !this.checked.includes('productChecked-' + item.split('-')[1] + '-0000')
      ) {
        this.checked.push('productChecked-' + item.split('-')[1] + '-0000');
      }
      this.checked.push(item);
      this.updateStoreRoute(this.checked);
      return `${this.getNameOfProduct(item)} has been added to your route`;
    } else {
      if (
        this.checked.filter(check => check.split('-')[1] == item.split('-')[1])
          .length == 2
      ) {
        this.removeAllEntitesFromChecked(item.split('-')[1]);
      } else {
        this.checked = this.checked.filter(check => check != item);
      }
      this.updateStoreRoute(this.checked);
      return `${this.getNameOfProduct(item)} has been removed to your route`;
    }
  }

  public toggleAllFiltered(selected: boolean, products: Product[]) {
    if (selected) {
      products.forEach((prod: Product) => {
        if (
          !this.checked.includes('productChecked-' + prod.productId + '-0000')
        ) {
          this.checked.push('productChecked-' + prod.productId + '-0000');
        }
        this.addAllEntitesToChecked(prod.productId.toString());
      });
    } else {
      products.forEach((prod: Product) =>
        this.removeAllEntitesFromChecked(prod.productId.toString())
      );
    }
    ProductStore.updateRouteState({ milk: this.checked });
    setListItem('milkRoute', this.checked);
  }

  public update() {
    this.routePlanProducts =
      ProductStore.currentRoute.milk?.map(item => {
        return +item.split('-')[1];
      }) ?? [];
    this.routePlanEntities =
      ProductStore.currentRoute.milk?.map(item => {
        return +item.split('-')[2] == 0
          ? `all${item.split('-')[1]}`
          : item.split('-')[2];
      }) ?? [];
    this.checked = [...(ProductStore.currentRoute.milk ?? [])];
    if (this.checked.length == 0) {
      this.checked = getListItem('milkRoute');
      ProductStore.updateRouteState({ milk: this.checked });
    }
  }

  public get updatedValue(): string {
    const add =
      this.checked.filter(
        item => !ProductStore.currentRoute.milk?.includes(item)
      ) ?? [];
    const remove =
      ProductStore.currentRoute.milk?.filter(
        item => !this.checked.includes(item)
      ) ?? [];
    if (add.length != 0 || remove.length != 0) {
      let updatedValue;
      if (add.length != 0) {
        if (add[0].endsWith('0000')) {
          this.addAllEntitesToChecked(add[0].split('-')[1]);
        } else if (!add[0].endsWith('0000')) {
          const vatDetails = add[0].split('-');
          vatDetails[2] = '0000';
          const productLevelEntity = vatDetails.join('-');
          if (!ProductStore.currentRoute.milk?.includes(productLevelEntity)) {
            this.checked.push(productLevelEntity);
          }
        }
        updatedValue = `${this.getNameOfProduct(
          add[0]
        )} has been added to your route`;
      } else {
        if (remove[0].endsWith('0000')) {
          this.removeAllEntitesFromChecked(remove[0].split('-')[1]);
        } else if (!remove[0].endsWith('0000')) {
          const vatDetails = remove[0].split('-');
          const productSuffix = `${vatDetails[0]}-${vatDetails[1]}`;
          if (
            (
              this.checked.filter(item => item.indexOf(productSuffix) != -1) ??
              []
            ).length == 1
          ) {
            this.removeAllEntitesFromChecked(remove[0].split('-')[1]);
          }
        }
        updatedValue = `${this.getNameOfProduct(
          remove[0]
        )} has been removed from your route`;
      }
      ProductStore.updateRouteState({ milk: this.checked });
      setListItem('milkRoute', this.checked);
      return updatedValue;
    }
    return '';
  }
}
