export enum AllowedField { //fields that all product types use
  CHECKBOX = 'checkbox',
  SITE = 'siteDefault',
  VOLUME_PERCENT_BAR = 'volumePercentBar',
  PERCENT_FULL = 'percentFullDefault',
  WARNINGS = 'alertsDefault',
  ADDRESS = 'address',
  ENTITY_STATUS = 'entityStatus',
  ENTITY_STATUS_CHANGE_DATE = 'entityStatusChangeDate',
  CONTRIBUTION_TIER = 'contributionTier',
  NUM_ENTITIES = 'numberEntities',
  REPAIR_ICON = 'repairIcon',
  ACTION_TAKEN = 'actionTaken',
  ENTITY_NAME = 'entityName'
}

export enum MilkAllowedField {
  SUPPLIER_NUMBER = 'supplyNumDefault',
  AGE = 'ageDefault',
  VOLUME = 'volumeLDefault',
  LAST_PICKUP = 'lastPickupDefault',
  LAST_MILKING = 'lastMilkingDefault',
  TEMPERATURE = 'temperatureDefault',
  COLLAPSE = 'collapseToggle',
  VOLUME_CAPACITY = 'volumeCapacity',
  COMPLIANCE_METHOD = 'compliance'
}

export enum FuelAllowedField {
  CUSTOM_ID = 'idDefault',
  TYPE = 'typeDefault',
  VOLUME = 'volumeLDefault',
  FILL_VOLUME = 'fillVolumeDefault',
  USAGE = 'usageDefault',
  REMAINING = 'remainingDefault',
  LARGEST_TANK = 'largestTank',
  ALERT_TIME = 'alertTime'
}

export enum FeedAllowedField {
  CUSTOM_ID = 'idDefault',
  TYPE = 'typeDefault',
  VOLUME = 'volumeLDefault',
  FILL_VOLUME = 'fillVolumeDefault',
  USAGE = 'usageDefault',
  REMAINING = 'remainingDefault',
  LARGEST_TANK = 'largestTank',
  LAST_REFILL_SIZE = 'lastRefillSize',
  LAST_REFILL_DATE = 'lastRefillDate',
  ALERT_TIME = 'alertTime'
}

export enum WaterAllowedField {
  CUSTOM_ID = 'idDefault',
  CURRENT_USAGE = 'currentUsageDefault',
  USAGE_TODAY = 'usageTodayDefault',
  USAGE_YESTERDAY = 'usageYesterdayDefault',
  REMAINING = 'remainingDefault',
  PERCENT = 'percentDefault',
  VOLUME_CAPACITY = 'volumeCapacity',
  VOLUME = 'volumeLDefault'
}

export enum WaterTankAllowedField {
  SITE = 'siteDefault',
  CUSTOM_ID = 'idDefault',
  REMAINING = 'remainingDefault',
  PERCENT = 'percentDefault',
  VOLUME = 'volumeLDefault',
  WARNINGS = 'alertsDefault',
  VOLUME_CAPACITY = 'volumeCapacity',
  VOLUME_PERCENT_BAR = 'volumePercentBar'
}

export enum WaterMeterAllowedField {
  SITE = 'siteDefault',
  CUSTOM_ID = 'idDefault',
  CURRENT_USAGE = 'currentUsageDefault',
  USAGE_TODAY = 'usageTodayDefault',
  USAGE_YESTERDAY = 'usageYesterdayDefault'
}

export type anyAllowedField =
  | AllowedField
  | MilkAllowedField
  | FuelAllowedField
  | FeedAllowedField
  | WaterAllowedField;
