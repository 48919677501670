import User from '@/store/modules/User';

export const isUat = (): boolean => {
  const env = process.env.VUE_APP_API_URL;
  if (env.indexOf('uat') > -1) {
    return true;
  } else {
    return false;
  }
};

export const isOrgUat = (): boolean => {
  const env = process.env.VUE_APP_API_URL;
  if (User?.orgId && env.indexOf('uat') > -1) {
    if (User.orgId == 2 || User.orgId == 324 || User.orgId == 323) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

export default isUat;
