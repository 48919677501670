import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventChangeOrg extends GAEvent {
  public eventName = 'change-org';
  public prevOrgId?: number;
  public nextOrgId?: number;

  constructor(prevOrgId?: number, nextOrgId?: number) {
    super();
    this.prevOrgId = prevOrgId;
    this.nextOrgId = nextOrgId;
  }
}
