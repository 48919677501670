import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';

import UserState from '@/store/models/UserState';
import CurrentUser from '@/store/models/CurrentUser';

import {
  AttemptLogin,
  GetUser,
  Logout,
  ChangePassword,
  ForgotPassword,
  ResetPassword,
  Verify,
  ChangeUserDetails,
  ChangeEmail,
  GetUserInformation,
  RequestDeleteAccount,
  ConfirmPassword, DeleteAccount
} from '@/services/api/LoginApi';
import LoginResponse from '@/services/api/models/responses/LoginResponse';

import store from '../index';

import modulesNames from '../moduleNames';

import LocalStorageUtils from '@/utils/LocalStorageUtils';
import dayjs from 'dayjs';
import { ComplianceSettings } from '../models/MilkScore';
import RequestDeleteAccountRequest from '@/services/api/models/requests/RequestDeleteAccountRequest';
import ConfirmPasswordRequest from '@/services/api/models/requests/ConfirmPasswordRequest';
import DeleteAccountRequest from '@/services/api/models/requests/DeleteAccountRequest';

// Refer to https://github.com/championswimmer/vuex-module-decorators
// We can probably simplify this store a lot.
@Module({ dynamic: true, namespaced: true, store, name: modulesNames.user })
class User extends VuexModule implements UserState {
  public _currentUser?: CurrentUser | null = null;
  public _token?: LoginResponse = this.token;
  public _orgIndex = this.orgIndex ?? 0;

  get currentUser(): CurrentUser | undefined | null {
    return this._currentUser;
  }

  get token(): LoginResponse {
    const token = {
      ...this._token,
      token: LocalStorageUtils.getItem('AUTH_TOKEN') ?? '',
      authType: 'bearer',
      expiresAt: +(LocalStorageUtils.getItem('EXPIRES_AT') ?? 0),
      expiresIn: 0,
      orgs: JSON.parse(LocalStorageUtils.getItem('ORGS') || '[]')
    };
    return token;
  }

  get orgIndex(): number {
    const index = +(LocalStorageUtils.getItem('ORG_INDEX') ?? 0);
    return index;
  }

  get orgId(): number {
    if (this._token?.orgs[this.orgIndex]) {
      return this._token?.orgs[this.orgIndex].orgId ?? 0;
    } else {
      return 0;
    }
  }

  get isAuthenticated(): boolean {
    if (this._token?.expiresAt && this._token.expiresAt < dayjs().unix()) {
      return false;
    } else {
      return !!this._currentUser; // cast current user to a boolean
    }
  }

  @Mutation
  public SET_CURRENT_USER(user?: CurrentUser): void {
    this._currentUser = user;
  }

  @Mutation
  public CLEAR_TOKEN(): void {
    this._token = undefined;
    LocalStorageUtils.removeItem('AUTH_TOKEN');
    LocalStorageUtils.removeItem('EXPIRES_AT');
    LocalStorageUtils.removeItem('ORGS');
  }

  @Mutation
  public SET_TOKEN(loginResponse?: LoginResponse): void {
    if (loginResponse) {
      this._token = loginResponse;
      LocalStorageUtils.setItem('AUTH_TOKEN', loginResponse.token);
      LocalStorageUtils.setItem(
        'EXPIRES_AT',
        (dayjs().unix() + loginResponse.expiresIn).toString()
      );
      LocalStorageUtils.setItem('ORGS', JSON.stringify(loginResponse.orgs));
    } else {
      this.CLEAR_TOKEN();
    }
  }

  @Mutation
  public UPDATE_ORG_INDEX(index: number): void {
    LocalStorageUtils.setItem('ORG_INDEX', index.toString());
    this._orgIndex = index;
  }

  @Action({ rawError: true })
  public updateOrgIndex(index: number): void {
    this.UPDATE_ORG_INDEX(index);
  }

  @Action({ rawError: true })
  public async login(params: {
    email: string;
    password: string;
  }): Promise<void> {
    await this.fetchToken(params);
  }

  @Action({ rawError: true })
  public async changePassword(params: {
    email: string;
    password: string;
    newPassword: string;
    passwordConfirm: string;
    id: number;
  }): Promise<any> {
    await AttemptLogin({ email: params.email, password: params.password });
    const response = await ChangePassword(params.id, params.newPassword);
    return response;
  }

  @Action({ rawError: true })
  public async changeUserDetails(params: {
    id: number;
    params: {};
  }): Promise<any> {
    const response = await ChangeUserDetails(params.id, params.params);
    return response;
  }

  @Action({ rawError: true })
  public async resetPassword(params: {
    email: string;
    password: string;
    token: string;
  }): Promise<void> {
    const response = await ResetPassword(params);
    this.SET_TOKEN({
      ...response,
      expiresAt: response.expiresIn + dayjs().unix()
    });
    await this.fetchCurrentUser();
  }

  @Action({ rawError: true })
  public async forgotPassword(params: { email: string }): Promise<void> {
    await ForgotPassword({
      email: params.email
    });
  }

  @Action({ rawError: true })
  public async verify(params: {
    userId: string;
    expires: string;
    signature: string;
    password: string;
  }): Promise<void> {
    const response = await Verify(params);
  }
  @Action({ rawError: true })
  public async changeEmail(params: {
    userId: string;
    expires: string;
    signature: string;
  }): Promise<void> {
    const response = await ChangeEmail(params);
  }

  @Action({ rawError: true })
  public async fetchToken(params: {
    email: string;
    password: string;
  }): Promise<void> {
    const response = await AttemptLogin(params);
    this.SET_TOKEN({
      ...response,
      expiresAt: response.expiresIn + dayjs().unix()
    });
    this.UPDATE_ORG_INDEX(this._orgIndex ?? 0);
    await this.fetchCurrentUser();
  }

  @Action({ rawError: true })
  public async fetchCurrentUser(): Promise<void> {
    try {
      const user: CurrentUser = await GetUserInformation();
      this.SET_CURRENT_USER(user);
    } catch {
      this.logout();
    }
  }

  @Action({ rawError: true })
  public async logout(): Promise<void> {
    await Logout();
    this.CLEAR_TOKEN();
    this.SET_CURRENT_USER(undefined);
    this.UPDATE_ORG_INDEX(0);
  }

  @Action({ rawError: true })
  public async tryRefreshUser(): Promise<void> {
    if (this._token?.token && !this.isAuthenticated) {
      await this.fetchCurrentUser();
      if (!this.isAuthenticated) {
        this.logout();
      }
    }
  }

  @Action({ rawError: true })
  public async confirmPassword(
    params: ConfirmPasswordRequest
  ): Promise<boolean> {
    return await ConfirmPassword(params);
  }

  @Action({ rawError: true })
  public async requestDeleteAccount(params: {
    userId: number;
    params: RequestDeleteAccountRequest;
  }): Promise<boolean> {
    return await RequestDeleteAccount(params.userId, params.params);
  }

  @Action({ rawError: true })
  public async deleteAccount(params: {
    userId: number;
    params: DeleteAccountRequest;
  }): Promise<boolean> {
    return await DeleteAccount(params.userId, params.params);
  }
}

export default getModule(User);
