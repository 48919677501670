import { TableColumnFormatters } from '@/store/models/TableColumn';
import { getAppConstant, getAppName } from './AppName';
import { getObjectItem, setObjectItem } from './LocalStorageUtils';
import { getSpecificPageName } from './PageUtils';
import { getPageTableFieldList } from './table-fields/TableFieldLists';
import { StringKeyObject } from './types/StringKeyObject';

export function SetVisibilityOfTableColumns(columns: TableColumnFormatters[]) {
  const columnsObject: {
    [key: string]: TableColumnFormatters;
  } = {};
  columns.forEach(column => {
    columnsObject[column.name] = column;
  });
  const columnVisibilityFromItem = getObjectItem(
    `${getAppName(false)}${getSpecificPageName()}TableColumnsVisibility`
  );

  if (columnVisibilityFromItem) {
    //There is a saved visibility entry for this page
    Object.entries(columnsObject).forEach(([key, field]) => {
      const visibility =
        (columnVisibilityFromItem as StringKeyObject)[field.name] ?? true;
      columnsObject[key].visible = visibility;
    });
  } else {
    //Use default visibility
    //get page table field list
    //if it is set visibility based on if the column name is in the list
    const pageSpecificFields = getPageTableFieldList()[getAppConstant()];
    Object.entries(columnsObject).forEach(([key, field]) => {
      //ew gross I had to use any... not sure if there a way to do it page specific
      const visibility = pageSpecificFields.includes(field.name as any);
      columnsObject[key].visible = visibility;
    });
  }

  return Object.values(columnsObject);
}

export function UpdateTableColumnsVisibility(
  columns: StringKeyObject | TableColumnFormatters[]
) {
  let columnsVisibility: StringKeyObject = {};
  if (Array.isArray(columns)) {
    columns.forEach(column => {
      columnsVisibility[column.name] = column.visible;
    });
  } else {
    columnsVisibility = columns;
  }

  setObjectItem(
    `${getAppName(false)}${getSpecificPageName()}TableColumnsVisibility`,
    columnsVisibility
  );
}
