/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FeedMetrics, Product } from '@/store/models/Product';
import { TableColumnFormatters } from '@/store/models/TableColumn';
import {
  formatFillVolumeDisplay,
  formatDailyUseDisplay,
  formatDaysTillEmptyDisplay,
  formatLastRefillVolumeDisplay,
  formatLastRefillDisplay,
  formatAvailableCapacityDisplay,
  formatFeedDailyUseDisplay
} from '../formatters/DisplayValueFormatters';

import {
  GetVisibleCustomColumnsFormatters,
  tableColumnFormatters
} from './TableFields';
import { AllowedField, FeedAllowedField } from '../types/AllowedFields';
import Vue from 'vue';
import { GetDefaultTableColumns } from '../TableColumns';
import { SetVisibilityOfTableColumns } from '../TableColumnVisibility';
import { getEntityName, getPreferredMetricWord, getUnit } from '../AppName';
import { toTitleCase } from '../FormattingUtils';
import { getFormattedFeedAmount } from '../FeedUtils';
import { relativeDay } from '../DateUtils';

export const feedSpecificTableColumnsFormatters: {
  [key in FeedAllowedField]: TableColumnFormatters;
} = {
  [FeedAllowedField.CUSTOM_ID]: {
    name: FeedAllowedField.CUSTOM_ID,
    label: () => 'Id',
    visible: true,
    width: 100,
    filterable: false,
    searchable: true,
    sortable: true,
    searchKey: (item: Product) => {
      return item.partnerDefinedId;
    },
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by partner defined id and entities by entity id
      return sortType == 'child' ? item.partnerDefinedId : item.entityId;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item.partnerDefinedId ? item.partnerDefinedId : '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return ''; //blank
    },
    textStyling: 'textOverflowEllipsis'
  },
  [FeedAllowedField.TYPE]: {
    name: FeedAllowedField.TYPE,
    label: () => 'Type',
    visible: true,
    width: 100,
    filterable: true,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return entity.metrics.feedType ?? '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return entity.metrics.feedType ?? '';
    }
  },
  [FeedAllowedField.VOLUME]: {
    name: FeedAllowedField.VOLUME,
    label: () => toTitleCase(getPreferredMetricWord()),
    visible: true,
    width: 200,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min volume of entities
      //Sort entities by volume value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as FeedMetrics).percentFull;
      } else {
        return (item.entities[0].metrics as FeedMetrics).percentFull;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return ''; //SPECIAL - volume with progress bar
    },
    childFormattedValue: (item: Product, entity: any) => {
      return ''; //SPECIAL - volume with progress bar
    },
    parentComponent: () => 'VolumeCapacityField',
    childComponent: () => 'VolumeCapacityField',
    textStyling: 'text-center'
  },
  [FeedAllowedField.FILL_VOLUME]: {
    name: FeedAllowedField.FILL_VOLUME,
    label: () => 'Available Capacity',
    visible: true,
    width: 200,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min fill volume of entities
      //Sort entities by fill volume value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as FeedMetrics).fillVolume;
      } else {
        return (item.entities[0].metrics as FeedMetrics).fillVolume;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatAvailableCapacityDisplay(entity);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatAvailableCapacityDisplay(entity);
    }
  },
  [FeedAllowedField.LAST_REFILL_SIZE]: {
    name: FeedAllowedField.LAST_REFILL_SIZE,
    label: () => 'Last Refill',
    visible: true,
    width: 120,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min fill volume of entities
      //Sort entities by fill volume value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as FeedMetrics).lastDeliveryVolume;
      } else {
        return (item.entities[0].metrics as FeedMetrics).lastDeliveryVolume;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatLastRefillVolumeDisplay(entity);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatLastRefillVolumeDisplay(entity);
    }
  },
  [FeedAllowedField.LAST_REFILL_DATE]: {
    name: FeedAllowedField.LAST_REFILL_DATE,
    label: () => 'Last Refill Date',
    visible: true,
    width: 160,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min fill volume of entities
      //Sort entities by fill volume value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as FeedMetrics).lastDeliveryTimestamp;
      } else {
        return (item.entities[0].metrics as FeedMetrics).lastDeliveryTimestamp;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatLastRefillDisplay(entity);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatLastRefillDisplay(entity);
    }
  },
  [FeedAllowedField.USAGE]: {
    name: FeedAllowedField.USAGE,
    label: () => 'Usage',
    visible: true,
    width: 100,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min fill volume of entities
      //Sort entities by fill volume value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as FeedMetrics).shortDailyUse;
      } else {
        return (item.entities[0].metrics as FeedMetrics).shortDailyUse;
      }
    },

    parentFormattedValue: (item: Product, entity: any) => {
      return formatFeedDailyUseDisplay(entity);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatFeedDailyUseDisplay(entity);
    }
  },
  [FeedAllowedField.REMAINING]: {
    name: FeedAllowedField.REMAINING,
    label: () => 'Remaining',
    visible: true,
    width: 120,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min days remaining of entities
      //Sort entities by daus remaining value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as FeedMetrics).daysTillEmpty;
      } else {
        return (item.entities[0].metrics as FeedMetrics).daysTillEmpty;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatDaysTillEmptyDisplay(entity).toString();
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatDaysTillEmptyDisplay(entity).toString();
    }
  },
  [FeedAllowedField.LARGEST_TANK]: {
    name: FeedAllowedField.LARGEST_TANK,
    label: () => `Largest ${getEntityName(true)}`,
    visible: true,
    width: 140,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      return item && item.largestCapacity ? item.largestCapacity : undefined;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item && item.largestCapacity
        ? getFormattedFeedAmount(item.largestCapacity, item, true)
        : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    }
  },
  [FeedAllowedField.ALERT_TIME]: {
    name: FeedAllowedField.ALERT_TIME,
    label: () => 'Time',
    visible: true,
    width: 140,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      return item && (item.metrics as FeedMetrics)?.alertTs
        ? (item.metrics as FeedMetrics).alertTs
        : undefined;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item && (item.metrics as FeedMetrics)?.alertTs
        ? relativeDay((item.metrics as FeedMetrics).alertTs)
        : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return item && (item.metrics as FeedMetrics).alertTs
        ? relativeDay((item.metrics as FeedMetrics).alertTs)
        : '-';
    }
  }
};

export const feedTableColumnsFormatters = {
  ...tableColumnFormatters,
  ...feedSpecificTableColumnsFormatters
};

export function GetFeedTableColumnFormatters(
  fields: (FeedAllowedField | AllowedField)[]
) {
  const baseColumnsWithFormatters = fields.map(column => {
    return feedTableColumnsFormatters[column];
  });
  return baseColumnsWithFormatters;
}

export async function GetVisibleFeedTableColumnFormatters(
  fields: (FeedAllowedField | AllowedField)[],
  addCustomFields: boolean
) {
  const defaultColumnsWithVisibility = GetDefaultTableColumns();
  const baseColumnsWithFormatters = SetVisibilityOfTableColumns(
    fields.map(column => {
      return feedTableColumnsFormatters[column];
    })
  ).filter(column => {
    if (column.name == AllowedField.VOLUME_PERCENT_BAR) {
      return defaultColumnsWithVisibility[AllowedField.PERCENT_FULL].visible;
    }
    return column.visible;
  });
  if (addCustomFields) {
    const customColumnsWithFormatters = await GetVisibleCustomColumnsFormatters();
    return [...baseColumnsWithFormatters, ...customColumnsWithFormatters];
  } else {
    return baseColumnsWithFormatters;
  }
}

export function GetDefaultFeedColumns() {
  return Object.fromEntries(
    Object.entries(feedTableColumnsFormatters).filter(column => {
      return column[0].includes('Default');
    })
  );
}
