import ProductAPI from '@/services/api/models/ProductAPI';
import { Product, WaterTankMetrics } from '@/store/models/Product';
import { TableColumnFormatters } from '@/store/models/TableColumn';
import CustomStore from '@/store/modules/CustomStore';
import { getAppName, isApp } from './AppName';
import { FeedFiltersClass } from './classes/FeedFiltersClass';
import { FuelFiltersClass } from './classes/FuelFiltersClass';
import { MilkFiltersClass } from './classes/MilkFiltersClass';
import { MilkSelectedRoute } from './classes/MilkSelectedRoute';
import { SelectedRoute } from './classes/SelectedRoute';
import { WaterFiltersClass } from './classes/WaterFiltersClass';
import { Constants } from './Constants';
import {
  formatEntitiesAPI,
  formatEntityMetrics,
  groupProducts
} from './formatters/EntitiesFormatter';
import {
  formatFeedEntitiesAPI,
  formatFeedProducts
} from './formatters/FeedEntitiesFormatter';
import {
  formatFuelEntitiesAPI,
  formatFuelProducts
} from './formatters/FuelEntitiesFormatter';
import {
  formatMilkEntitiesAPI,
  formatMilkProducts
} from './formatters/MilkEntitiesFormatter';
import {
  formatWaterEntitiesAPI,
  formatWaterProducts
} from './formatters/WaterEntitiesFormatter';
import { isPage } from './PageUtils';
import {
  feedSpecificTableColumnsFormatters,
  GetFeedTableColumnFormatters,
  GetVisibleFeedTableColumnFormatters
} from './table-fields/FeedTableFields';
import {
  fuelSpecificTableColumnsFormatters,
  GetFuelTableColumnFormatters,
  GetVisibleFuelTableColumnFormatters
} from './table-fields/FuelTableFields';
import {
  GetMilkTableColumnFormatters,
  GetVisibleMilkTableColumnFormatters,
  milkSpecificTableColumnsFormatters
} from './table-fields/MilkTableFields';
import {
  alertsViewFields,
  customerViewFields,
  productViewFields
} from './table-fields/TableFieldLists';
import {
  GetEntityCustomColumnsWithFormatters,
  GetSiteCustomColumnsWithFormatters,
  GetTableColumnFormatters,
  GetVisibleCustomColumnsFormattersDict,
  GetVisibleTableColumnFormatters,
  tableColumnFormatters
} from './table-fields/TableFields';
import { formatComment } from './TableFormatters';
import {
  AllowedField,
  FeedAllowedField,
  FuelAllowedField,
  MilkAllowedField,
  WaterAllowedField
} from './types/AllowedFields';
import { productType } from './types/ProductType';
import {
  GetVisibleWaterTableColumnFormatters,
  GetWaterTableColumnFormatters,
  waterSpecificTableColumnsFormatters
} from './table-fields/WaterTableFields';
import { returnOnEntityAccess } from './WaterUtils';
import {
  formatPercentWaterFullDisplay,
  formatWaterTankUnit,
  formatWaterUllageDisplay,
  formatWaterVolumeDisplay
} from './formatters/DisplayValueFormatters';

export function formatAppProducts(products: Product[]) {
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      return formatMilkProducts(products);
    case Constants.PRODUCT_TYPE_FUEL:
      return formatFuelProducts(products);
    case Constants.PRODUCT_TYPE_WATER:
      return formatWaterProducts(products);
    case Constants.PRODUCT_TYPE_FEED:
      return formatFeedProducts(products);
    default:
      return groupProducts(products);
  }
}

export async function getAppTableColumnFormatter(
  fieldName: string
): Promise<TableColumnFormatters | null> {
  const customColumns = await GetVisibleCustomColumnsFormattersDict();
  if (Object.keys(customColumns).includes(fieldName)) {
    return customColumns[fieldName] ?? null;
  }
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      if (Object.values(MilkAllowedField).includes(fieldName as any)) {
        return (
          milkSpecificTableColumnsFormatters[fieldName as MilkAllowedField] ??
          null
        );
      } else {
        return tableColumnFormatters[fieldName as AllowedField] ?? null;
      }
    case Constants.PRODUCT_TYPE_FUEL:
      if (Object.values(FuelAllowedField).includes(fieldName as any)) {
        return (
          fuelSpecificTableColumnsFormatters[fieldName as FuelAllowedField] ??
          null
        );
      } else {
        return tableColumnFormatters[fieldName as AllowedField] ?? null;
      }
    case Constants.PRODUCT_TYPE_FEED:
      if (Object.values(FeedAllowedField).includes(fieldName as any)) {
        return (
          feedSpecificTableColumnsFormatters[fieldName as FeedAllowedField] ??
          null
        );
      } else {
        return tableColumnFormatters[fieldName as AllowedField] ?? null;
      }
    case Constants.PRODUCT_TYPE_WATER:
      if (Object.values(WaterAllowedField).includes(fieldName as any)) {
        return (
          waterSpecificTableColumnsFormatters[fieldName as WaterAllowedField] ??
          null
        );
      } else {
        return tableColumnFormatters[fieldName as AllowedField] ?? null;
      }
    default:
      return tableColumnFormatters[fieldName as AllowedField] ?? null;
  }
}

export async function getVisibleAppTableColumnFormatters(
  fields:
    | typeof productViewFields
    | typeof customerViewFields
    | typeof alertsViewFields,
  includeCustomFields: boolean
) {
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      return await GetVisibleMilkTableColumnFormatters(
        fields[Constants.PRODUCT_TYPE_MILK],
        includeCustomFields
      );
    case Constants.PRODUCT_TYPE_FUEL:
      return await GetVisibleFuelTableColumnFormatters(
        fields[Constants.PRODUCT_TYPE_FUEL],
        includeCustomFields
      );
    case Constants.PRODUCT_TYPE_FEED:
      return await GetVisibleFeedTableColumnFormatters(
        fields[Constants.PRODUCT_TYPE_FEED],
        includeCustomFields
      );
    case Constants.PRODUCT_TYPE_WATER:
      return await GetVisibleWaterTableColumnFormatters(
        fields[Constants.PRODUCT_TYPE_WATER],
        includeCustomFields
      );
    default:
      return await GetVisibleTableColumnFormatters(
        fields[Constants.PRODUCT_TYPE_GENERIC],
        includeCustomFields
      );
  }
}

export function getAppTableColumnWithFormatters(
  fields:
    | typeof productViewFields
    | typeof customerViewFields
    | typeof alertsViewFields
): TableColumnFormatters[] {
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      return GetMilkTableColumnFormatters(fields[Constants.PRODUCT_TYPE_MILK]);
    case Constants.PRODUCT_TYPE_FUEL:
      return GetFuelTableColumnFormatters(fields[Constants.PRODUCT_TYPE_FUEL]);
    case Constants.PRODUCT_TYPE_FEED:
      return GetFeedTableColumnFormatters(fields[Constants.PRODUCT_TYPE_FEED]);
    case Constants.PRODUCT_TYPE_WATER: {
      return GetWaterTableColumnFormatters(
        fields[Constants.PRODUCT_TYPE_WATER]
      );
    }
    default:
      return GetTableColumnFormatters(fields[Constants.PRODUCT_TYPE_GENERIC]);
  }
}

export async function getAppTableColumnWithCustomsWithFormatters(
  fields:
    | typeof productViewFields
    | typeof customerViewFields
    | typeof alertsViewFields
) {
  const defaultColumns = getAppTableColumnWithFormatters(fields);
  const siteCustoms = await GetSiteCustomColumnsWithFormatters();
  const entityCustoms = await GetEntityCustomColumnsWithFormatters();
  return { defaultColumns, siteCustoms, entityCustoms };
}

export function getAppFilter() {
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      return new MilkFiltersClass();
    case Constants.PRODUCT_TYPE_FUEL:
      return new FuelFiltersClass();
    case Constants.PRODUCT_TYPE_WATER:
      return new WaterFiltersClass();
    case Constants.PRODUCT_TYPE_FEED:
      return new FeedFiltersClass();
    default:
      return new MilkFiltersClass();
  }
}

export function getAppDefaultTableFields(
  fields:
    | typeof productViewFields
    | typeof customerViewFields
    | typeof alertsViewFields
) {
  const baseDefaultFields = Object.values(AllowedField).filter(field =>
    field.includes('Default')
  );
  const appFields = {
    [Constants.PRODUCT_TYPE_MILK]: [
      ...new Set([
        ...fields[Constants.PRODUCT_TYPE_MILK],
        ...baseDefaultFields,
        ...Object.values(MilkAllowedField).filter(field =>
          field.includes('Default')
        )
      ])
    ] as (AllowedField | MilkAllowedField)[],
    [Constants.PRODUCT_TYPE_FUEL]: [
      ...new Set([
        ...fields[Constants.PRODUCT_TYPE_FUEL],
        ...baseDefaultFields,
        ...Object.values(FuelAllowedField).filter(field =>
          field.includes('Default')
        )
      ])
    ] as (AllowedField | FuelAllowedField)[],
    [Constants.PRODUCT_TYPE_FEED]: [
      ...new Set([
        ...fields[Constants.PRODUCT_TYPE_FEED],
        ...baseDefaultFields,
        ...Object.values(FeedAllowedField).filter(field =>
          field.includes('Default')
        )
      ])
    ] as (AllowedField | FeedAllowedField)[],
    [Constants.PRODUCT_TYPE_WATER]: [
      ...new Set([
        ...fields[Constants.PRODUCT_TYPE_WATER],
        ...baseDefaultFields,
        ...Object.values(WaterAllowedField).filter(field =>
          field.includes('Default')
        )
      ])
    ] as (AllowedField | WaterAllowedField)[],
    [Constants.PRODUCT_TYPE_GENERIC]: [
      ...new Set([
        ...fields[Constants.PRODUCT_TYPE_GENERIC],
        ...baseDefaultFields
      ])
    ] as AllowedField[]
  };

  return appFields;
}

export function getAppRoute() {
  return isApp(Constants.PRODUCT_TYPE_MILK)
    ? new MilkSelectedRoute()
    : new SelectedRoute(getAppName(false) as productType);
}

export function getAppMapTag(mapTag: string | undefined, product: Product) {
  function getWaterTankTag() {
    switch (mapTag) {
      case 'volume':
        return formatWaterVolumeDisplay(product);
      case 'ullage':
        return formatWaterUllageDisplay(product);
      case 'percent':
      default:
        return formatPercentWaterFullDisplay(product);
    }
  }

  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      return mapTag == 'score'
        ? product?.maxScore?.display ?? '-'
        : product?.maxAge?.display ?? '-';
    case Constants.PRODUCT_TYPE_FUEL:
      return mapTag == 'percentFull'
        ? product.minPercent?.display ?? '-'
        : product.minDays?.display ?? '-';
    case Constants.PRODUCT_TYPE_FEED:
      return mapTag == 'remaining'
        ? product.minDays?.display ?? '-'
        : product.minPercent?.display ?? '-';
    case Constants.PRODUCT_TYPE_WATER:
      return (
        returnOnEntityAccess(
          getWaterTankTag(),
          product.maxCurrentUsage?.display
        ) ?? '-'
      );
    default:
      return '-';
  }
}
export function getAppMapTagIndex(mapTag: string, product: Product) {
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      return mapTag == 'score'
        ? product?.maxScore?.index ?? 0
        : product?.maxAge?.index ?? 0;
    case Constants.PRODUCT_TYPE_FEED:
    case Constants.PRODUCT_TYPE_FUEL:
      return mapTag == 'percentFull'
        ? product.minPercent?.index ?? 0
        : product.minDays?.index ?? 0;
    case Constants.PRODUCT_TYPE_WATER:
      return 0; //Yet to be implemented
    default:
      return 0;
  }
}

export async function formatAppEntitiesAPI(data: ProductAPI[]) {
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      return formatMilkEntitiesAPI(data);
    case Constants.PRODUCT_TYPE_FUEL:
      return await formatFuelEntitiesAPI(data);
    case Constants.PRODUCT_TYPE_WATER:
      return formatWaterEntitiesAPI(data);
    case Constants.PRODUCT_TYPE_FEED:
      return formatFeedEntitiesAPI(data);
    default:
      return data.map((entity: ProductAPI) => {
        return {
          ...formatEntitiesAPI(entity),
          entityComments: formatComment(entity, null),
          metrics: formatEntityMetrics(entity)
        } as Product;
      });
  }
}

export function getAppEntityOrgCustomSetting() {
  if (CustomStore.orgCustomSettings) {
    switch (getAppName(false)) {
      case Constants.PRODUCT_TYPE_MILK:
        return CustomStore.orgCustomSettings.milk?.vats;
      case Constants.PRODUCT_TYPE_FUEL:
        return CustomStore.orgCustomSettings.fuel?.tanks;
      case Constants.PRODUCT_TYPE_FEED:
        return CustomStore.orgCustomSettings.feed?.feed_tanks;
      case Constants.PRODUCT_TYPE_WATER: {
        return CustomStore.orgCustomSettings.water;
      }
      default:
        undefined;
    }
  }
}
