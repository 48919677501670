
import BoundingBox from '@/services/api/models/BoundingBox';
import { Constants, getUnitChar } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ConstantsMixin extends Vue {
  public readonly ALLPRODUCTBOUNDINGBOX: BoundingBox =
    Constants.ALLPRODUCTBOUNDINGBOX;

  // Countries
  public readonly IRELAND = Constants.IRELAND;
  public readonly NEWZEALAND = Constants.NEWZEALAND;

  // Products
  public readonly PRODUCT_TYPE_MILK = Constants.PRODUCT_TYPE_MILK;
  public readonly PRODUCT_TYPE_FUEL = Constants.PRODUCT_TYPE_FUEL;
  public readonly PRODUCT_TYPE_FEED = Constants.PRODUCT_TYPE_FEED;
  public readonly PRODUCT_TYPE_WATER = Constants.PRODUCT_TYPE_WATER;
  public readonly PRODUCT_TYPE_GENERIC = Constants.PRODUCT_TYPE_GENERIC;

  // Api Entity
  public readonly API_ENTITY_TYPE_MILK = Constants.API_ENTITY_TYPE_MILK;
  public readonly API_ENTITY_TYPE_FUEL = Constants.API_ENTITY_TYPE_FUEL;
  public readonly API_ENTITY_TYPE_FEED = Constants.API_ENTITY_TYPE_FEED;
  public readonly API_ENTITY_TYPE_WATER_METER =
    Constants.API_ENTITY_TYPE_WATER_METER;
  public readonly API_ENTITY_TYPE_WATER_TANK =
    Constants.API_ENTITY_TYPE_WATER_TANK;
  public readonly API_ENTITY_TYPE_PRODUCT = Constants.API_ENTITY_TYPE_PRODUCT;

  // Status
  public readonly ENTITY_STATUS_LIVE = Constants.ENTITY_STATUS_LIVE;
  public readonly ENTITY_STATUS_SIGNOFF = Constants.ENTITY_STATUS_SIGNOFF;
  public readonly ENTITY_STATUS_INSTALL = Constants.ENTITY_STATUS_INSTALL;
  public readonly ENTITY_STATUS_PENDING = Constants.ENTITY_STATUS_PENDING;
  public readonly ENTITY_STATUS_DISPLAY_LIVE =
    Constants.ENTITY_STATUS_DISPLAY_LIVE;
  public readonly ENTITY_STATUS_DISPLAY_SIGNOFF =
    Constants.ENTITY_STATUS_DISPLAY_SIGNOFF;
  public readonly ENTITY_STATUS_DISPLAY_INSTALL =
    Constants.ENTITY_STATUS_DISPLAY_INSTALL;
  public readonly ENTITY_STATUS_DISPLAY_PENDING =
    Constants.ENTITY_STATUS_DISPLAY_PENDING;
  public readonly FAULT_STATUS_REPAIR = Constants.FAULT_STATUS_REPAIR;

  // Fault impact
  public readonly FAULT_IMPACT_NO_DATA = Constants.FAULT_IMPACT_NO_DATA;
  public readonly FAULT_IMPACT_UNRELIABLE_DATA =
    Constants.FAULT_IMPACT_UNRELIABLE_DATA;
  public readonly FAULT_IMPACT_MAINTENANCE = Constants.FAULT_IMPACT_MAINTENANCE;

  // Compliance methods
  public readonly COMPLIANCE_METHOD_FONTERRA =
    Constants.COMPLIANCE_METHOD_FONTERRA;
  public readonly COMPLIANCE_METHOD_MPI = Constants.COMPLIANCE_METHOD_MPI;
  public readonly COMPLIANCE_METHOD_SAMR = Constants.COMPLIANCE_METHOD_SAMR;
  public readonly COMPLIANCE_METHOD_NOTSET = Constants.COMPLIANCE_METHOD_NOTSET;

  // Units
  public readonly UNIT_LITRES = Constants.UNIT_LITRES;
  public readonly UNIT_TONNES = Constants.UNIT_TONNES;
  public readonly UNIT_KILOGRAM = Constants.UNIT_KILOGRAM;

  // Metric
  public readonly METRIC_VOLUME = Constants.METRIC_VOLUME;
  public readonly METRIC_WEIGHT = Constants.METRIC_WEIGHT;

  // Entity Label
  public readonly ENTITY_LABEL_TANK = Constants.ENTITY_LABEL_TANK;
  public readonly ENTITY_LABEL_VAT = Constants.ENTITY_LABEL_VAT;
  public readonly ENTITY_LABEL_SILO = Constants.ENTITY_LABEL_SILO;
  public readonly ENTITY_LABEL_BIN = Constants.ENTITY_LABEL_BIN;

  public getStatusDisplayConversion() {
    const ENTITY_STATUS_DISPLAY_CONVERSION: { [key: string]: string } = {};
    ENTITY_STATUS_DISPLAY_CONVERSION[
      this.ENTITY_STATUS_LIVE
    ] = this.ENTITY_STATUS_DISPLAY_LIVE;
    ENTITY_STATUS_DISPLAY_CONVERSION[
      this.ENTITY_STATUS_SIGNOFF
    ] = this.ENTITY_STATUS_DISPLAY_SIGNOFF;
    ENTITY_STATUS_DISPLAY_CONVERSION[
      this.ENTITY_STATUS_INSTALL
    ] = this.ENTITY_STATUS_DISPLAY_INSTALL;
    ENTITY_STATUS_DISPLAY_CONVERSION[
      this.ENTITY_STATUS_PENDING
    ] = this.ENTITY_STATUS_DISPLAY_PENDING;
    return ENTITY_STATUS_DISPLAY_CONVERSION;
  }

  public getFaultImpactStatement(faultImpact: string) {
    const commonStatement = 'This site has a repair visit scheduled';
    switch (faultImpact) {
      case Constants.FAULT_IMPACT_MAINTENANCE:
        return commonStatement + ', there is no impact on the data';
      case Constants.FAULT_IMPACT_UNRELIABLE_DATA:
        return commonStatement + ', this data may be unreliable';
      case Constants.FAULT_IMPACT_NO_DATA:
        return commonStatement + ', there is no data';
      default:
        return commonStatement;
    }
  }

  public getUnitChar(unit: string) {
    return getUnitChar(unit);
  }
}
