import { FetchFuelTypes } from '@/services/api/ProductApi';
import Preferences, {
  CustomFieldObjectPreferences,
  CustomFieldPreferences
} from '@/services/api/models/Preferences';
import { milkGlobals } from '@/store/Globals';
import CustomField from '@/store/models/CustomField';
import { FilterListFormat } from '@/store/models/EmailAlerts';
import {
  AppFilters,
  CustomFieldFilter,
  FuelFilters,
  SavedAppFilters,
  SavedFeedFilters,
  SavedFuelFilters,
  SavedMilkFilters
} from '@/store/models/Filters';
import FuelType from '@/store/models/FuelType';
import dayjs from 'dayjs';
import { getAppName, getPluralEntityName, isApp } from './AppName';
import { Constants } from './Constants';
import { getFormattedFeedAmount } from './FeedUtils';
import { fillEmptyRanges, isUpperBoundDefault } from './FiltersUtils';
import { toTitleCase } from './FormattingUtils';
import { complianceMethodLabel } from './MilkScoreUtils';
import { decamelizeFunction, formatMilkScore } from './TableFormatters';

function formatRangeListItem(
  lowerValue: number | null,
  upperValue: number | null,
  key: string
) {
  if (lowerValue) {
    if (upperValue && !isUpperBoundDefault(upperValue)) {
      return {
        filter: `${decamelizeFunction(key.split('Range')[0])}:`,
        value: `${getFormattedFeedAmount(
          lowerValue,
          undefined,
          true
        )} - ${getFormattedFeedAmount(upperValue, undefined, true)}`
      };
    } else {
      return {
        filter: `${decamelizeFunction(key.split('Range')[0])}:`,
        value: `> ${getFormattedFeedAmount(lowerValue, undefined, true)}`
      };
    }
  } else {
    if (upperValue && !isUpperBoundDefault(upperValue)) {
      return {
        filter: `${decamelizeFunction(key.split('Range')[0])}:`,
        value: `< ${getFormattedFeedAmount(upperValue, undefined, true)}`
      };
    }
  }
}

function formatCustomFiltersListItem(filters?: CustomFieldFilter[]) {
  if (filters) {
    return filters.map((filter: CustomFieldFilter) => {
      return filter.selection.map((value: string) => {
        return {
          filter: `${filter.field.label}:`,
          value: value
        };
      });
    });
  }
  return [];
}

export function formatFuelFilterListItem(
  key: keyof SavedFuelFilters,
  alteredFilters: SavedFuelFilters
): FilterListFormat | FilterListFormat[] | FilterListFormat[][] | void {
  switch (key) {
    case 'type':
      if (alteredFilters[key].length > 0) {
        return alteredFilters[key].map((type: string) => {
          return {
            filter: 'Fuel Type:',
            value: type
          };
        });
      }

      break;
    case 'customSiteFilters': {
      const filters = formatCustomFiltersListItem(alteredFilters[key]);
      if (filters) {
        return filters;
      }
      break;
    }
    case 'customTankFilters': {
      const tankFilters = formatCustomFiltersListItem(
        alteredFilters.customEntityFilters
      );
      if (tankFilters) {
        return tankFilters;
      }
      break;
    }
    case 'capacityRange':
    case 'ullageRange':
    case 'volumeRange': {
      const item = formatRangeListItem(
        alteredFilters[key][0],
        alteredFilters[key][1],
        key
      );
      if (item) {
        return item;
      }
      break;
    }
    case 'onlyFaults':
      if (alteredFilters[key]) {
        return {
          filter: '',
          value: decamelizeFunction(key)
        };
      }
      break;
  }
}

export function formatFeedFilterListItem(
  key: keyof SavedFeedFilters,
  alteredFilters: SavedFeedFilters
): FilterListFormat | FilterListFormat[] | FilterListFormat[][] | void {
  switch (key) {
    case 'type':
      if (alteredFilters[key].length > 0) {
        return alteredFilters[key].map((type: string) => {
          return {
            filter: 'Feed Type:',
            value: type
          };
        });
      }

      break;
    case 'customSiteFilters': {
      const filters = formatCustomFiltersListItem(alteredFilters[key]);
      if (filters) {
        return filters;
      }
      break;
    }
    case 'customFeedTankFilters': {
      const tankFilters = formatCustomFiltersListItem(
        alteredFilters.customEntityFilters
      );
      if (tankFilters) {
        return tankFilters;
      }
      break;
    }
    case 'capacityRange':
    case 'volumeRange':
    case 'weightRange':
    case 'weightCapacityRange': {
      const item = formatRangeListItem(
        alteredFilters[key][0],
        alteredFilters[key][1],
        key
      );
      if (item) {
        return item;
      }
      break;
    }
  }
}

export function formatMilkFilterListItem(
  key: keyof SavedMilkFilters,
  alteredFilters: SavedMilkFilters
) {
  switch (key) {
    case 'customSiteFilters': {
      const filters = formatCustomFiltersListItem(alteredFilters[key]);
      if (filters) {
        return filters;
      }
      break;
    }
    case 'customVatFilters': {
      const vatFilters = formatCustomFiltersListItem(
        alteredFilters.customEntityFilters
      );
      if (vatFilters) {
        return vatFilters;
      }
      break;
    }
    case 'capacityRange':
    case 'volumeRange': {
      const item = formatRangeListItem(
        alteredFilters[key][0],
        alteredFilters[key][1],
        key
      );
      if (item) {
        return item;
      }
      break;
    }
    case 'volumePercent': {
      if (
        alteredFilters[key] &&
        (alteredFilters[key][0] != 0 || alteredFilters[key][1] != 100)
      ) {
        return {
          filter: 'Volume:',
          value: `${alteredFilters[key][0]}% - ${alteredFilters[key][1]}%`
        };
      }
      break;
    }
    case 'onlySupply':
      if (alteredFilters[key]) {
        return {
          filter: '',
          value: `${getPluralEntityName(true)} in use`
        };
      }
      break;
    case 'hideRepair':
      if (alteredFilters[key]) {
        return {
          filter: '',
          value: decamelizeFunction(key)
        };
      }
      break;
    case 'risk':
      if (alteredFilters[key]) {
        return {
          filter: `${decamelizeFunction(key)}:`,
          value: milkGlobals.hasNonFonterra
            ? 'Above 0 mins°C'
            : formatMilkScore(
                (alteredFilters as SavedMilkFilters)[key].toString()
              )
        };
      }
      break;
    case 'score':
      if (alteredFilters[key].length > 0) {
        return alteredFilters[key].map((val: string) => {
          return {
            filter: `${decamelizeFunction(key)}:`,
            value: toTitleCase(val)
          };
        });
      }
      break;
    case 'complianceMethod':
      if (alteredFilters[key].length > 0) {
        return alteredFilters[key].map((val: string) => {
          return {
            filter: `${decamelizeFunction(key)}:`,
            value: complianceMethodLabel(val)
          };
        });
      }
      break;
    case 'age':
      if (alteredFilters[key]) {
        return {
          filter: `${decamelizeFunction(key)}:`,
          value: `> ${alteredFilters[key]}h`
        };
      }
      break;
    case 'temperature':
      if (alteredFilters[key]) {
        return {
          filter: `${decamelizeFunction(key)}:`,
          value: alteredFilters[key] == 'above' ? 'Above 6°C' : 'Below 6°C'
        };
      }
      break;
  }
}

export function getToAddToListFilters(
  key: string,
  alteredFilters: SavedAppFilters
) {
  if (isApp(Constants.PRODUCT_TYPE_MILK)) {
    return formatMilkFilterListItem(
      key as keyof SavedMilkFilters,
      alteredFilters as SavedMilkFilters
    );
  } else if (isApp(Constants.PRODUCT_TYPE_FEED)) {
    return formatFeedFilterListItem(
      key as keyof SavedFeedFilters,
      alteredFilters as SavedFeedFilters
    );
  } else {
    return formatFuelFilterListItem(
      key as keyof SavedFuelFilters,
      alteredFilters as SavedFuelFilters
    );
  }
}

export function formatFilterListItem(alteredFilters: SavedAppFilters) {
  const listFilters: { filter: string; value: string }[] = [];
  Object.keys(alteredFilters).forEach(key => {
    const toAddToListFilters = getToAddToListFilters(key, alteredFilters);

    if (toAddToListFilters) {
      if (Array.isArray(toAddToListFilters)) {
        toAddToListFilters.forEach(
          (filter: FilterListFormat | FilterListFormat[]) => {
            if (Array.isArray(filter)) {
              listFilters.push(...filter);
            } else {
              listFilters.push(filter);
            }
          }
        );
      } else {
        listFilters.push(toAddToListFilters);
      }
    }
  });
  return listFilters;
}

export function convertFixedScheduleFromHHmm(fixedSchedule: string[] | null) {
  if (fixedSchedule) {
    return fixedSchedule.map(time => {
      return dayjs(
        `${time.substring(0, 2) + ':' + time.substring(2, 4)} 12-01-2022`
      ).format('h:mm a');
    });
  } else {
    return ['5:30 am', '9:30 am', '1:30 pm', '5:30 pm', '9:30 pm'];
  }
}

export function convertFixedScheduleToHHmm(fixedSchedule: string[]) {
  return fixedSchedule.map(time => {
    return dayjs(`${time} 12-01-2022`).format('HHmm');
  });
}

export function convertPeriodScheduleFromMins(periodSchedule: number | null) {
  if (periodSchedule) {
    return periodSchedule === 30 ? '30 mins' : `${periodSchedule / 60} hr`;
  } else {
    return '1 hr';
  }
}

export function convertPeriodScheduleToMins(periodSchedule: string) {
  return +periodSchedule.split(' ')[0] === 30
    ? 30
    : +periodSchedule.split(' ')[0] * 60;
}

export function convertFuelTypeToLabel(types: string[], fuelTypes: FuelType[]) {
  return types.map(type => {
    return fuelTypes.find(fuelType => fuelType.type == type)?.label ?? type;
  });
}

export function convertFuelLabelToType(
  labels: string[],
  fuelTypes: FuelType[]
) {
  return labels.map(label => {
    return fuelTypes.find(fuelType => fuelType.label == label)?.type ?? label;
  });
}

export function getAppSummaryEmail(preferences: Preferences) {
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      return preferences.partner.milk?.alertSummaryEmail;
    case Constants.PRODUCT_TYPE_FUEL:
      return preferences.partner.fuel?.alertSummaryEmail;
    case Constants.PRODUCT_TYPE_FEED:
      return preferences.partner.feed?.alertSummaryEmail;
  }
}

function getFixedDropdownValues(): string[] {
  const values: string[] = [];
  for (let i = 5; i <= 21; i++) {
    // ((i - 1) % 12) + 1 just gives you 5-12 and then wraps 1-9 rather than 0-8
    values.push(`${((i - 1) % 12) + 1}:00 ${i < 12 ? 'am' : 'pm'}`);
    values.push(`${((i - 1) % 12) + 1}:30 ${i < 12 ? 'am' : 'pm'}`);
  }
  return values;
}

export const fixedDropdownValues = getFixedDropdownValues();

export function convertCustomFieldsToString(
  customFields: CustomFieldObjectPreferences
): CustomFieldPreferences {
  const convertedCustomFields: CustomFieldPreferences = {
    products: [],
    tanks: [],
    vats: [],
    feed_tanks: []
  };
  convertedCustomFields.products = customFields.products.map(
    field => (field as CustomField).name
  );
  if (isApp(Constants.PRODUCT_TYPE_FUEL)) {
    convertedCustomFields.tanks = customFields.tanks?.map(
      field => (field as CustomField).name
    );
  }
  if (isApp(Constants.PRODUCT_TYPE_MILK)) {
    convertedCustomFields.vats = customFields.vats?.map(
      field => (field as CustomField).name
    );
  }
  if (isApp(Constants.PRODUCT_TYPE_FEED)) {
    convertedCustomFields.feed_tanks = customFields.feed_tanks?.map(
      field => (field as CustomField).name
    );
  }
  return convertedCustomFields;
}

export async function alterCustomFiltersFromSaved(filters: AppFilters) {
  let alteredFilters = { ...filters };
  alteredFilters = fillEmptyRanges({
    ...alteredFilters
  } as AppFilters);
  if (isApp(Constants.PRODUCT_TYPE_FUEL)) {
    const fuelTypes = await FetchFuelTypes();
    (alteredFilters as FuelFilters).type = convertFuelLabelToType(
      (alteredFilters as FuelFilters).type,
      fuelTypes
    );
    (alteredFilters as SavedFuelFilters).customEntityFilters =
      (alteredFilters as SavedFuelFilters).customTankFilters ?? [];
  } else if (isApp(Constants.PRODUCT_TYPE_MILK)) {
    (alteredFilters as SavedMilkFilters).customEntityFilters =
      (alteredFilters as SavedMilkFilters).customVatFilters ?? [];
  } else if (isApp(Constants.PRODUCT_TYPE_FEED)) {
    (alteredFilters as SavedFeedFilters).customEntityFilters =
      (alteredFilters as SavedFeedFilters).customFeedTankFilters ?? [];
  }

  return alteredFilters;
}
