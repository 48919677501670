import { Constants } from '../Constants';
import { getSpecificPageName } from '../PageUtils';
import {
  AllowedField,
  FeedAllowedField,
  FuelAllowedField,
  MilkAllowedField,
  WaterAllowedField
} from '../types/AllowedFields';

export const productViewFields = {
  [Constants.PRODUCT_TYPE_MILK]: [
    AllowedField.CHECKBOX,
    MilkAllowedField.COLLAPSE,
    MilkAllowedField.SUPPLIER_NUMBER,
    AllowedField.SITE,
    MilkAllowedField.VOLUME,
    AllowedField.VOLUME_PERCENT_BAR,
    AllowedField.PERCENT_FULL,
    AllowedField.WARNINGS,
    MilkAllowedField.AGE,
    MilkAllowedField.LAST_MILKING,
    MilkAllowedField.LAST_PICKUP,
    MilkAllowedField.TEMPERATURE,
    MilkAllowedField.COMPLIANCE_METHOD
  ] as (AllowedField | MilkAllowedField)[],
  [Constants.PRODUCT_TYPE_FUEL]: [
    AllowedField.CHECKBOX,
    FuelAllowedField.CUSTOM_ID,
    AllowedField.SITE,
    FuelAllowedField.TYPE,
    FuelAllowedField.VOLUME,
    AllowedField.WARNINGS,
    AllowedField.PERCENT_FULL,
    AllowedField.VOLUME_PERCENT_BAR,
    FuelAllowedField.FILL_VOLUME,
    FuelAllowedField.USAGE,
    FuelAllowedField.REMAINING
  ] as (AllowedField | FuelAllowedField)[],
  [Constants.PRODUCT_TYPE_FEED]: [
    AllowedField.CHECKBOX,
    FeedAllowedField.CUSTOM_ID,
    AllowedField.SITE,
    FeedAllowedField.TYPE,
    FeedAllowedField.VOLUME,
    AllowedField.WARNINGS,
    AllowedField.PERCENT_FULL,
    AllowedField.VOLUME_PERCENT_BAR,
    FeedAllowedField.FILL_VOLUME,
    FeedAllowedField.USAGE,
    FeedAllowedField.REMAINING,
    FeedAllowedField.LAST_REFILL_SIZE,
    FeedAllowedField.LAST_REFILL_DATE
  ] as (AllowedField | FeedAllowedField)[],
  [Constants.PRODUCT_TYPE_WATER]: [
    AllowedField.SITE,
    AllowedField.VOLUME_PERCENT_BAR,
    AllowedField.WARNINGS,
    WaterAllowedField.CUSTOM_ID,
    WaterAllowedField.CURRENT_USAGE,
    WaterAllowedField.USAGE_TODAY,
    WaterAllowedField.USAGE_YESTERDAY,
    WaterAllowedField.VOLUME,
    WaterAllowedField.VOLUME_CAPACITY,
    WaterAllowedField.REMAINING
  ] as (AllowedField | WaterAllowedField)[],
  [Constants.PRODUCT_TYPE_GENERIC]: [
    AllowedField.CHECKBOX,
    AllowedField.SITE
  ] as AllowedField[]
};

export const customerViewFields = {
  [Constants.PRODUCT_TYPE_MILK]: [
    ...productViewFields[Constants.PRODUCT_TYPE_MILK],
    AllowedField.ENTITY_STATUS
  ] as (AllowedField | MilkAllowedField)[],
  [Constants.PRODUCT_TYPE_FUEL]: [
    ...productViewFields[Constants.PRODUCT_TYPE_FUEL],
    AllowedField.ENTITY_STATUS
  ] as (AllowedField | FuelAllowedField)[],
  [Constants.PRODUCT_TYPE_FEED]: [
    ...productViewFields[Constants.PRODUCT_TYPE_FEED],
    AllowedField.ENTITY_STATUS
  ] as (AllowedField | FeedAllowedField)[],
  [Constants.PRODUCT_TYPE_WATER]: [
    ...productViewFields[Constants.PRODUCT_TYPE_WATER],
    AllowedField.ENTITY_STATUS
  ] as (AllowedField | WaterAllowedField)[],
  [Constants.PRODUCT_TYPE_GENERIC]: [
    ...productViewFields[Constants.PRODUCT_TYPE_GENERIC],
    AllowedField.ENTITY_STATUS
  ] as AllowedField[]
};

export const liveSitesFields = {
  [Constants.PRODUCT_TYPE_MILK]: [
    AllowedField.CHECKBOX,
    AllowedField.SITE,
    AllowedField.ADDRESS,
    MilkAllowedField.SUPPLIER_NUMBER,
    AllowedField.NUM_ENTITIES,
    AllowedField.CONTRIBUTION_TIER,
    AllowedField.REPAIR_ICON,
    MilkAllowedField.COMPLIANCE_METHOD
  ] as (AllowedField | MilkAllowedField)[],
  [Constants.PRODUCT_TYPE_FUEL]: [
    AllowedField.CHECKBOX,
    AllowedField.SITE,
    AllowedField.ADDRESS,
    FuelAllowedField.CUSTOM_ID,
    AllowedField.NUM_ENTITIES,
    FuelAllowedField.LARGEST_TANK,
    AllowedField.CONTRIBUTION_TIER,
    AllowedField.REPAIR_ICON
  ] as (AllowedField | FuelAllowedField)[],
  [Constants.PRODUCT_TYPE_FEED]: [
    AllowedField.CHECKBOX,
    AllowedField.SITE,
    AllowedField.ADDRESS,
    FeedAllowedField.CUSTOM_ID,
    AllowedField.NUM_ENTITIES,
    FeedAllowedField.LARGEST_TANK,
    AllowedField.CONTRIBUTION_TIER,
    AllowedField.REPAIR_ICON
  ] as (AllowedField | FeedAllowedField)[],
  [Constants.PRODUCT_TYPE_WATER]: [
    AllowedField.SITE,
    AllowedField.ADDRESS,
    WaterAllowedField.CUSTOM_ID,
    AllowedField.NUM_ENTITIES,
    AllowedField.CONTRIBUTION_TIER
  ] as (AllowedField | WaterAllowedField)[],
  [Constants.PRODUCT_TYPE_GENERIC]: [
    AllowedField.CHECKBOX,
    AllowedField.SITE,
    AllowedField.ADDRESS,
    AllowedField.CONTRIBUTION_TIER
  ] as AllowedField[]
};

export const alertsViewFields = {
  [Constants.PRODUCT_TYPE_MILK]: [
    AllowedField.WARNINGS,
    AllowedField.PERCENT_FULL,
    MilkAllowedField.VOLUME_CAPACITY,
    MilkAllowedField.AGE,
    MilkAllowedField.SUPPLIER_NUMBER,
    AllowedField.SITE,
    AllowedField.ENTITY_NAME,
    MilkAllowedField.COMPLIANCE_METHOD,
    AllowedField.ACTION_TAKEN
  ] as (AllowedField | MilkAllowedField)[],
  [Constants.PRODUCT_TYPE_FUEL]: [
    AllowedField.WARNINGS,
    AllowedField.PERCENT_FULL,
    FuelAllowedField.VOLUME,
    FuelAllowedField.ALERT_TIME,
    FuelAllowedField.CUSTOM_ID,
    AllowedField.SITE,
    AllowedField.ENTITY_NAME,
    AllowedField.ACTION_TAKEN
  ] as (AllowedField | FuelAllowedField)[],
  [Constants.PRODUCT_TYPE_FEED]: [
    AllowedField.WARNINGS,
    AllowedField.PERCENT_FULL,
    FuelAllowedField.VOLUME,
    FeedAllowedField.ALERT_TIME,
    FuelAllowedField.CUSTOM_ID,
    AllowedField.SITE,
    AllowedField.ENTITY_NAME,
    FeedAllowedField.LAST_REFILL_SIZE,
    FeedAllowedField.LAST_REFILL_DATE,
    AllowedField.ACTION_TAKEN
  ] as (AllowedField | FeedAllowedField)[],
  [Constants.PRODUCT_TYPE_WATER]: [
    WaterAllowedField.CUSTOM_ID,
    AllowedField.SITE,
    WaterAllowedField.CURRENT_USAGE,
    WaterAllowedField.USAGE_TODAY,
    WaterAllowedField.USAGE_YESTERDAY,
    WaterAllowedField.VOLUME,
    WaterAllowedField.VOLUME_CAPACITY,
    AllowedField.VOLUME_PERCENT_BAR,
    WaterAllowedField.REMAINING,
    AllowedField.ACTION_TAKEN
  ] as (AllowedField | WaterAllowedField)[],
  [Constants.PRODUCT_TYPE_GENERIC]: [
    AllowedField.SITE,
    AllowedField.ACTION_TAKEN
  ] as AllowedField[]
};

export function getPageTableFieldList() {
  switch (getSpecificPageName()) {
    case 'alerts':
      return alertsViewFields;
    case 'sites':
      return liveSitesFields;
    case 'customer':
      return customerViewFields;
    default:
      return productViewFields;
  }
}
