import ProductAPI from '@/services/api/models/ProductAPI';
import { FuelCustomSettingsData } from '@/store/models/CustomSettingsData';
import { calculateFillVolume } from '@/store/models/FuelTank';
import {
  FuelMetricsDefault,
  FuelMetricsTank,
  Product
} from '@/store/models/Product';
import { workingDays } from '../DateUtils';
import { toTitleCase } from '../FormattingUtils';
import {
  formatComment,
  formatFuelAlert,
  formatFuelDaysToEmpty,
  formatTimeOfAlert
} from '../TableFormatters';
import { outdatedValidity } from '../TestingValidity';
import {
  formatEntitiesAPI,
  formatGroupedEntity,
  getCombinedEntityCustomFields,
  getMinValue
} from './EntitiesFormatter';

function getAlteredUsagePatternFields(
  usageDayPattern: number | undefined,
  metrics: any
): {
  dailyUse: number | null;
  estimatedEmptyTimestamp: number | null;
  daysTillEmpty: number | null;
  workingDaysTillEmpty: number | null;
} {
  if (metrics) {
    if (usageDayPattern == 5 || usageDayPattern == 6) {
      const emptyTimestamp =
        metrics[`estimatedEmpty${usageDayPattern}DayTimestamp`];
      return {
        dailyUse: metrics[`dailyUse${usageDayPattern}Day`],
        estimatedEmptyTimestamp: emptyTimestamp,
        daysTillEmpty: formatFuelDaysToEmpty(emptyTimestamp),
        workingDaysTillEmpty: workingDays(usageDayPattern, emptyTimestamp)
      };
    } else {
      return {
        dailyUse: metrics.dailyUse,
        estimatedEmptyTimestamp: metrics.estimatedEmptyTimestamp,
        daysTillEmpty: formatFuelDaysToEmpty(metrics.estimatedEmptyTimestamp),
        workingDaysTillEmpty: workingDays(7, metrics.estimatedEmptyTimestamp)
      };
    }
  }
  return {
    dailyUse: null,
    estimatedEmptyTimestamp: null,
    daysTillEmpty: null,
    workingDaysTillEmpty: null
  };
}

/**
 * Formats fuel entity list from API: JSON parsing, formatting fields and handling UAT data
 */
export async function formatFuelEntitiesAPI(entities: ProductAPI[]) {
  const formattedEntities = entities.map((entity: ProductAPI) => {
    let metrics;

    if (!entity.metrics || Object.keys(entity.metrics).length == 0) {
      metrics = FuelMetricsDefault;
    } else {
      metrics =
        typeof entity.metrics === 'string'
          ? JSON.parse(entity.metrics)
          : entity.metrics;
    }
    const formattedBaseEntity = formatEntitiesAPI(entity);

    const alteredUsagePatternFields = getAlteredUsagePatternFields(
      (formattedBaseEntity.entityCustomSettingsData as FuelCustomSettingsData)
        ?.usageDayPattern,
      metrics
    );
    return {
      ...formattedBaseEntity,
      entityComments: formatComment(entity, metrics.lastDeliveryTimestamp),
      metrics: entity
        ? {
            ...(metrics as FuelMetricsTank),
            nominalVolume: metrics.nominalVolume ?? entity.capacity,
            fuelType: entity.fuelType
              ? toTitleCase(entity.fuelType)
              : metrics.fuelType,
            safeFillVolume: entity.safeFillVolume,
            fillVolume: calculateFillVolume(
              entity.safeFillVolume ?? null,
              metrics.nominalVolume ?? null,
              metrics.volume ?? null
            ),
            outdated: outdatedValidity(metrics.volumeTs),
            daysTillEmpty: alteredUsagePatternFields.daysTillEmpty,
            workingDaysTillEmpty:
              alteredUsagePatternFields.workingDaysTillEmpty,
            dailyUse: alteredUsagePatternFields.dailyUse,
            estimatedEmptyTimestamp:
              alteredUsagePatternFields.estimatedEmptyTimestamp,
            alert: formatFuelAlert(
              formattedBaseEntity.entityCustomSettingsData,
              metrics ? metrics.percentFull : 50
            ),
            alertTs: formatTimeOfAlert(
              formattedBaseEntity.entityCustomSettingsData,
              metrics.percentFull,
              metrics.levelHistory
            )
          }
        : FuelMetricsDefault
    } as Product;
  });
  return formattedEntities;
}

/**
 * Groups list of fuel entities by product id to create a list of products with entites
 */
export function getGroupedFuelProducts(entities: Product[]) {
  const map = new Map();
  entities.forEach((item: Product) => {
    const collection = map.get(item.productId);
    if (!collection) {
      map.set(item.productId, {
        ...item,
        minDays: getMinValue(item, 0, null, 'daysTillEmpty'),
        minPercent: getMinValue(item, 0, null, 'percentFull'),
        entities: [
          {
            ...formatGroupedEntity(item)
          }
        ]
      });
    } else {
      const index = collection.entities.length;
      collection.entities.push({
        ...formatGroupedEntity(item)
      });
      collection.minDays = getMinValue(
        item,
        index,
        collection.minDays,
        'daysTillEmpty'
      );

      collection.minPercent = getMinValue(
        item,
        index,
        collection.minPercent,
        'percentFull'
      );
      collection.entityCustomFieldData = getCombinedEntityCustomFields(
        collection.entityCustomFieldData,
        item.entityCustomFieldData
      );
    }
  });
  return map;
}

export function formatFuelProducts(entities: Product[]): Product[] {
  return Array.from(getGroupedFuelProducts(entities)).map(
    product => product[1]
  );
}
