
import { Component, Vue } from 'vue-property-decorator';
import NavigationPanel from '@/components/common/NavigationPanel.vue';

@Component({
  components: {
    NavigationPanel
  }
})
export default class AppHeader extends Vue {}
