import { isOrgUat } from '@/services/api/Environment';
import ProductAPI from '@/services/api/models/ProductAPI';
import {
  MilkMetrics,
  MilkMetricsDefault,
  Product
} from '@/store/models/Product';

import { milkGlobals } from '@/store/Globals';
import MapTagValue, { ScoreMapTagValue } from '@/store/models/MapTagValue';
import MilkScore, {
  ComplianceSettings,
  rating
} from '@/store/models/MilkScore';
import dayjs from 'dayjs';
import { Constants } from '../Constants';
import { hours, hoursOld } from '../DateUtils';
import { formatStatus, toTitleCase } from '../FormattingUtils';
import { getComplianceScore } from '../MilkScoreUtils';
import {
  formatComment,
  formatMilkScoreAbbr,
  formatMilkScoreColour,
  formatMilkScoreDigit,
  formatVatState
} from '../TableFormatters';
import { validityContainsMilk, validityVatVolume } from '../TestingValidity';
import {
  formatEntitiesAPI,
  formatGroupedEntity,
  getCombinedEntityCustomFields
} from './EntitiesFormatter';
const IS_UAT = isOrgUat();

/*
 * Returns the max age, the display value and the index ofthe entity with this age
 */
function getMaxAge(
  currentVat: Product,
  index: number,
  previousAge: MapTagValue | null
): MapTagValue {
  const metrics = currentVat?.metrics as MilkMetrics;

  const enteredVat = metrics?.milkEnteredVat;
  if (index == 0 || previousAge === null) {
    return {
      value: enteredVat != null ? +hours(enteredVat) : 0,
      display:
        metrics?.containsMilk && enteredVat != null
          ? hoursOld(enteredVat)
          : '-',
      index: 0
    };
  } else {
    if (enteredVat == null || !metrics?.containsMilk) {
      return previousAge;
    } else if (
      previousAge.display === '-' ||
      previousAge.value < +hours(enteredVat)
    ) {
      return {
        value: +hours(enteredVat),
        display: hoursOld(enteredVat),
        index: index
      };
    } else {
      return previousAge;
    }
  }
}

/*
 * Returns the max score, the display value and the index of this score
 */
function getMaxScore(
  currentVat: Product,
  index: number,
  previousScore: ScoreMapTagValue | null
): ScoreMapTagValue {
  function getDisplayScore(
    digit: rating,
    methodScore: MilkScore | null,
    metrics: MilkMetrics
  ) {
    if (!metrics?.containsMilk || digit == null) {
      return '-';
    } else {
      if (
        methodScore?.complianceMethod == Constants.COMPLIANCE_METHOD_FONTERRA
      ) {
        return formatMilkScoreAbbr(Math.round(digit).toString());
      } else {
        return toTitleCase(formatMilkScoreColour(digit, true));
      }
    }
  }

  const methodScore = getComplianceScore(
    (currentVat?.metrics as MilkMetrics).milkScores,
    currentVat?.entityCustomSettingsData as ComplianceSettings
  );
  // get the colour digit (this will be 0,1,2,3,4 or null)
  const digit = formatMilkScoreDigit(
    methodScore?.rating ?? null,
    !!methodScore?.areaOutOfSpec
  );

  const metrics = currentVat?.metrics as MilkMetrics;
  if (index == 0 || previousScore === null) {
    return {
      value: digit,
      display:
        metrics?.containsMilk && digit != null
          ? getDisplayScore(digit, methodScore, metrics)
          : '-',
      index: 0
    };
  } else {
    if (metrics?.milkEnteredVat == null || !metrics?.containsMilk) {
      return previousScore;
    }
    if (
      digit != null &&
      (previousScore.display === '-' ||
        previousScore.value == null ||
        previousScore.value < digit)
    ) {
      return {
        value: digit,
        display:
          metrics?.containsMilk && digit != null
            ? getDisplayScore(digit, methodScore, metrics)
            : '-',
        index: index
      };
    } else {
      return previousScore;
    }
  }
}

/*
 * Returns whether the partner has fonterra products or not
 */
export function getHasNonFonterra(products: Product[]) {
  let hasNonFonterra = false;
  for (const product of products) {
    if (product.supplier != 'F' && !hasNonFonterra) {
      hasNonFonterra = true;
      break;
    }
  }
  return hasNonFonterra;
}

/*
 * Returns the max capacity for a list of products
 */
export function getMaxCapacity(products: Product[]) {
  return products.reduce(
    (a, b) =>
      a < (b.metrics as MilkMetrics)?.capacity
        ? (b.metrics as MilkMetrics)?.capacity
        : a,
    0
  );
}

/**
 * Milk table displays product level data, this function sets the combined metrics for a product.
 * It will take total volumes and take relevant minimums and maximums of other fields
 */
function getCombinedMetrics(
  combined: MilkMetrics,
  currentMetrics: MilkMetrics,
  customSettings: ComplianceSettings | null
) {
  const combinedMetrics = { ...combined };
  if (currentMetrics?.capacity) {
    combinedMetrics.capacity += currentMetrics?.capacity;
  }

  if (currentMetrics?.milkScores && currentMetrics.containsMilk) {
    if (
      (getComplianceScore(currentMetrics?.milkScores, customSettings)
        ?.areaOutOfSpec ?? 0) >
        (getComplianceScore(combinedMetrics?.milkScores, customSettings)
          ?.areaOutOfSpec ?? 0) ||
      !combinedMetrics.containsMilk
    ) {
      combinedMetrics.milkScores = currentMetrics.milkScores;
    }
  } else if (!combinedMetrics.milkScores || !combinedMetrics.containsMilk) {
    combinedMetrics.milkScores = null;
  }

  if (currentMetrics?.vatVolume && currentMetrics.containsMilk) {
    if (!combinedMetrics.containsMilk) {
      combinedMetrics.vatVolume = currentMetrics.vatVolume;
    } else {
      combinedMetrics.vatVolume += currentMetrics.vatVolume;
    }
  }

  if (
    currentMetrics?.milkEnteredVat != null &&
    currentMetrics.containsMilk &&
    (hours(currentMetrics.milkEnteredVat) >
      hours(combinedMetrics.milkEnteredVat) ||
      !combinedMetrics.containsMilk)
  ) {
    combinedMetrics.milkEnteredVat = currentMetrics.milkEnteredVat;
  }
  if (
    currentMetrics?.lastPickup != null &&
    (hours(currentMetrics.lastPickup) < hours(combinedMetrics.lastPickup) ||
      !combinedMetrics.lastPickup)
  ) {
    combinedMetrics.lastPickup = currentMetrics.lastPickup;
  }
  if (
    currentMetrics?.lastMilking &&
    combinedMetrics.lastMilking &&
    (currentMetrics.lastMilking.timestamp >
      combinedMetrics.lastMilking.timestamp ||
      (currentMetrics.lastMilking.type == 'start' &&
        combinedMetrics.lastMilking.type != 'first'))
  ) {
    combinedMetrics.lastMilking = currentMetrics.lastMilking;
  }
  if (currentMetrics?.state != 'supply') {
    if (combinedMetrics.state != 'supply') {
      combinedMetrics.state =
        combinedMetrics.state == 'notUsed'
          ? currentMetrics.state ?? ''
          : combinedMetrics.state;
    }
  } else {
    if (combinedMetrics.state != 'supply') {
      combinedMetrics.state = currentMetrics.state;
    }
  }
  if (currentMetrics?.containsMilk != null) {
    if (combinedMetrics.containsMilk == null) {
      combinedMetrics.containsMilk = currentMetrics.containsMilk;
    } else {
      combinedMetrics.containsMilk =
        combinedMetrics.containsMilk || currentMetrics.containsMilk;
    }
  }

  if (
    combinedMetrics.vatVolume &&
    combinedMetrics.capacity &&
    combinedMetrics.containsMilk
  ) {
    combinedMetrics.percentFull = Math.round(
      (combinedMetrics.vatVolume / combinedMetrics.capacity) * 100
    );
  }

  return combinedMetrics;
}

/**
 * Formats milk entity list from API: JSON parsing, formatting fields and handling UAT data
 */
export function formatMilkEntitiesAPI(entities: ProductAPI[]) {
  const formattedEntities = entities.map((entity: ProductAPI) => {
    let metrics;
    if (!entity.metrics) {
      metrics = MilkMetricsDefault;
    } else {
      metrics =
        typeof entity.metrics === 'string'
          ? JSON.parse(entity.metrics)
          : entity.metrics;
    }
    const vatVolume =
      metrics.vatVolume != undefined
        ? IS_UAT
          ? metrics.vatVolume
          : metrics.vatVolumeTs && validityVatVolume(metrics.vatVolumeTs ?? 0)
          ? metrics.vatVolume
          : null
        : IS_UAT
        ? metrics.tonnage
        : null;
    const containsMilk = metrics.containsMilk
      ? IS_UAT
        ? true
        : metrics.containsMilkTs &&
          validityContainsMilk(metrics.containsMilkTs ?? 0)
        ? true
        : null
      : IS_UAT
      ? true
      : false;
    return {
      ...formatEntitiesAPI(entity),
      entityComments: formatComment(entity, dayjs(metrics.lastPickup).unix()),
      metrics: entity
        ? {
            ...(metrics as MilkMetrics),
            vatVolume: vatVolume,
            containsMilk: containsMilk,
            milkEnteredVat:
              metrics.milkEnteredVat == 'NULL' ? null : metrics.milkEnteredVat,
            milkScore: IS_UAT
              ? entity.supplier == 'F'
                ? metrics.milkScore % 5
                : metrics.milkScore
              : metrics.milkScore,
            milkScores: containsMilk ? metrics.milkScores : null,
            state:
              formatStatus(entity.entityStatus) == Constants.ENTITY_STATUS_LIVE
                ? formatVatState(entity.state ?? '', entity.supplier ?? '')
                : 'supply',
            percentFull:
              entity?.capacity && vatVolume
                ? Math.round((vatVolume / entity?.capacity) * 100)
                : 0,
            capacity: entity?.capacity
          }
        : MilkMetricsDefault
    } as Product;
  });
  return formattedEntities;
}

/**
 * Groups list of milk entities by product id to create a list of products with entites
 */
export function getGroupedMilkProducts(entities: Product[]) {
  const hasNonFonterra = milkGlobals.hasNonFonterra;
  const map = new Map();
  entities.forEach((item: Product) => {
    const collection = map.get(item.productId);
    if (!collection) {
      map.set(item.productId, {
        ...item,
        faultStatus: hasNonFonterra ? item.faultStatus : '',
        maxAge: getMaxAge(item, 0, null),
        maxScore: getMaxScore(item, 0, null),
        entities: [
          {
            ...formatGroupedEntity(item),
            faultStatus: hasNonFonterra ? item.faultStatus : '',
            metrics: {
              ...item.metrics,
              state: formatVatState(item.state ?? '', item.supplier ?? '')
            }
          }
        ]
      });
    } else {
      const index = collection.entities.length;
      collection.entities.push({
        ...formatGroupedEntity(item),
        faultStatus: hasNonFonterra ? item.faultStatus : '',
        metrics: {
          ...item.metrics,
          state: formatVatState(item.state ?? '', item.supplier ?? '')
        }
      });
      collection.entities = collection.entities.sort(
        (a: Product, b: Product) => {
          if (a == undefined || b == undefined) return 0;
          return a.name > b.name ? 1 : -1;
        }
      );
      collection.maxAge = getMaxAge(item, index, collection.maxAge);
      collection.maxScore = getMaxScore(item, index, collection.maxScore);
      collection.metrics = getCombinedMetrics(
        collection.metrics,
        item?.metrics as MilkMetrics,
        item?.entityCustomSettingsData as ComplianceSettings
      );
      collection.entityCustomFieldData = getCombinedEntityCustomFields(
        collection.entityCustomFieldData,
        item.entityCustomFieldData
      );
    }
  });
  return map;
}

export function formatMilkProducts(entities: Product[]): Product[] {
  return Array.from(getGroupedMilkProducts(entities)).map(
    product => product[1]
  );
}
