import HttpClient from '@/services/api/ApiBase';
import { CustomSettings } from '@/store/models/CustomSettingsData';
import CustomSettingsRequest from './models/requests/CustomSettingsRequest';

export const UpdateEntityCustomSettingsData = async (request: {
  partnerId: number;
  entityId: number;
  entityType: string;
  data: any;
}): Promise<any> => {
  const data = await HttpClient.post<any>(
    `/partner/${request.partnerId}/customSettingsData/${request.entityType}/${request.entityId}`,
    { data: request.data }
  );
  return data.data.data;
};

export const UpdateCustomSettingsData = async (request: {
  partnerId: number;
  entityId: number;
  entityType: string;
  data: any;
}): Promise<any> => {
  const data = await HttpClient.post<any>(
    `/partner/${request.partnerId}/customSettings/${request.entityType}/${request.entityId}`,
    { data: request.data }
  );
  return data.data.data;
};

export const GetCustomSettings = async (request: {
  partnerId: number;
  entityType: string;
  legacy: boolean;
}): Promise<any> => {
  const data = await HttpClient.get<any>(
    `/partner/${request.partnerId}/customSettings${
      !request.legacy ? '?legacy=false' : ''
    }`
  );
  const settings = data.data.data.find(
    (item: any) => item.entityType == request.entityType
  );
  return settings ? settings.customSettings : {};
};

export const GetOrgCustomSettings = async (
  orgId: number,
  legacy: boolean
): Promise<CustomSettings> => {
  const response = await HttpClient.get<any>(
    `/partner/${orgId}/customSettings${!legacy ? '?legacy=false' : ''}`
  );
  return response.data.data;
};

export const UpdateCustomSetting = async (
  request: CustomSettingsRequest
): Promise<any> => {
  const data = await HttpClient.post<any>(
    `/partner/${request.partnerId}/updateCustomSetting/${request.productType}/${request.entityType}`,
    { data: request.data }
  );
  return data.data.data;
};

export const GetComplianceMethods = async (orgId: number) => {
  const data = await HttpClient.get<any>(`/partner/${orgId}/complianceMethods`);
  return data.data.data;
};
