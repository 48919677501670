import { FetchFuelTypes } from '@/services/api/ProductApi';
import {
  fuelCustomFilterDefaults,
  milkCustomFilterDefaults
} from '@/services/api/models/Preferences';
import {
  AppFilters,
  SavedAppFilters,
  SavedFeedFilters,
  SavedFuelFilters,
  SavedMilkFilters
} from '@/store/models/Filters';
import { isApp } from './AppName';
import { Constants } from './Constants';
import { convertFuelTypeToLabel } from './EmailAlertsUtils';

const rangeKeys = [
  'volumeRange',
  'capacityRange',
  'ullageRange',
  'weightRange',
  'weightCapacityRange'
];

function getDefaultUpperBound() {
  return isApp(Constants.PRODUCT_TYPE_FEED) ? 500000 : 120000;
}

function isRangeKey(key: string) {
  return rangeKeys.includes(key);
}

export function isBoundDefault(value: number | null) {
  return !value;
}

export function isUpperBoundDefault(
  upperValue: number | null,
  upperBoundDefault?: number
) {
  return (
    isBoundDefault(upperValue) ||
    upperValue == (upperBoundDefault ?? getDefaultUpperBound())
  );
}

export function isRangeDefault(range: (number | null)[]) {
  return (
    !range?.length ||
    (isBoundDefault(range[0]) &&
      isUpperBoundDefault(range[1], getDefaultUpperBound()))
  );
}

export function isPercentRangeDefault(range: (number | null)[]) {
  return !range?.length || !(range[0] || (range[1] != null && range[1] != 100));
}

export function isFiltersDefault(filters: AppFilters) {
  return Object.entries(filters).every(([key, value]) => {
    if (isRangeKey(key)) {
      return isRangeDefault(value);
    } else if (key == 'volumePercent') {
      return isPercentRangeDefault(value);
    } else {
      return (
        value == null ||
        (Array.isArray(value) && value.length == 0) ||
        (typeof value == 'number' && value == 0) ||
        (typeof value == 'string' && (value == '' || value == null)) ||
        (typeof value == 'boolean' && !value)
      );
    }
  });
}

export function isRangesDefault(filters: SavedAppFilters) {
  const alteredFilters = { ...filters };
  return Object.entries(alteredFilters).every(([key, value]) => {
    if (isRangeKey(key)) {
      return isRangeDefault(value);
    } else if (key == 'volumePercent') {
      return isPercentRangeDefault(value);
    }
  });
}

export function fillEmptyRanges(filters: SavedAppFilters) {
  const alteredFilters = { ...filters };
  Object.entries(alteredFilters).forEach(([key, value]) => {
    if (isRangeKey(key) && isRangeDefault(value)) {
      (alteredFilters as any)[key] = [0, getDefaultUpperBound()];
    } else if (
      isRangeKey(key) &&
      isUpperBoundDefault(value[1], getDefaultUpperBound())
    ) {
      (alteredFilters as any)[key][1] = getDefaultUpperBound();
    } else if (key == 'volumePercent' && isPercentRangeDefault(value)) {
      (alteredFilters as SavedMilkFilters)[key] = [0, 100];
    }
  });
  return alteredFilters as AppFilters;
}

export function alterRangesForSaving(customFilters: AppFilters) {
  const alteredFilters = { ...customFilters };
  Object.entries(alteredFilters).forEach(([key, value]) => {
    if (key.endsWith('Range') && isRangeDefault(value)) {
      alteredFilters[key as keyof AppFilters] = [];
    } else if (key == 'volumePercent' && isPercentRangeDefault(value)) {
      alteredFilters[key as keyof AppFilters] = [];
    }
  });
  return alteredFilters;
}

export async function alterCustomFiltersForSaving(customFilters: AppFilters) {
  let alteredFilters = { ...customFilters };
  if (!alteredFilters) {
    return isApp(Constants.PRODUCT_TYPE_MILK)
      ? milkCustomFilterDefaults
      : fuelCustomFilterDefaults;
  }
  alteredFilters = alterRangesForSaving(alteredFilters);
  if (isApp(Constants.PRODUCT_TYPE_MILK)) {
    (alteredFilters as SavedMilkFilters).customVatFilters =
      alteredFilters.customEntityFilters;
  } else if (isApp(Constants.PRODUCT_TYPE_FUEL)) {
    const fuelTypes = await FetchFuelTypes();
    (alteredFilters as SavedFuelFilters).type = convertFuelTypeToLabel(
      (alteredFilters as SavedFuelFilters).type,
      fuelTypes
    );
    (alteredFilters as SavedFuelFilters).customTankFilters =
      alteredFilters.customEntityFilters;
  } else if (isApp(Constants.PRODUCT_TYPE_FEED)) {
    (alteredFilters as SavedFeedFilters).customFeedTankFilters =
      alteredFilters.customEntityFilters;
  }
  return alteredFilters;
}
