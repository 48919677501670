import router from '@/router';
type pageName =
  | 'products'
  | 'sites'
  | 'settings'
  | 'email-alerts'
  | 'alerts'
  | 'requests'
  | 'help'
  | 'route-plan'
  | 'home'
  | 'profile'
  | '';

export function getPageName(): pageName {
  const route = router.currentRoute.path;
  const findTerm = (term: string) => {
    if (route.includes(term)) {
      return route;
    }
  };

  switch (route) {
    case findTerm('view'):
      return 'products';
    case findTerm('sites'):
      return 'sites';
    case findTerm('settings'):
      return 'settings';
    case findTerm('email-alerts'):
      return 'email-alerts';
    case findTerm('alerts'):
      return 'alerts';
    case findTerm('requests'):
      return 'requests';
    case findTerm('help'):
      return 'help';
    case findTerm('route-plan'):
      return 'route-plan';
    case '/home':
      return 'home';
    case '/profile':
      return 'profile';
    default:
      return '';
  }
}

export function getSpecificPageName(): pageName | 'customer' {
  const route = router.currentRoute.path;
  if (route.includes('customer')) {
    return 'customer';
  } else {
    return getPageName();
  }
}

export function isPage(page: pageName) {
  return getPageName() == page;
}
