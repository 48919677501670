
import { Component, Vue } from 'vue-property-decorator';
import AppHeader from '@/layout/AppHeader.vue';
import User from '@/store/modules/User';
import { initialiseMixpanel } from '@/services/Mixpanel';
import Chart from 'chart.js';
import { SafeArea } from 'capacitor-plugin-safe-area';
@Component({
  components: {
    AppHeader
  },
  computed: {
    isAuthenticated: () => User.isAuthenticated
  }
})
export default class App extends Vue {
  public safeAreaInsetsYAxis: number | null = null;
  mounted() {
    initialiseMixpanel();
    Chart.defaults.global.defaultFontFamily = 'Sofia Pro';
    SafeArea.getSafeAreaInsets().then(({ insets }: { insets: any }) => {
      this.safeAreaInsetsYAxis = insets.top + insets.bottom;
    });
  }
}
