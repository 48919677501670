import User from '@/store/modules/User';
import { getAppName } from './AppName';
import { getSpecificPageName } from './PageUtils';
import { CustomColumnIndex } from './types/CustomColumnIndex';

const setItem = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

const getItem = (key: string): string | null => {
  return localStorage.getItem(key);
};

const removeItem = (key: string): void => {
  localStorage.removeItem(key);
};

export default { setItem, getItem, removeItem };

export function setUserItem(key: string, value: string) {
  localStorage.setItem(
    User.currentUser?.id + '-' + User._orgIndex + key,
    value
  );
}

export function getUserItem(key: string) {
  return localStorage.getItem(
    User.currentUser?.id + '-' + User._orgIndex + key
  );
}

export function removeUserItem(key: string) {
  localStorage.removeItem(User.currentUser?.id + '-' + User._orgIndex + key);
}

export function setListItem(name: string, val: string[]) {
  setUserItem(name, val.join('%,'));
}

export function getListItem(name: string): string[] {
  const item = getUserItem(name) ?? '';
  const itemList = item.split('%,');
  return itemList.filter(item => item != '');
}

export function setObjectItem(name: string, val: any) {
  setUserItem(name, JSON.stringify(val));
}

export function getObjectItem(name: string) {
  const item = getUserItem(name) ?? '';
  return item ? JSON.parse(item) : null;
}

export function setObjectListItem(name: string, val: any[]) {
  const stringList = val.map(value => {
    return JSON.stringify(value);
  });
  setListItem(name, stringList);
}

export function getObjectListItem(name: string) {
  const item = getUserItem(name) ?? '';
  const itemList = item.split('%,');
  return itemList[0] != '' ? itemList.map(value => JSON.parse(value)) : [];
}

export const getSavedTableColumnOrder = (): CustomColumnIndex => {
  return (
    getObjectItem(
      `${getAppName(false)}${getSpecificPageName()}TableColumnOrder`
    ) ?? {}
  );
};
