import CustomField from '@/store/models/CustomField';
import TableColumn, { TableColumnFormatters } from '@/store/models/TableColumn';
import CustomStore from '@/store/modules/CustomStore';
import { getAppName, getEntityApiWord } from './AppName';
import { Constants } from './Constants';
import {
  getObjectItem,
  getObjectListItem,
  removeUserItem,
  setObjectItem
} from './LocalStorageUtils';
import { getSpecificPageName, isPage } from './PageUtils';
import { StringKeyObject } from './types/StringKeyObject';

export function UpdateCustomColumnsVisibility(
  customFieldsVisibility: StringKeyObject | TableColumn[],
  entityType: string
) {
  let defaultFieldsVisibility: StringKeyObject = {};
  if (Array.isArray(customFieldsVisibility)) {
    customFieldsVisibility.forEach(field => {
      defaultFieldsVisibility[field.name] = field.visible;
    });
  } else {
    defaultFieldsVisibility = customFieldsVisibility;
  }
  setObjectItem(
    `${getAppName(
      false
    )}${entityType}${getSpecificPageName()}CustomFieldsVisibility`,
    defaultFieldsVisibility
  );
}

export async function GetCustomColumns(
  entityType: string
): Promise<TableColumn[]> {
  let customFields: TableColumn[] = [];
  const customFieldDefinitions = JSON.parse(
    JSON.stringify(
      entityType == Constants.API_ENTITY_TYPE_PRODUCT
        ? CustomStore.customFields
        : CustomStore.customEntityFields
    )
  );

  customFieldDefinitions.forEach((item: CustomField) => {
    customFields.push({
      name: item.name,
      label: item.label,
      visible: false,
      filterable: item.filterable ?? false,
      searchable: item.searchable ?? false,
      type: item.type,
      sortable: item.sortable ?? false
    });
  });
  let tableSpecificFieldsFromItem = getObjectItem(
    `${getAppName(
      false
    )}${entityType}${getSpecificPageName()}CustomFieldsVisibility`
  );
  const tableFieldsFromItem = getObjectItem(
    `${getAppName(false)}${entityType}CustomFieldsVisibility`
  );
  if (tableSpecificFieldsFromItem || tableFieldsFromItem) {
    //New format has been saved
    if (!tableSpecificFieldsFromItem && tableFieldsFromItem) {
      //Page specific custom fields have not been saved, but there are non page specifc visibilty set
      tableSpecificFieldsFromItem = tableFieldsFromItem;
      //save visibility settings to page specific settings
      UpdateCustomColumnsVisibility(tableFieldsFromItem, entityType);
    }
    customFields = customFields.map(field => {
      const visibility =
        (tableSpecificFieldsFromItem as StringKeyObject)[field.name] ?? false;
      return { ...field, visible: visibility };
    });
    return customFields;
  } else {
    const oldTableFieldsFromItem = (
      getObjectListItem(
        `${getAppName(false)}${
          entityType == 'products' ? '' : entityType
        }TableFields`
      ) ?? []
    ).filter(column => !column.name.includes('Default'));
    if (oldTableFieldsFromItem.length != 0) {
      //Old format is saved,  convert to new format, save it, use it to update visibilty of fields, delete old format
      const customFieldsVisibility: StringKeyObject = {};
      oldTableFieldsFromItem.forEach(field => {
        customFieldsVisibility[field.name] = field.visible;
      });
      customFields = customFields.map(field => {
        const visibility = customFieldsVisibility[field.name] ?? false;
        return { ...field, visible: visibility };
      });
      //save new visibility
      UpdateCustomColumnsVisibility(customFieldsVisibility, entityType);

      //delete old saved visibility object
      removeUserItem(
        `${getAppName(false)}${
          entityType == 'products' ? '' : entityType
        }TableFields`
      );
      return customFields;
    } else {
      //No saved fields for this site, save new visibility settings object
      const customFieldsVisibility: StringKeyObject = {};
      customFields.forEach(field => {
        customFieldsVisibility[field.name] = field.visible;
      });

      UpdateCustomColumnsVisibility(customFieldsVisibility, entityType);
      return customFields;
    }
  }
}

export async function GetSiteCustomColumns(): Promise<TableColumn[]> {
  return await GetCustomColumns('products');
}

export async function GetEntityCustomColumns(): Promise<TableColumn[]> {
  return await GetCustomColumns(getEntityApiWord());
}

export async function GetVisibleSiteCustomColumns(): Promise<TableColumn[]> {
  //I'm not sure what the entity would be for water yet so for now it's 'tanks'
  return await GetSiteCustomColumns().then(columns => {
    return columns.filter(custom => custom.visible);
  });
}

export async function GetVisibleEntityCustomColumns(): Promise<TableColumn[]> {
  //I'm not sure what the entity would be for water yet so for now it's 'tanks'
  return await GetEntityCustomColumns().then(columns => {
    return columns.filter(custom => custom.visible);
  });
}
