import decamelize from 'decamelize';
import Vue from 'vue';
import dayjs from 'dayjs';
import { Constants } from './Constants';
import _ from 'lodash';

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/**
 * decamelizes and converts to title case
 * E.g. 'thisIsCamelCase' -> 'This Is Camel Case'
 */
export const toDecamelize = Vue.filter('toDecamelize', (str: string) => {
  return toTitleCase(decamelize(str, ' '));
});

export function toSentenceCase(str: string) {
  return _.startCase(str);
}

export function getSentenceCaseObject(products: any) {
  const sentenceCaseObject: any = {};
  Object.keys(products).forEach((key, index) => {
    const result = toSentenceCase(key);
    sentenceCaseObject[result] = Object.values(products)[index];
  });

  return sentenceCaseObject;
}

export function customRounding(value: number) {
  if (value > 10000) {
    return Math.round(value / 100) * 100;
  } else if (value > 1000) {
    return Math.round(value / 10) * 10;
  } else {
    return Math.round(value / 5) * 5;
  }
}

export function convertSecsToHrs(secs: number | undefined) {
  return secs ? secs / 3600 : secs;
}

export function convertHrsToSecs(hrs: number | undefined) {
  return hrs ? hrs * 3600 : hrs;
}

export function isNew(liveAt?: number) {
  const twoWeeksAgo = dayjs()
    .subtract(14, 'day')
    .unix();
  if (liveAt) {
    const timestamp = dayjs(liveAt).unix();
    if (timestamp > twoWeeksAgo) {
      return true;
    } else {
      return false;
    }
  }
  return null;
}

export function formatStatus(status: string | undefined) {
  return status ? status : Constants.ENTITY_STATUS_LIVE;
}
