import Vue from 'vue';
export const milkGlobals = Vue.observable({
  maxVolume: 0,
  hasNonFonterra: true
});

export function setMaxVatVolume(newVolume: number) {
  milkGlobals.maxVolume = newVolume;
}

export function setHasNonFonterra(newValue: boolean) {
  milkGlobals.hasNonFonterra = newValue;
}

export const storeState = Vue.observable({ loading: true });

export function setStoreLoadingState(loading: boolean) {
  storeState.loading = loading;
}

export const outdatedFunctionality = Vue.observable({ visible: true });
