import User from '@/store/modules/User';
import mixpanel from 'mixpanel-browser';
import isUat from './api/Environment';

export function setUpUser() {
  if (!isUat()) {
    const user = User.currentUser;
    mixpanel.alias(`${user?.id}`);
    mixpanel.identify(`${user?.id}`);
    mixpanel.people.set({
      $name: user?.name,
      $email: user?.email,
      $phone: user?.phone
    });
  }
}

export function initialiseMixpanel() {
  if (!isUat()) {
    mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);
  }
}

export function trackEvent(
  eventName: string,
  eventProperties?: { [key: string]: string }
) {
  if (!isUat()) {
    mixpanel.track(eventName, eventProperties);
  }
}
