import { FuelFilters } from '@/store/models/Filters';
import { FuelMetricsTank, Product } from '@/store/models/Product';
import { reduceMaxValue } from '../formatters/EntitiesFormatter';
import { FiltersClass } from './FiltersClass';
import { isUpperBoundDefault } from '../FiltersUtils';

export class FuelFiltersClass extends FiltersClass<FuelFilters> {
  public maxUllage = 0;

  constructor() {
    super('fuel', {
      type: [],
      volumeRange: [0, 0],
      capacityRange: [0, 0],
      ullageRange: [0, 0],
      customSiteFilters: [],
      customEntityFilters: [],
      onlyFaults: false,
      onlyOutdated: false
    });
  }

  public filterProducts(products: Product[]) {
    let filtered = [...products];

    if (this.filters.onlyFaults) {
      filtered = filtered?.filter(product => product.faultStatus == 'repair');
    }
    if (this.filters.onlyOutdated) {
      filtered = filtered?.filter(
        product => (product.metrics as FuelMetricsTank).outdated
      );
    }

    if (this.filters.type.length != 0) {
      filtered = filtered?.filter(product =>
        this.filters.type.includes(
          (product.metrics as FuelMetricsTank).fuelType
        )
      );
    }
    if (this.filters.capacityRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as FuelMetricsTank).nominalVolume >=
          this.filters.capacityRange[0]
      );
    }

    if (this.filters.capacityRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as FuelMetricsTank).nominalVolume <=
          this.filters.capacityRange[1]
      );
    }

    if (this.filters.volumeRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as FuelMetricsTank).volume >=
          this.filters.volumeRange[0]
      );
    }

    if (this.filters.volumeRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as FuelMetricsTank).volume <=
          this.filters.volumeRange[1]
      );
    }
    if (this.filters.ullageRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as FuelMetricsTank).fillVolume >=
          this.filters.ullageRange[0]
      );
    }
    if (this.filters.ullageRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as FuelMetricsTank).fillVolume <=
          this.filters.ullageRange[1]
      );
    }

    filtered = super.filterProducts(filtered);
    return filtered;
  }

  public get filtersSet(): boolean {
    return (
      super.filtersSet ||
      this.filters.type.length > 0 ||
      this.filters.onlyFaults ||
      (this.filters.onlyOutdated ?? false)
    );
  }

  public get lowerBoundSet(): boolean {
    return super.lowerBoundSet || this.filters.ullageRange[0] != 0;
  }

  public setMaxRanges(products: Product[]): void {
    super.setMaxRanges(products);
    this.setMaxUllage(
      Math.ceil(reduceMaxValue(products, 'fillVolume') / 500) * 500
    );
  }

  public setMaxUllage(maxUll: number) {
    if (
      this.filters.ullageRange[1] == this.maxUllage ||
      this.filters.ullageRange[1] == null
    ) {
      this.filters.ullageRange[1] = maxUll;
    }
    this.maxUllage = maxUll;
  }

  public get tankParametersSet(): boolean {
    return super.tankParametersSet && this.maxUllage != 0;
  }

  public get upperBoundSet(): boolean {
    return (
      super.upperBoundSet ||
      isUpperBoundDefault(this.filters.ullageRange[1], this.maxUllage) == false
    );
  }
}
