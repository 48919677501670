import { FuelMetricsTank } from './Product';

export default interface FuelTank {
  id: number;
  productId: number;
  name: string;
  safeFillVolume: number;
  fuelType: string;
  fuelTankId: number;
  currententitystate: {
    createdAt: string;
    entityId: number;
    entityType: string;
    id: number;
    metrics: FuelMetricsTank;
  };
}

export const calculateFillVolume = (
  safeFillVolume: number | null,
  nominalVolume: number | null,
  volume: number | null
): number | null => {
  if (volume === null) {
    return null;
  }
  let fillVolume: number | null = null;
  if (safeFillVolume !== null && nominalVolume !== null) {
    const smallestTankVolume = Math.min(safeFillVolume, nominalVolume);
    fillVolume = smallestTankVolume - volume;
  } else if (safeFillVolume !== null && nominalVolume === null) {
    fillVolume = safeFillVolume - volume;
  } else if (safeFillVolume === null && nominalVolume !== null) {
    fillVolume = nominalVolume - volume;
  }

  if (fillVolume === null) {
    return null;
  }

  return Math.max(0, fillVolume);
};
