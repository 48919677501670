import { Product } from '@/store/models/Product';
import {
  getAppName,
  getPreferredMetricWord,
  getUnitWithTonnes,
  getUnitWord
} from './AppName';
import { Constants } from './Constants';
import { AmountCapacityFilter, AmountFilter } from './types/AmountMetric';

export function isWeight(entity?: Product) {
  return getUnitWord(entity) == Constants.UNIT_KILOGRAM;
}

export function isTonnes(value?: number, entity?: Product) {
  return !!entity && !!value && isWeight(entity) && value >= 1000;
}

export function getTonnes2dp(value: number) {
  return Math.round(value / 10) / 100;
}

export function getTonnage(value: number) {
  return (value / 1000).toLocaleString(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  });
}

/**
 * Formats the supplied number based on the storage type of the entity
 * @param forceTonnes if this is true it will force the value to be converted to tonnes, if this is false it will force the original metric, and undefined will carry out original calculation
 */
export function getFeedAmount(
  value: number,
  entity?: Product,
  forceTonnes?: boolean
) {
  return isWeight(entity) &&
    (forceTonnes || (isTonnes(value, entity) && forceTonnes !== false))
    ? getTonnage(value)
    : value?.toLocaleString() ?? '-';
}

/**
 * Formats the supplied number based on the storage type of the entity and pairs it with the correct unit.
 * @param forceTonnes if this is true it will force the value to be converted to tonnes, if this is false it will force the original metric, and undefined will carry out original calculation
 */
export function getFormattedFeedAmount(
  value: number,
  entity?: Product,
  forceTonnes?: boolean
) {
  return `${getFeedAmount(value, entity, forceTonnes)}${getUnitWithTonnes(
    value,
    entity,
    forceTonnes
  )}`;
}

export function alterFeedExportAmount(value: number) {
  return isWeight() ? getTonnes2dp(value) : Math.round(value);
}

export function getFeedAmountFilter(): AmountFilter {
  return (getPreferredMetricWord() + 'Range') as AmountFilter;
}

export function getFeedCapacityFilter(): AmountCapacityFilter {
  return ((getPreferredMetricWord() == Constants.METRIC_WEIGHT
    ? 'weightCapacity'
    : 'capacity') + 'Range') as AmountCapacityFilter;
}
