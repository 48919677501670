import MilkScore from '@/store/models/MilkScore';
import {
  FeedMetrics,
  FuelMetricsTank,
  MilkMetrics,
  Product,
  WaterMeterMetrics,
  WaterTankMetrics
} from '@/store/models/Product';
import dayjs from 'dayjs';
import { getEntityApiWord, getEntityName, getUnit } from '../AppName';
import { Constants } from '../Constants';
import { getDateFromUnix, relativeDay } from '../DateUtils';
import { getFormattedFeedAmount, isTonnes, isWeight } from '../FeedUtils';
import {
  formatAgeMilk,
  formatDaysTillEmpty,
  formatLastMilking,
  formatLastPickup,
  formatMilkScoreAbbr,
  formatWaterFlow,
  formatWaterTankLevel,
  formatWaterUsage
} from '../TableFormatters';
import { getObjectItem } from '../LocalStorageUtils';

//Generic value
export const formatPercentFullDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | MilkMetrics | FeedMetrics;
  return Math.round(metrics.percentFull || 0) + '%';
};

export const formatPercent = (value: string | number) => {
  return `${value}%`;
};

//Water value
export const formatPercentWaterFullDisplay = (entity: Product) => {
  if (!entity) return '-';
  const metrics = entity.metrics as WaterTankMetrics;
  return formatPercent(Math.round(metrics.percent ?? 0));
};

//Milk values
export const formatMilkVolumeDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.vatVolume
    ? metrics.containsMilk
      ? Math.round(metrics.vatVolume).toLocaleString() + 'L'
      : metrics.containsMilk == null
      ? '-'
      : 'Empty'
    : metrics.containsMilk == null
    ? '-'
    : 'Empty';
};

export const formatLastCollectionDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.lastPickup
    ? formatLastPickup(metrics.lastPickup).split(' ')[0]
    : '-';
};

export const formatLastCollectionDateDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.lastPickup ? formatLastPickup(metrics.lastPickup) : '-';
};

export const formatAgeOfMilkDisplay = (
  product: Product,
  type: 'parent' | 'child'
) => {
  let oldestTs: string | null = null;
  if (type == 'parent' && product.entities) {
    product.entities.forEach(entity => {
      const metrics = entity.metrics as MilkMetrics;
      const milkEnteredVatDate = new Date(metrics.milkEnteredVat);
      const oldestTsDate = oldestTs ? new Date(oldestTs) : null;
      const vatIsValid =
        metrics &&
        metrics.milkEnteredVat &&
        metrics.containsMilk &&
        entity.state === 'supply';
      if (vatIsValid && (!oldestTsDate || milkEnteredVatDate <= oldestTsDate)) {
        oldestTs = metrics.milkEnteredVat;
      }
    });
  } else {
    const metrics = product.metrics as MilkMetrics;
    oldestTs = metrics.milkEnteredVat;
  }
  return oldestTs ? formatAgeMilk(oldestTs) : '-';
};

export const formatAgeOfMilkDateDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics && metrics.milkEnteredVat && metrics.containsMilk
    ? `Milk entered ${getEntityName(false)} ` +
        formatLastPickup(metrics.milkEnteredVat)
    : metrics.containsMilk
    ? 'No data recorded'
    : 'As at ' + formatLastPickup(dayjs().format());
};

export const formatMilkPercentFullDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.containsMilk
    ? formatPercentFullDisplay(entity)
    : metrics.containsMilk == null
    ? '-'
    : '0%';
};

export const formatLastMilkingDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.lastMilking && metrics.lastMilking.timestamp
    ? metrics.lastMilking.type == 'start'
      ? 'In Progress'
      : formatLastMilking(metrics.lastMilking.timestamp)
    : '';
};

export const formatMilkScoreDisplay = (
  entity: Product,
  milkScore: MilkScore
) => {
  const metrics = entity.metrics as MilkMetrics;
  if (milkScore.rating && milkScore.rating >= 1 && metrics.containsMilk) {
    if (entity.supplier == 'F') {
      return formatMilkScoreAbbr(milkScore.rating.toString());
    } else {
      return Math.round(milkScore.rating);
    }
  } else {
    return '';
  }
};

export const checkMilkMetricUnavailable = (
  entity: Product,
  field: keyof MilkMetrics
) => {
  const metrics = entity.metrics as MilkMetrics;
  return !metrics || metrics.containsMilk == null || metrics[field] == null;
};

export const checkWaterMetricUnavailable = (
  entity: Product,
  field: keyof (WaterMeterMetrics & WaterTankMetrics)
) => {
  const metrics = entity.metrics as WaterMeterMetrics & WaterTankMetrics;
  return !metrics || metrics[field] == null;
};

//Fuel and Feed values
export const formatVolumeDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics
    ? metrics.volume > 0 && 100 > metrics.percentFull
      ? `${metrics.volume.toLocaleString()} ${getUnit(entity)}`
      : ''
    : '';
};

export const formatFeedVolumeDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics
    ? metrics.volume > 0 && 100 > metrics.percentFull
      ? getFormattedFeedAmount(metrics.volume, entity)
      : ''
    : '';
};

export const formatFillVolumeDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.fillVolume != null
    ? Math.round(metrics.fillVolume || 0).toLocaleString() + getUnit(entity)
    : '-';
};

export const formatWaterTankUnit = (value: number) => {
  const savedUnits = getObjectItem(`unitSettings-${getEntityApiWord()}`);
  const waterUsageUnit = savedUnits?.usageUnit ?? 'L';
  const computedValue =
    Math.round((waterUsageUnit == 'm\u00B3' ? value / 1000 : value) * 10) / 10;
  return computedValue != null
    ? computedValue.toLocaleString() + waterUsageUnit
    : value.toLocaleString() + waterUsageUnit;
};

export const formatWaterUllageDisplay = (entity: Product) => {
  const metrics = entity.metrics as WaterTankMetrics | FeedMetrics;
  if (!entity.capacity || !metrics.volume) return '-';
  return metrics && metrics.volume != null
    ? formatWaterTankUnit(
        Math.round(entity.capacity - Math.round(metrics.volume / 100) * 100)
      )
    : '-';
};

export const formatWaterCapacity = (entity: Product) => {
  const metrics = entity.metrics as WaterTankMetrics | FeedMetrics;
  if (!entity.capacity) return '-';
  return metrics && metrics.volume != null
    ? formatWaterTankUnit(entity.capacity)
    : '-';
};

export const formatAvailableCapacityDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.fillVolume != null
    ? getFormattedFeedAmount(metrics.fillVolume, entity)
    : '-';
};

export const formatLastRefillVolumeDisplay = (entity: Product) => {
  const metrics = entity.metrics as FeedMetrics;
  if (!metrics) {
    return '-';
  }
  let lastDelivery = null;
  if (isWeight(entity)) {
    // Show tonnage, i.e. lastDeliveryWeight
    lastDelivery = metrics.lastDeliveryWeight;
  } else {
    lastDelivery = metrics.lastDeliveryVolume;
  }
  return lastDelivery != null
    ? getFormattedFeedAmount(lastDelivery ?? 0, entity)
    : '-';
};

export const formatLastRefillDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics &&
    metrics.lastDeliveryTimestamp != null &&
    metrics.lastDeliveryTimestamp != 0
    ? relativeDay(metrics.lastDeliveryTimestamp)
    : '-';
};

export const formatLastRefillTileDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.lastDeliveryTimestamp != null
    ? `Last refill ${relativeDay(metrics.lastDeliveryTimestamp)}`
    : '';
};

export const formatFeedDailyUseDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  if (!metrics) {
    return '-';
  }
  if (isWeight(entity) && (metrics as FeedMetrics).shortDailyUseWeight) {
    let shortDailyUseWeight: number =
      (metrics as FeedMetrics).shortDailyUseWeight ?? 0;
    if (!isTonnes(shortDailyUseWeight, entity)) {
      shortDailyUseWeight = Math.round(shortDailyUseWeight);
    }
    return getFormattedFeedAmount(shortDailyUseWeight, entity);
  }
  return metrics.dailyUse != null
    ? getFormattedFeedAmount(metrics.dailyUse, entity, false)
    : '-';
};

export const formatDailyUseDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.dailyUse != null
    ? metrics.dailyUse.toLocaleString() + getUnit(entity)
    : '-';
};

export const formatDailyUseDateDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.dailyUseTs != null
    ? `As at ${relativeDay(metrics.dailyUseTs || 0)}`
    : '';
};

export const formatDaysTillEmptyDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.daysTillEmpty != null
    ? formatDaysTillEmpty(metrics.daysTillEmpty)
    : '-';
};

export const formatDaysTillEmptyDateDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.estimatedEmptyTimestamp
    ? `Empty on
    ${getDateFromUnix(metrics.estimatedEmptyTimestamp)}`
    : '';
};

export const formatVolumeWithCapacityDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  if (
    metrics &&
    metrics.volume > 0 &&
    100 > metrics.percentFull &&
    metrics.nominalVolume
  ) {
    return `${metrics.volume.toLocaleString()}${getUnit(
      entity
    )} /  ${metrics.nominalVolume.toLocaleString()}${getUnit(entity)}`;
  } else {
    return '-';
  }
};

export const checkFuelFeedMetricUnavailable = (
  entity: Product,
  field: (
    | 'volume'
    | 'percentFull'
    | 'fillVolume'
    | 'daysTillEmpty'
    | 'dailyUse'
    | 'lastDeliveryVolume'
    | 'lastDeliveryWeight'
    | 'shortDailyUse'
    | 'shortDailyUseWeight'
  )[]
) => {
  const metrics = entity.metrics as FeedMetrics;
  let unavailable = false;
  field.forEach(f => {
    if (!metrics || metrics[f] == null) {
      unavailable = true;
    }
  });
  return unavailable;
};

export const isLive = (entity: Product) => {
  if (entity.status != Constants.ENTITY_STATUS_LIVE) {
    return false;
  }
  return true;
};

// Water values
export const formatCurrentUsageDisplay = (entity: Product) => {
  const metrics = entity.metrics as WaterMeterMetrics;
  return metrics.currentUsage != null
    ? formatWaterFlow(metrics.currentUsage)
    : '-';
};

export const formatUsageTodayDisplay = (entity: Product) => {
  const metrics = entity.metrics as WaterMeterMetrics;
  return metrics.usageToday != null
    ? formatWaterUsage(metrics.usageToday)
    : '-';
};

export const formatUsageYesterdayDisplay = (entity: Product) => {
  const metrics = entity.metrics as WaterMeterMetrics;
  return metrics.usageYesterday != null
    ? formatWaterUsage(metrics.usageYesterday)
    : '-';
};

export const formatWaterVolumeDisplay = (
  entity: Product,
  emptyReturn = true
) => {
  const metrics = entity.metrics as WaterTankMetrics | FeedMetrics;
  if (!metrics.volume) return emptyReturn ? '' : '-';
  return formatWaterTankUnit(Math.round(metrics.volume / 100) * 100);
};

export const formatCapacityDisplay = (entity: Product) => {
  const metrics = entity.metrics as WaterTankMetrics;
  return metrics.percent != null ? formatWaterTankLevel(metrics.percent) : '-';
};
