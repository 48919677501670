import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import permissionService from '@/services/PermissionService';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import AsyncComputedPlugin from 'vue-async-computed';
import ToggleButton from 'vue-js-toggle-button';
import bFormSlider from 'vue-bootstrap-slider';
import './styles/global.scss';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {
  ageOf,
  timeAgo,
  relativeDay,
  relativeOnlyDay,
  hoursOld,
  hours,
  days
} from './utils/DateUtils';
import { toDecamelize } from './utils/FormattingUtils';
import VueGTag from 'vue-gtag';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(AsyncComputedPlugin);
Vue.use(ToggleButton);
Vue.use(bFormSlider);
Vue.use(
  VueGTag,
  {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS_TOKEN,
      params: {
        send_page_view: true
      }
    }
  },
  router
);
new Vue({
  router,
  store,
  provide: {
    permissionService
  },
  filters: {
    ageOf,
    timeAgo,
    relativeDay,
    relativeOnlyDay,
    hoursOld,
    hours,
    days,
    toDecamelize
  },
  render: h => h(App)
}).$mount('#app');
