import { NavigationGuardNext, RawLocation, Route } from "vue-router";

type Guard = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => void;

function runGuards(
  guards: Guard[], 
  to: Route, 
  from: Route, 
  next: NavigationGuardNext<Vue>
): void {
  let result;
  guards.find(
    (guard: Guard) => guard(to, from, (nextUrl) => {
      const redirectOccurred = nextUrl !== undefined;
      if (redirectOccurred) {
        result = nextUrl;
      }
      return redirectOccurred;
    })
  );
  next(result);
}

export function MultipleGuards(
  guards: Guard[]
): (to: Route, from: Route, next: NavigationGuardNext<Vue>) => void {
  return (
    to: Route, 
    from: Route, 
    next: NavigationGuardNext<Vue>
  ) => runGuards(guards, to, from, next);
}