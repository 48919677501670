
import { Component, Vue } from 'vue-property-decorator';
import {
  getAppName,
  getAppColour,
  getAppColourRGB,
  getEntityName,
  getEntityApiWord,
  getEntityTitle,
  isApp,
  getGraphColours,
  getLevelText,
  getEntityHeaderInfo,
  getUnit,
  getEntityApiCamelCaseWord,
  getAppConstant,
  getUnitWord,
  getPreferredMetricWord,
  getUnitWithTonnes,
  getPluralEntityName
} from '@/utils/AppName';
import { Level } from '@/utils/types/Level';
import { Product } from '@/store/models/Product';
import { AmountMetric } from '@/utils/types/AmountMetric';

@Component
export default class AppName extends Vue {
  public getAppName(isTitleCase: boolean) {
    return getAppName(isTitleCase);
  }

  public getAppConstant() {
    return getAppConstant();
  }

  public getAppColour(isFormatBootstrap: boolean) {
    return getAppColour(isFormatBootstrap);
  }

  public getAppColourRGB() {
    return getAppColourRGB();
  }

  public getEntityName(isTitleCase: boolean, entity?: Product) {
    return getEntityName(isTitleCase, entity);
  }

  public getPluralEntityName(isTitleCase: boolean, entity?: Product) {
    return getPluralEntityName(isTitleCase, entity);
  }

  public getEntityApiWord() {
    return getEntityApiWord();
  }

  public getEntityApiCamelCaseWord() {
    return getEntityApiCamelCaseWord();
  }

  public getEntityTitle(entity: any) {
    return getEntityTitle(entity);
  }

  public getEntityHeaderInfo(entity: any) {
    return getEntityHeaderInfo(entity);
  }

  public isApp(app: string) {
    return isApp(app);
  }

  public getGraphColours(disabled: boolean) {
    return getGraphColours(disabled);
  }

  public getLevelText(level: Level) {
    return getLevelText(level);
  }

  public getPreferredMetricWord(levelMetric?: AmountMetric) {
    return getPreferredMetricWord(levelMetric);
  }

  public getUnitWord(entity?: Product, forceTonnes?: boolean) {
    return getUnitWord(entity, forceTonnes);
  }

  public getUnit(entity?: Product) {
    return getUnit(entity);
  }

  public getUnitWithTonnes(
    value: number,
    entity?: Product,
    forceTonnes?: boolean
  ) {
    return getUnitWithTonnes(value, entity, forceTonnes);
  }
}
