import router from '@/router';
import HttpClient from '@/services/api/ApiBase';
import fuelDailyGraphValues from '@/services/api/fuelDailyGraphValues.json';
import fuelPlaceholderData from '@/services/api/fuelPlaceholderData.json';
import milkScoreHistoryPlaceholder from '@/services/api/milkScoreHistoryPlaceholder.json';
import milkVatGraphPlaceholder from '@/services/api/milkVatGraphPlaceholder.json';
import FeedLevel from '@/services/api/models/FeedLevel';
import ProductResponse from '@/services/api/models/responses/ProductResponse';
import FeedType from '@/store/models/FeedTypes';
import FuelType from '@/store/models/FuelType';
import { ComplianceSettings } from '@/store/models/MilkScore';
import { Product } from '@/store/models/Product';
import { isApp } from '@/utils/AppName';
import { Constants } from '@/utils/Constants';
import { ConvertCustomFields } from '@/utils/CustomFields';
import {
  datesToDays,
  datesToOffset,
  getMillisecondTimestamp
} from '@/utils/DateUtils';
import { isNew, toTitleCase } from '@/utils/FormattingUtils';
import { formatAppEntitiesAPI } from '@/utils/GenericUtils';
import { formatComment } from '@/utils/TableFormatters';
import {
  formatEntitiesAPI,
  formatEntityMetrics
} from '@/utils/formatters/EntitiesFormatter';
import { formatFeedEntitiesAPI } from '@/utils/formatters/FeedEntitiesFormatter';
import { formatFuelEntitiesAPI } from '@/utils/formatters/FuelEntitiesFormatter';
import { formatMilkEntitiesAPI } from '@/utils/formatters/MilkEntitiesFormatter';
import { formatWaterEntitiesAPI } from '@/utils/formatters/WaterEntitiesFormatter';
import dayjs from 'dayjs';
import { isOrgUat } from './Environment';
import {
  FetchDemoTimeseries,
  FetchFeedTankTimeseries,
  FetchMilkScoreHistoryTimeseries,
  FetchProductsTimeseries,
  FetchTankTimeseries,
  FetchVatTimeseries,
  FetchWaterMeterTimeseries,
  FetchWaterTankTimeseriesPercent
} from './TimeseriesApi';
import FeedBalance from './models/FeedBalance';
import { MeterTimeseriesReading } from './models/MeterTimeseries';
import { MilkProductTimeseriesReading } from './models/MilkProductTimeseries';
import ProductAPI from './models/ProductAPI';
import TankTimeseries, { TankTimeseriesReading } from './models/TankTimeseries';
import { VatMilkScoreTimeseries } from './models/VatTimeseries';
import ProductRequest from './models/requests/ProductRequest';
import UserResponse from './models/responses/UserResponse';
import { WaterTankVolumePercent } from './models/WaterTankVolumePercent';
const IS_UAT = isOrgUat();

export const FetchFuelTypes = async (): Promise<FuelType[]> => {
  const response = await HttpClient.get<{ data: FuelType[] }>(
    `/tanks/fuelTypes`
  );
  return response.data.data;
};

export const FetchFeedTypes = async (): Promise<FeedType[]> => {
  const response = await HttpClient.get<{ data: FeedType[] }>(
    `/feed/feedTypes`
  );
  return response.data.data;
};

export const FetchEntityStates = async (request: {
  partnerId: number;
  productRequest: ProductRequest;
}): Promise<Product[]> => {
  // Should return an array of entities, id, name.
  try {
    const entityStateResponse = await HttpClient.post<ProductResponse>(
      `/partner/${request.partnerId}/entities`,
      {
        data: {
          boundingBox: request.productRequest.boundingBox,
          productType: request.productRequest.productType
        }
      }
    );
    if (
      !(entityStateResponse.status == 200 || entityStateResponse.status == 201)
    ) {
      alert(
        'There was an issue retreiving the products. Please refresh the page to try again. If the problem continues please contact Levno. '
      );
      router.push('home');
    }

    let formattedEntities;
    if (request.productRequest.productType == 'milk') {
      formattedEntities = formatMilkEntitiesAPI(entityStateResponse.data.data);
    } else if (request.productRequest.productType == 'fuel') {
      formattedEntities = await formatFuelEntitiesAPI(
        entityStateResponse.data.data
      );
    } else if (request.productRequest.productType == 'water') {
      formattedEntities = formatWaterEntitiesAPI(entityStateResponse.data.data);
    } else if (request.productRequest.productType == 'feed') {
      formattedEntities = formatFeedEntitiesAPI(entityStateResponse.data.data);
    } else {
      formattedEntities = entityStateResponse.data.data.map(
        (entity: ProductAPI) => {
          return {
            ...formatEntitiesAPI(entity),
            entityComments: formatComment(entity, null),
            metrics: formatEntityMetrics(entity)
          } as Product;
        }
      );
    }
    return formattedEntities;
  } catch (e) {
    console.error(e);
    alert(
      'There was an issue retreiving the products. Please refresh the page to try again. If the problem continues please contact Levno. '
    );
    router.push('home');
    return [];
  }
};

export const FetchUsers = async (request: {
  partnerId: number;
}): Promise<any> => {
  const users = await HttpClient.get<UserResponse>(
    `/orgs/${request.partnerId}/users`
  );
  const alteredUsers = users.data.data.map(user => {
    return { ...user, role: toTitleCase(user.role?.split('.')[1] ?? '') };
  });
  return alteredUsers;
};

export const FetchPendingSites = async (request: {
  partnerId: number;
  productType: string;
}): Promise<any> => {
  try {
    const sites = await HttpClient.get<any>(
      `/partner/${request.partnerId}/pendingSites/${request.productType}`
    );
    let formattedEntities;
    if (isApp(Constants.PRODUCT_TYPE_MILK)) {
      formattedEntities = formatMilkEntitiesAPI(sites.data.data);
    } else if (isApp(Constants.PRODUCT_TYPE_FUEL)) {
      formattedEntities = await formatFuelEntitiesAPI(sites.data.data);
    } else if (isApp(Constants.PRODUCT_TYPE_WATER)) {
      formattedEntities = formatWaterEntitiesAPI(sites.data.data);
    } else if (isApp(Constants.PRODUCT_TYPE_FEED)) {
      formattedEntities = formatFeedEntitiesAPI(sites.data.data);
    } else {
      formattedEntities = sites.data.data.map((entity: ProductAPI) => {
        return {
          ...formatEntitiesAPI(entity),
          entityComments: formatComment(entity, null),
          metrics: formatEntityMetrics(entity)
        } as Product;
      });
    }
    return formattedEntities;
  } catch (e) {
    return [];
  }
};

export const FetchNewSites = async (request: {
  partnerId: number;
  productType: string;
}): Promise<any> => {
  try {
    const sites = await HttpClient.get<any>(
      `/partner/${request.partnerId}/newSites/${request.productType}`
    );
    return sites.data.data.map((site: any) => {
      return {
        ...site,
        isNew: isNew(site.productLiveAt),
        liveAt: site.productLiveAt,
        customFieldData: ConvertCustomFields(site.customFieldData),
        entityCustomFieldData: ConvertCustomFields(site.entityCustomFieldData)
      };
    });
  } catch (e) {
    return [];
  }
};

export const FetchSingularProduct = async (request: {
  partnerId: number;
  productId: number;
}): Promise<Product[]> => {
  try {
    const site = await HttpClient.get<any>(
      `/partner/${request.partnerId}/product/${request.productId}`
    );
    return await formatAppEntitiesAPI(site.data);
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const FetchVatGraphData = async (
  vatId: string,
  startTime: string,
  finishTime: string,
  disabled: boolean,
  source: string
): Promise<any> => {
  if (disabled) {
    return JSON.parse(JSON.stringify(milkVatGraphPlaceholder));
  } else if (IS_UAT) {
    const graphData = await FetchDemoTimeseries(
      vatId,
      startTime,
      finishTime,
      source
    );
    const data = {
      volume: [
        ...(graphData.volume?.map(reading => {
          return {
            ...reading,
            timestamp: getMillisecondTimestamp(reading.timestamp)
          };
        }) ?? [])
      ],
      volumeEvents: [
        ...(graphData.milkingFinish?.map((data: any) => {
          return {
            value: +data.value,
            timestamp: getMillisecondTimestamp(data.timestamp),
            label: 'milkingFinish'
          };
        }) ?? []),
        ...(graphData.milkingFinish?.map((data: any) => {
          return {
            value: +data.startVolume,
            timestamp: getMillisecondTimestamp(data.startTime),
            label: 'milkingStart'
          };
        }) ?? []),
        ...(graphData.pickupFinish?.map((data: any) => {
          return {
            value: +data.value,
            timestamp: getMillisecondTimestamp(data.timestamp),
            label: 'pickupFinish'
          };
        }) ?? [])
      ]
    };
    return data;
  } else {
    const graphData = await FetchVatTimeseries(
      vatId,
      startTime,
      finishTime,
      [
        'milkingFinish',
        'milkingStart',
        'pickupFinish',
        'pickupStart',
        'volume'
      ],
      source
    );
    return graphData;
  }
};

export const FetchVatWashData = async (
  vatId: string,
  startTime: string,
  finishTime: string,
  nDays: number,
  offset: number,
  vatName: string,
  source: string
): Promise<any> => {
  if (IS_UAT) {
    const graphData = await FetchDemoTimeseries(
      vatId,
      startTime,
      finishTime,
      source
    );
    const data = {
      vatTempEvents: [
        ...(graphData.vatWashFinish?.map((data: any) => {
          return {
            value: +data.value,
            timestamp: data.timestamp * 1000,
            label: 'vatWashFinish'
          };
        }) ?? [])
      ]
    };
    return { name: vatName, data: data };
  } else {
    const graphData = await FetchVatTimeseries(
      vatId,
      startTime,
      finishTime,
      ['vatWashFinish'],
      source
    );
    return { name: vatName, data: graphData };
  }
};

export const FetchProductsCombinedGraphData = async (
  supplierNumber: string,
  supplier: string | undefined,
  entityID: string,
  startTime: string,
  finishTime: string,
  nDays: number,
  offset: number,
  disabled: boolean,
  source: string
): Promise<MilkProductTimeseriesReading[]> => {
  let graphData;
  if (IS_UAT || disabled) {
    graphData = await FetchDemoTimeseries(
      entityID,
      startTime,
      finishTime,
      source
    );
    return (
      graphData.milkingFinish?.map((milkingFinish: any) => {
        return { ...milkingFinish, finishTime: milkingFinish.timestamp };
      }) ?? []
    );
  } else {
    graphData = await FetchProductsTimeseries(
      supplierNumber,
      supplier,
      nDays,
      offset,
      'milking',
      source
    );
  }
  const wholeStartDay = dayjs
    .unix(+startTime)
    .hour(0)
    .minute(1)
    .unix();
  const wholeFinishDay = dayjs
    .unix(+finishTime)
    .hour(23)
    .minute(59)
    .unix();
  const filteredItems = graphData.filter(
    (item: { startTime: number; finishTime: number; value: number }) => {
      return item.startTime > wholeStartDay && item.finishTime < wholeFinishDay;
    }
  );
  return filteredItems;
};

export const FetchProductsCombinedEventsData = async (
  supplierNumber: string,
  supplier: string | undefined,
  startTime: string,
  finishTime: string,
  nDays: number,
  offset: number,
  series: 'milking' | 'pickup',
  source: string
): Promise<MilkProductTimeseriesReading[]> => {
  let graphData;
  if (IS_UAT) {
    graphData = await FetchDemoTimeseries('1', startTime, finishTime, source);
    if (series == 'milking') {
      return (
        graphData.milkingFinish?.map((milkingFinish: any) => {
          return { ...milkingFinish, finishTime: milkingFinish.timestamp };
        }) ?? []
      );
    } else if (series == 'pickup') {
      return (
        graphData.pickupFinish?.map((pickupFinish: any) => {
          return { ...pickupFinish, finishTime: pickupFinish.timestamp };
        }) ?? []
      );
    } else {
      return [];
    }
  } else {
    return await FetchProductsTimeseries(
      supplierNumber,
      supplier,
      nDays,
      offset,
      series,
      source
    );
  }
};

export const FetchMilkScoreHistory = async (
  entityID: string,
  startTime: string,
  finishTime: string,
  customSettings: ComplianceSettings,
  disabled: boolean
): Promise<VatMilkScoreTimeseries> => {
  if (disabled) {
    return milkScoreHistoryPlaceholder;
  }
  return await FetchMilkScoreHistoryTimeseries(
    entityID,
    customSettings,
    startTime,
    finishTime
  );
};

export const FetchFuelEventsData = async (
  entityID: string,
  startTime: string,
  finishTime: string,
  source: string
): Promise<TankTimeseries> => {
  let graphData;
  try {
    if (IS_UAT) {
      graphData = await FetchDemoTimeseries(
        entityID,
        startTime,
        finishTime,
        source
      );
      return {
        daily: graphData.daily ?? [],
        refill: graphData.refill ?? [],
        withdrawal: graphData.withdrawal ?? []
      } as TankTimeseries;
    } else {
      return await FetchTankTimeseries(entityID, startTime, finishTime, source);
    }
  } catch {
    return { daily: [], refill: [], withdrawal: [] };
  }
};

export const FetchFuelBurnGraphData = async (
  entityID: string,
  startTime: string,
  finishTime: string,
  disabled: boolean,
  source: string
): Promise<TankTimeseries> => {
  let graphData;
  try {
    if (IS_UAT || disabled) {
      graphData = fuelPlaceholderData.graphData;

      return {
        refill: graphData.refill.filter((refill: any) => {
          return (
            refill.timestamp > +startTime && refill.timestamp < +finishTime
          );
        }),
        daily: graphData.daily.filter((daily: any) => {
          return daily.timestamp > +startTime && daily.timestamp < +finishTime;
        }),
        withdrawal: graphData.withdrawal.filter((withdrawal: any) => {
          return (
            withdrawal.timestamp > +startTime &&
            withdrawal.timestamp < +finishTime
          );
        })
      };
    } else {
      return await FetchTankTimeseries(entityID, startTime, finishTime, source);
    }
  } catch {
    return { daily: [], refill: [], withdrawal: [] };
  }
};

export const FetchFeedLevelGraphData = async (
  entityID: string,
  nDays: number,
  offset: number,
  source: string
): Promise<FeedLevel[]> => {
  const graphData = await FetchFeedTankTimeseries(
    entityID,
    nDays,
    offset,
    'level',
    source
  );
  return graphData.level ?? [];
};

export const FetchFeedBalanceGraphData = async (
  entityID: string,
  startTime: string,
  finishTime: string,
  disabled: boolean,
  type: 'usage' | 'activity',
  source: string,
  series?: string
): Promise<{ balance: FeedBalance[]; level: FeedLevel[] }> => {
  let graphData;
  if (disabled) {
    if (type == 'activity') {
      graphData = fuelPlaceholderData.graphData.daily.filter(withdrawal => {
        return (
          withdrawal.timestamp > +startTime &&
          withdrawal.timestamp < +finishTime
        );
      });
    } else {
      graphData = fuelDailyGraphValues.filter(withdrawal => {
        return (
          withdrawal.timestamp > +startTime &&
          withdrawal.timestamp < +finishTime
        );
      });
    }

    return {
      balance: graphData.map((entry: TankTimeseriesReading) => {
        return {
          percent: 0,
          date: dayjs.unix(entry.timestamp).format(),
          usage: entry.value,
          volume: entry.value,
          usageWeight: entry.value,
          volumeWeight: entry.value
        };
      }),
      level: []
    };
  } else {
    let offset = datesToOffset(+finishTime);
    if (type == 'usage') {
      offset = offset == 0 ? -1 : offset;
    }
    graphData = await FetchFeedTankTimeseries(
      entityID,
      datesToDays(+finishTime, +startTime),
      offset,
      series ?? 'balance,level',
      source
    );
    let level = graphData.level ?? [];
    let prevDate: string | null = null;
    level = level.filter(x => {
      if (
        prevDate === null ||
        new Date(x.date).getTime() >
          new Date(prevDate).getTime() + 1000 * 60 * 60 * 4
      ) {
        prevDate = x.date;
        return true;
      }
      return false;
    });
    return {
      balance: graphData.balance ?? [],
      level
    };
  }
};

export const FetchFeedRefillGraphData = async (
  entityID: string,
  nDays: number,
  offset: number,
  source: string
): Promise<FeedLevel[]> => {
  const graphData = await FetchFeedTankTimeseries(
    entityID,
    nDays,
    offset,
    'logical-refill',
    source
  );
  return graphData['logical-refill'] ?? [];
};

export const FetchWaterGraphData = async (
  entityID: string,
  startTime: string,
  finishTime: string,
  disabled: boolean,
  source: string
): Promise<MeterTimeseriesReading[]> => {
  let graphData;
  if (disabled) {
    graphData = fuelDailyGraphValues
      .filter(withdrawal => {
        return (
          withdrawal.timestamp > +startTime &&
          withdrawal.timestamp < +finishTime
        );
      })
      .map(value => {
        return { ...value, volume: value.value, finishTime: value.timestamp };
      });

    return graphData as MeterTimeseriesReading[];
  } else {
    graphData = await FetchWaterMeterTimeseries(
      entityID,
      startTime,
      finishTime,
      source
    );
    return (graphData as MeterTimeseriesReading[]) ?? [];
  }
};

export const FetchWaterTankGraphData = async (
  entityID: string,
  startTime: string,
  finishTime: string,
  source: string
): Promise<WaterTankVolumePercent[]> => {
  const graphData = await FetchWaterTankTimeseriesPercent(
    entityID,
    startTime,
    finishTime,
    source
  );
  return (graphData as WaterTankVolumePercent[]) ?? [];
};

export const FetchFuelGraphData = async (
  entityID: string,
  startTime: string,
  finishTime: string,
  disabled: boolean,
  source: string
): Promise<TankTimeseriesReading[]> => {
  let graphData;
  if (IS_UAT || disabled) {
    console.log(startTime, finishTime);
    graphData = fuelDailyGraphValues.filter(withdrawal => {
      return (
        withdrawal.timestamp > +startTime && withdrawal.timestamp < +finishTime
      );
    });

    return graphData;
  } else {
    graphData = await FetchTankTimeseries(
      entityID,
      startTime,
      finishTime,
      source
    );
    return graphData.withdrawal ?? [];
  }
};
