import { AxiosError } from 'axios';
import ApiError from './models/ApiError';

export const formatError = (error: AxiosError): ApiError => {
  return {
    status: error.response?.status ?? 500,
    errorMessage:
      error.response?.data.error ??
      error.response?.data.errors ??
      error.response?.data.message ??
      'Unexpected error'
  };
};
