
import { trackEvent } from '@/services/Mixpanel';
import { getLevnoPhoneNumber } from '@/utils/LocationUtils';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  methods: {
    getLevnoPhoneNumber
  }
})
export default class Home extends Vue {
  mounted() {
    trackEvent(`User viewing default home page`);
  }
}
