import CustomField from '@/store/models/CustomField';
import CustomFieldData from '@/store/models/CustomFieldData';
import {
  defaultCustomSettings,
  FuelCustomSettingsData
} from '@/store/models/CustomSettingsData';
import { ComplianceSettings } from '@/store/models/MilkScore';
import { Product } from '@/store/models/Product';

export function ConvertCustomFields(
  customfields: string | CustomFieldData[] | null
) {
  if (customfields) {
    const originalCustomFields =
      typeof customfields === 'string'
        ? JSON.parse(customfields)
        : customfields;
    if (Array.isArray(originalCustomFields)) {
      const customFieldObject: { [key: string]: string } = {};
      if (originalCustomFields) {
        originalCustomFields.forEach((field: CustomFieldData) => {
          customFieldObject[field.name] = field.value;
        });
        return customFieldObject;
      }
    }
  }
  return null;
}

export function ConvertCustomSettings(
  customSettings: string | { [key: string]: string } | null
): FuelCustomSettingsData | ComplianceSettings | null {
  if (customSettings) {
    const originalCustomSettings =
      typeof customSettings === 'string'
        ? JSON.parse(customSettings)
        : customSettings;
    if (Array.isArray(originalCustomSettings)) {
      //is legacy code
      const customSettingsObject: FuelCustomSettingsData | undefined =
        defaultCustomSettings?.fuel?.tanks;
      if (originalCustomSettings[0] && customSettingsObject) {
        customSettingsObject.criticalLevelThreshold =
          originalCustomSettings[0].fuelCriticalLevelThreshold;
        customSettingsObject.lowLevelThreshold =
          originalCustomSettings[0].fuelLowLevelThreshold;
        return customSettingsObject;
      }
      return originalCustomSettings[0];
    } else {
      const customSettingsObject:
        | FuelCustomSettingsData
        | ComplianceSettings = {
        ...originalCustomSettings
      };

      return customSettingsObject;
    }
  }
  return null;
}

export function generateCustomFieldDisplayData(
  currentProduct: Product | null,
  currentEntity: Product | null,
  siteView: boolean,
  customFields: CustomField[] | null
) {
  let customFieldData: CustomFieldData[] = [];
  if (
    currentProduct &&
    ((siteView && currentProduct.customFieldData != null) ||
      (!siteView && currentEntity))
  ) {
    if (!siteView && currentEntity) {
      const customFieldDataTank = currentEntity?.entityCustomFieldData ?? {};
      customFields?.forEach(field => {
        const value = customFieldDataTank[field.name] ?? '';
        customFieldData.push({
          name: field.name,
          value: value,
          label: field.label
        });
      });
    } else if (siteView && currentProduct.customFieldData != null) {
      const customFieldDataSite = currentProduct.customFieldData || {};
      customFields?.forEach(field => {
        const value = customFieldDataSite[field.name] ?? '';
        customFieldData.push({
          name: field.name,
          value: value,
          label: field.label
        });
      });
    } else {
      customFieldData = [];
      customFields?.forEach(field => {
        customFieldData.push({
          name: field.name,
          value: '',
          label: field.label
        });
      });
    }
  } else {
    customFieldData = [];
    customFields?.forEach(field => {
      customFieldData.push({
        name: field.name,
        value: '',
        label: field.label
      });
    });
  }
  return customFieldData;
}
