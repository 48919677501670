import User from '@/store/modules/User';
import { Constants } from '@/utils/Constants';
import { isAdmin } from '@/utils/TestingValidity';
import { NavigationGuardNext, Route } from 'vue-router';

export const ProductGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  await User.tryRefreshUser();
  const productTypes: string[] = User.token.orgs[User._orgIndex].productTypes;
  const navigatingTo: string = to.meta?.productType;
  const pageName: string = to.meta?.pageName;
  if (productTypes.length === 0) {
    pageName ? next(`/${to.meta?.pageName}`) : next('/home');
  } else if (productTypes.includes(navigatingTo)) {
    next();
  } else {
    pageName
      ? next(`/${productTypes[0]}-${to.meta?.pageName}`)
      : next(`/${productTypes[0]}-view`);
  }
};

export const AdminGuard = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  await User.tryRefreshUser();
  const productTypes: string[] = User.token.orgs[User._orgIndex].productTypes;
  let navigatingTo: string = to.meta?.productType;
  const tabGuard: boolean = to.meta?.tabGuard;
  const productGuard: boolean = to.meta?.productGuard;

  if (productGuard) {
    if (productTypes.length === 0) {
      next('/home');
    } else if (!productTypes.includes(navigatingTo)) {
      navigatingTo = productTypes[0];
    }
  }
  if (!isAdmin()) {
    // they are not an admin so should not have access to this page unless it's table columns
    if (
      tabGuard &&
      (navigatingTo == Constants.PRODUCT_TYPE_MILK ||
        navigatingTo == Constants.PRODUCT_TYPE_WATER) &&
      (to.params.tab === 'user-management' ||
        to.params.tab === 'alerts-settings')
    ) {
      next(`/${navigatingTo}-settings/tab/custom-field`);
    } else if (
      tabGuard &&
      (navigatingTo == Constants.PRODUCT_TYPE_MILK ||
        navigatingTo == Constants.PRODUCT_TYPE_WATER) &&
      to.params.tab === 'custom-field'
    ) {
      next();
    } else if (
      tabGuard &&
      (navigatingTo == Constants.PRODUCT_TYPE_FUEL ||
        navigatingTo == Constants.PRODUCT_TYPE_FEED) &&
      to.params.tab === 'user-management'
    ) {
      next(`/${navigatingTo}-settings/tab/custom-field`);
    } else if (
      tabGuard &&
      (navigatingTo == Constants.PRODUCT_TYPE_FUEL ||
        navigatingTo == Constants.PRODUCT_TYPE_FEED) &&
      (to.params.tab === 'custom-field' || to.params.tab === 'alerts-settings')
    ) {
      next();
    } else {
      next(`/${navigatingTo}-view`);
    }
  } else {
    next();
  }
};
