import UserResponse from './models/responses/UserResponse';
import HttpClient from '@/services/api/ApiBase';
import { toTitleCase } from '@/utils/FormattingUtils';

export const FetchUsers = async (request: {
  partnerId: number;
}): Promise<any> => {
  const users = await HttpClient.get<UserResponse>(
    `/partner/${request.partnerId}/users`
  );
  const alteredUsers = users.data.data.map(user => {
    return { ...user, role: toTitleCase(user.role?.split('.')[1] ?? '') };
  });
  return alteredUsers;
};

export const CreatePartnerUser = async (request: {
  partnerId: number;
  params: {};
}): Promise<any> => {
  try {
    const response = await HttpClient.post(
      `/partner/${request.partnerId}/user`,
      { data: request.params }
    );
    return response?.data;
  } catch {
    return -1;
  }
};

export const UpdatePartnerUsers = async (request: {
  partnerId: number;
  userId: number;
  params: {};
}): Promise<any> => {
  try {
    const response = await HttpClient.patch(
      `/partner/${request.partnerId}/user/${request.userId}`,
      {
        data: {
          ...request.params,
          partnerId: request.partnerId,
          app: 'partner'
        }
      }
    );
    return response?.data;
  } catch {
    return -1;
  }
};
