import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import Vue from 'vue';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

export const ageOf = Vue.filter('ageOf', (value: string) => {
  return dayjs(value)
    .fromNow()
    .replace('ago', 'old');
});

export const hoursOld = Vue.filter('hoursOld', (value: string) => {
  const hours = dayjs().diff(dayjs(value), 'h');
  return hours + (hours == 1 ? 'h' : 'h');
});

export const hours = Vue.filter('hours', (value: number) => {
  return +dayjs().diff(dayjs(value), 'h');
});

export const days = Vue.filter('days', (value: string) => {
  return dayjs(value).diff(dayjs(), 'd');
});

export const timeAgo = Vue.filter('timeAgo', (value: string) => {
  return dayjs(value).fromNow();
});

export const timeOfDay = Vue.filter('timeOfDay', (value: string) => {
  const date = dayjs(value);
  return date.hour() > dayjs().hour()
    ? date.format('h:mma')
    : date.format('h:mma') + ' tomorrow';
});

export const relativeDay = Vue.filter('relativeDay', (value: number) => {
  const date = dayjs.unix(value);
  if (date.isToday()) {
    // Is today: Today hour:minutes am/pm.
    return date.format('h:mma') + ' today';
  } else if (date.isYesterday()) {
    return date.format('h:mma') + ' yesterday';
  } else {
    return date.format('h:mma D MMM');
  }
});

export const relativeOnlyDay = Vue.filter(
  'relativeOnlyDay',
  (value: number) => {
    const date = dayjs.unix(value);
    if (date.isToday()) {
      return 'Today';
    } else if (date.isYesterday()) {
      return 'Yesterday';
    } else {
      return date.format('D MMM');
    }
  }
);

export function getWeekOfYear(day: Dayjs) {
  return day.isoWeek();
}

export function getStartOfDay(day: Dayjs) {
  return day
    .hour(0)
    .minute(0)
    .second(0);
}

export function getEndOfDay(day: Dayjs) {
  return day
    .hour(23)
    .minute(59)
    .second(59);
}

export function getStartOfMonth(day: Dayjs) {
  return getStartOfDay(day.date(1));
}

export function getEndOfMonth(day: Dayjs) {
  return getEndOfDay(day.date(day.daysInMonth()));
}

export function getStartOfWeek(day: Dayjs) {
  return getStartOfDay(day.subtract(day.day() - 1, 'day'));
}

export function getEndOfWeek(day: Dayjs) {
  return getStartOfDay(day.add(7 - day.day(), 'day'));
}

export function getTimeWindowFormat(event: any) {
  return (
    dayjs.unix(event.startTime).format('h:mma') +
    '-' +
    dayjs.unix(event.timestamp).format('h:mma DD MMM')
  );
}

export function toHoursMinutes(seconds: number) {
  if (isNaN(seconds) || seconds === null) {
    return '';
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - 3600 * hours) / 60);
  return `${hours}h ${minutes}m`;
}

export const formatHours = (value: string) => {
  return +dayjs().diff(dayjs(value), 'h');
};

export const getToday = () => {
  return dayjs();
};

export const getDayjsFromUnix = (value: number) => {
  return dayjs.unix(value);
};

export const getWeeksFromUnix = (value: number | null) => {
  if (!value) return null;
  return +dayjs.unix(value).diff(dayjs(), 'w');
};

export const getDaysFromUnix = (value: number | null) => {
  if (!value) return null;
  return +dayjs.unix(value).diff(dayjs(), 'd');
};

export const getUnixFromDate = (value: string | null) => {
  if (!value) return null;
  return dayjs(value).unix();
};

export const getDate = (value: string) => {
  return dayjs(value).format('Do MMM');
};

export const getDateFromUnix = (value: number) => {
  return dayjs.unix(value).format('Do MMM');
};

export const getMillisecondTimestamp = (value: number) => {
  if (value.toString().length < 13) {
    return value * 1000;
  }
  return value;
};

export const datesToOffset = (to: number) => {
  const offset = dayjs().diff(
    +to > dayjs().unix() ? dayjs() : dayjs.unix(+to),
    'day'
  );
  return offset;
};

export const datesToDays = (to: number, from: number) => {
  return Math.round(
    ((+to > dayjs().unix() ? dayjs().unix() : +to) - +from) / 86000
  );
};

export const isWorkingDay = (workingPattern: 5 | 6 | 7, day: Dayjs) => {
  const dayOfWeek = day.day();
  switch (workingPattern) {
    case 5:
      return dayOfWeek != 0 && dayOfWeek != 6;
    case 6:
      return dayOfWeek != 0;
    default:
      return true;
  }
};

export const workingDaysToFrom = (
  workingPattern: 5 | 6 | 7,
  from: Dayjs,
  to: Dayjs
): number | null => {
  let days = to.diff(from, 'd');
  const start = from.day();
  const end = to.day();
  if (workingPattern == 7 || !days) {
    return days;
  } else {
    const nonWorkingDays = 7 - workingPattern; //returns 1 is they don't work Sunday and 2 for normal business week
    const weeks = to.diff(from, 'w');
    if (weeks == null) return days;
    //Subtract non working days for every week in between
    days -= weeks * nonWorkingDays;
    if (
      (start == 6 && end != 6) ||
      (start != 6 && end == 6 && nonWorkingDays == 2)
    ) {
      //Remove extra Sat or Sun
      days--;
    } else if (start - end >= 1) {
      //Remove extra non working days
      days -= nonWorkingDays;
    }

    return days;
  }
};

export const workingDays = (
  workingPattern: 5 | 6 | 7,
  value: number
): number | null => {
  return workingDaysToFrom(workingPattern, dayjs(), dayjs.unix(value));
};
