export enum PermissionName {
  viewRequests = 'view.current.requests',
  createRequests = 'create.new.requests',
  viewAlerts = 'view.alerts',
  actionAlerts = 'action.alerts',
  editDefaultAlerts = 'edit.default.alerts',
  manageEmailAlerts = 'manage.email.alerts',
  editSiteDetails = 'edit.site.details',
  addUsers = 'add.users',
  editAndRemoveUsers = 'edit.and.remove.users',
  setUserRoles = 'set.user.roles',
  editSystemRoles = 'edit.system.roles',
  createNewSystemRoles = 'create.new.system.roles',
  manageCustomFieldDefinitions = 'manage.custom.field.definitions',
  editCustomFieldsData = 'edit.custom.fields.data',
  generateReports = 'generate.reports',
  editGeneralSettings = 'edit.general.settings'
}