import CustomStore from '@/store/modules/CustomStore';
import { Constants } from './Constants';
import {
  WaterTankAllowedField,
  WaterMeterAllowedField
} from './types/AllowedFields';

export function filterWaterTableCols(columns: any[]) {
  const tankFields = Object.values(WaterTankAllowedField).map(String);
  const meterFields = Object.values(WaterMeterAllowedField).map(String);

  const commonFields = tankFields.filter(field => meterFields.includes(field));

  const uniqueTankFields = tankFields.filter(
    field => !commonFields.includes(field)
  );
  const uniqueMeterFields = meterFields.filter(
    field => !commonFields.includes(field)
  );

  switch (CustomStore?.orgCustomSettings?.water?.entityAccess) {
    case Constants.API_ENTITY_TYPE_WATER_TANK:
      return columns.filter(col => !uniqueMeterFields.includes(col.name));
    case Constants.API_ENTITY_TYPE_WATER_METER:
    default:
      return columns.filter(col => !uniqueTankFields.includes(col.name));
  }
}
export function isWaterTank() {
  return (
    CustomStore?.orgCustomSettings?.water?.entityAccess ==
    Constants.API_ENTITY_TYPE_WATER_TANK
  );
}

export function isWaterMeter() {
  return (
    CustomStore?.orgCustomSettings?.water?.entityAccess ==
    Constants.API_ENTITY_TYPE_WATER_METER
  );
}

export function getEntityAccess() {
  return CustomStore?.orgCustomSettings?.water?.entityAccess;
}

export function actionOnEntityAccess(
  tankFunc: () => any,
  meterFunc: () => any
) {
  const entityAccess = CustomStore?.orgCustomSettings?.water?.entityAccess;

  switch (entityAccess) {
    case Constants.API_ENTITY_TYPE_WATER_TANK:
      return tankFunc();
    case Constants.API_ENTITY_TYPE_WATER_METER:
    default:
      return meterFunc();
  }
}

export function returnOnEntityAccess<T, U>(tankParam: T, meterParam: U): T | U {
  const entityAccess = CustomStore?.orgCustomSettings?.water?.entityAccess;

  switch (entityAccess) {
    case Constants.API_ENTITY_TYPE_WATER_TANK:
      return tankParam;
    case Constants.API_ENTITY_TYPE_WATER_METER:
    default:
      return meterParam;
  }
}
