/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Product,
  WaterMeterMetrics,
  WaterTankMetrics
} from '@/store/models/Product';
import { TableColumnFormatters } from '@/store/models/TableColumn';
import { SetVisibilityOfTableColumns } from '../TableColumnVisibility';
import { AllowedField, WaterAllowedField } from '../types/AllowedFields';
import {
  GetVisibleCustomColumnsFormatters,
  tableColumnFormatters
} from './TableFields';
import {
  formatCurrentUsageDisplay,
  formatPercentWaterFullDisplay,
  formatUsageTodayDisplay,
  formatUsageYesterdayDisplay,
  formatWaterCapacity,
  formatWaterUllageDisplay,
  formatWaterVolumeDisplay
} from '../formatters/DisplayValueFormatters';
import { GetDefaultTableColumns } from '../TableColumns';
import { filterWaterTableCols } from '../WaterUtils';

export const waterSpecificTableColumnsFormatters: {
  [key in WaterAllowedField]: TableColumnFormatters;
} = {
  [WaterAllowedField.CUSTOM_ID]: {
    name: WaterAllowedField.CUSTOM_ID,
    label: () => 'Id',
    visible: true,
    width: 100,
    filterable: false,
    searchable: true,
    sortable: true,
    searchKey: (item: Product) => {
      return item.partnerDefinedId;
    },
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by partner defined id and entities by entity id
      return sortType == 'child' ? item.entityId : item.partnerDefinedId;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item.partnerDefinedId ? item.partnerDefinedId : '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return ''; //blank
    },
    textStyling: 'textOverflowEllipsis'
  },
  [WaterAllowedField.CURRENT_USAGE]: {
    name: WaterAllowedField.CURRENT_USAGE,
    label: () => 'Current Usage',
    visible: true,
    width: 140,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min current usage of entities
      //Sort entities by current usage value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as WaterMeterMetrics).currentUsage;
      } else {
        return (item.entities[0].metrics as WaterMeterMetrics).currentUsage;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatCurrentUsageDisplay(entity);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatCurrentUsageDisplay(entity);
    }
  },
  [WaterAllowedField.USAGE_TODAY]: {
    name: WaterAllowedField.USAGE_TODAY,
    label: () => 'Usage Today',
    visible: true,
    width: 140,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min usage of entities
      //Sort entities by usage value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as WaterMeterMetrics).usageToday;
      } else {
        return (item.entities[0].metrics as WaterMeterMetrics).usageToday;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatUsageTodayDisplay(entity);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatUsageTodayDisplay(entity);
    }
  },
  [WaterAllowedField.USAGE_YESTERDAY]: {
    name: WaterAllowedField.USAGE_YESTERDAY,
    label: () => 'Usage Yesterday',
    visible: true,
    width: 140,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min usage of entities
      //Sort entities by usage value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as WaterMeterMetrics).usageYesterday;
      } else {
        return (item.entities[0].metrics as WaterMeterMetrics).usageYesterday;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatUsageYesterdayDisplay(entity);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatUsageYesterdayDisplay(entity);
    }
  },
  [WaterAllowedField.PERCENT]: {
    name: WaterAllowedField.PERCENT,
    label: () => 'Percentage',
    visible: true,
    width: 140,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sorts products by amount of water in tank, via the percent
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as WaterTankMetrics).percent;
      } else {
        return (item.entities[0].metrics as WaterTankMetrics).percent;
      }
    },
    parentFormattedValue: (_: Product, entity: any) => {
      return formatPercentWaterFullDisplay(entity);
    },
    childFormattedValue: (_: Product, entity: any) => {
      return formatPercentWaterFullDisplay(entity);
    }
  },
  [WaterAllowedField.VOLUME]: {
    name: WaterAllowedField.VOLUME,
    label: () => 'Volume',
    visible: true,
    width: 140,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sorts products by amount of water in tank, via the volume
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as WaterTankMetrics).volume;
      } else {
        return (item.entities[0].metrics as WaterTankMetrics).volume;
      }
    },
    parentFormattedValue: (_: Product, entity: any) => {
      return formatWaterVolumeDisplay(entity);
    },
    childFormattedValue: (_: Product, entity: any) => {
      return formatWaterVolumeDisplay(entity);
    }
  },
  [WaterAllowedField.VOLUME_CAPACITY]: {
    name: WaterAllowedField.VOLUME_CAPACITY,
    label: () => 'Capacity',
    visible: true,
    width: 140,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sorts products by capacity of tank
      return item.capacity;
    },
    parentFormattedValue: (_: Product, entity: any) => {
      return formatWaterCapacity(entity);
    },
    childFormattedValue: (_: Product, entity: any) => {
      return formatWaterCapacity(entity);
    }
  },
  [WaterAllowedField.REMAINING]: {
    name: WaterAllowedField.REMAINING,
    label: () => 'Ullage',
    visible: true,
    width: 140,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sorts products by amount needed to fill tank
      if (sortType == 'child' || !item.entities) {
        if (!item.capacity) return '-';
        return item.capacity - (item.metrics as WaterTankMetrics).volume;
      } else {
        if (!item.entities[0].capacity) return '-';
        return (
          item.entities[0].capacity -
          (item.entities[0].metrics as WaterTankMetrics).volume
        );
      }
    },
    parentFormattedValue: (_: Product, entity: any) => {
      return formatWaterUllageDisplay(entity);
    },
    childFormattedValue: (_: Product, entity: any) => {
      return formatWaterUllageDisplay(entity);
    }
  }
};

export const waterTableColumnsFormatters = {
  ...tableColumnFormatters,
  ...waterSpecificTableColumnsFormatters
};

export function GetWaterTableColumnFormatters(
  fields: (WaterAllowedField | AllowedField)[]
) {
  const baseColumnsWithFormatters = fields.map(column => {
    return waterTableColumnsFormatters[column];
  });

  return filterWaterTableCols(baseColumnsWithFormatters);
}

export async function GetVisibleWaterTableColumnFormatters(
  fields: (WaterAllowedField | AllowedField)[],
  addCustomFields: boolean
) {
  const defaultColumnsWithVisibility = GetDefaultTableColumns();
  let volumePercentBarColumn: TableColumnFormatters | undefined = undefined;
  const baseColumnsWithFormatters = SetVisibilityOfTableColumns(
    fields.map(column => {
      return waterTableColumnsFormatters[column];
    })
  )
    .filter(column => {
      if (column.name == AllowedField.VOLUME_PERCENT_BAR) {
        volumePercentBarColumn = column;
        return defaultColumnsWithVisibility[WaterAllowedField.VOLUME].visible;
      }
      return column.visible;
    })
    .filter(item => item !== volumePercentBarColumn);

  if (volumePercentBarColumn) {
    baseColumnsWithFormatters.push(volumePercentBarColumn);
  }

  if (addCustomFields) {
    const customColumnsWithFormatters = await GetVisibleCustomColumnsFormatters();
    return [...baseColumnsWithFormatters, ...customColumnsWithFormatters];
  } else {
    return baseColumnsWithFormatters;
  }
}

export function GetDefaultWaterColumns() {
  return Object.fromEntries(
    Object.entries(waterTableColumnsFormatters).filter(column => {
      return column[0].includes('Default');
    })
  );
}
