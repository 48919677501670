
import AppName from '@/components/mixin/AppName.vue';
import ChangeOrgModal from '@/components/modal/ChangeOrgModal.vue';
import router from '@/router';
import { isUat } from '@/services/api/Environment';
import { setStoreLoadingState } from '@/store/Globals';
import CustomStore from '@/store/modules/CustomStore';
import ProductStore from '@/store/modules/ProductStore';
import User from '@/store/modules/User';
import { getPluralEntityName } from '@/utils/AppName';
import { getAppFilter } from '@/utils/GenericUtils';
import LocalStorageUtils, { getListItem } from '@/utils/LocalStorageUtils';
import { isAdmin } from '@/utils/TestingValidity';
import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ConstantsMixin from '../mixin/Constants.vue';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventFilterEntities } from '@/services/ga/events/EventFilterEntities';
import { EventChangeOrg } from '@/services/ga/events/EventChangeOrg';
import { PermissionService } from '@/services/PermissionService';
import { Constants } from '@/utils/Constants';
import { getColour } from '@/utils/MapUtils';

@Component({
  components: {
    ChangeOrgModal
  },
  methods: {
    isAdmin,
    isUat,
    getPluralEntityName
  },
  computed: {
    ...mapGetters('user', {
      currentUser: 'currentUser',
      token: 'token',
      orgIndex: 'orgIndex'
    }),
    currentRoute: () => ProductStore.currentRoute ?? []
  },
  watch: {
    $route: function() {
      this.$root.$emit('pageChanged');
    }
  }
})
export default class NavigationPanel extends mixins(AppName, ConstantsMixin) {
  private route = this.$route.path;
  public width = 1000;
  public async logout(): Promise<void> {
    await User.logout();
    this.$router.push('/auth/login');
  }
  public orgName = '';
  public globalLowCriticalLevel = {};
  public savedAlertType = ['score'];
  public currentApp = this.PRODUCT_TYPE_MILK;

  @Inject('permissionService')
  public permissionService!: PermissionService;

  created() {
    const savedAlertType = getListItem('milkAlertType');
    if (savedAlertType && savedAlertType.length > 0) {
      this.savedAlertType = savedAlertType;
    }
    window.addEventListener('resize', this.updateWidth);
    this.$root.$on(
      ['customFieldsUpdated', 'alertsUpdated', 'orgIndexChanged'],
      () => {
        setStoreLoadingState(true);
        this.orgName = User.token.orgs[User._orgIndex].orgName;
        this.loadCustomSettings();
      }
    );
    this.$root.$on('milkAlertTypeChanged', () => {
      const savedAlertType = getListItem('milkAlertType');
      if (savedAlertType) {
        this.savedAlertType = savedAlertType;
      }
    });
    this.$root.$on('pageChanged', () => {
      if (this.getAppName(false) != this.currentApp) {
        this.currentApp = this.getAppName(false);
        this.loadCustomSettings();
      }
    });
  }
  destroyed() {
    document.removeEventListener('mouseup', this.updateWidth);
  }

  mounted() {
    setTimeout(() => {
      //Requires timeout to load the app name
      this.loadCustomSettings();
      this.currentApp = this.getAppName(false);
      const org = User.token.orgs[User._orgIndex];
      this.orgName = org ? org.orgName : '';
      this.updateWidth();
    }, 500);
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }

  get orderedOrgs() {
    const orgsWithIndex = User.token.orgs.map((org, index) => {
      return { ...org, orgIndex: index };
    });
    return orgsWithIndex.sort((a: any, b: any) => {
      return a.orgName < b.orgName ? -1 : 1;
    });
  }

  public async loadCustomSettings() {
    await CustomStore.setAllCustomStates();
    await ProductStore.fetchAllEntityStates(this.getAppName(false));
    setStoreLoadingState(false);
  }

  public getPageName() {
    const route = this.$route.path;
    const findTerm = (term: string) => {
      if (route.includes(term)) {
        return route;
      }
    };

    switch (this.$route.path) {
      case findTerm('view'):
        return 'products';
      case findTerm('sites'):
        return 'sites';
      case findTerm('settings'):
        return 'settings';
      case findTerm('email-alerts'):
        return 'email-alerts';
      case findTerm('alerts'):
        return 'alerts';
      case findTerm('requests'):
        return 'requests';
      case findTerm('help'):
        return 'help';
      case findTerm('route-plan'):
        return 'route-plan';
      case '/home':
        return 'DASHBOARD';
      case '/profile':
        return 'profile';
      case findTerm('details'):
        return 'details';
      default:
        return '';
    }
  }

  public goToPlanRoute() {
    router.push(this.getPageRoute('route-plan'));
  }

  public goToAlertsPage() {
    router.push(this.getPageRoute('alerts'));
  }

  public goToPage(page: string) {
    router.push(this.getPageRoute(page));
  }

  public desktopSettingsRoute(): string | null {
    const settingsPage = `/${this.getAppName(false)}-settings/tab`;
    if (this.canManageUsers) {
      return settingsPage + '/user-management';
    }
    if (this.canManageRoles) {
      return settingsPage + '/role-management';
    }
    if (this.canManageCustomFields) {
      return settingsPage + '/custom-field';
    }
    if (this.canManageAlerts) {
      return settingsPage + '/alerts-settings';
    }
    return null;
  }

  public getPageRoute(route: string): string {
    if (route == 'profile') {
      return `/${route}`;
    } else if (route == 'email-alerts') {
      return `/${this.getAppName(false)}-${route}`;
    } else if (this.isApp(this.PRODUCT_TYPE_MILK)) {
      return `/milk-${route}`;
    } else if (this.isApp(this.PRODUCT_TYPE_FUEL)) {
      return `/fuel-${route}`;
    } else if (this.isApp(this.PRODUCT_TYPE_WATER)) {
      return `/water-${route}`;
    } else if (this.isApp(this.PRODUCT_TYPE_FEED)) {
      return `/feed-${route}`;
    } else if (this.isApp(this.PRODUCT_TYPE_GENERIC)) {
      return `/generic-${route}`;
    } else {
      const orgs = JSON.parse(LocalStorageUtils.getItem('ORGS') || '[]')[0];
      if (orgs) {
        const defaultPage = orgs.productTypes;
        return `/${defaultPage[0]}-${route}`;
      } else {
        return '';
      }
    }
  }

  public getNavStyle(page: string) {
    const classStyle = 'text-center p-0 m-0 text-mediumGrey';
    if (page == this.getPageName()) {
      return `${classStyle} bg-grey border-top-0 border-right-0 border-bottom-0 border-2 border-${this.getAppColour(
        true
      )}`;
    } else if (page in ['help', 'settings']) {
      return `notImplemented sideNav bg-white ${classStyle}`;
    } else {
      return `sideNav bg-white ${classStyle}`;
    }
  }

  public getColour() {
    return this.getAppColour(true);
  }

  public get canSeeRequests(): boolean {
    return this.permissionService.canSeeRequests;
  }

  public get canSeeAlerts(): boolean {
    return this.permissionService.canSeeAlerts;
  }

  public get canManageAlerts(): boolean {
    return this.permissionService.canManageAlerts;
  }

  public get canManageUsers(): boolean {
    return this.permissionService.canManageUsers;
  }

  public get canManageCustomFields(): boolean {
    return this.permissionService.canManageCustomFields;
  }

  public get canManageRoles(): boolean {
    return this.permissionService.canManageRoles;
  }

  public get canManageEmailAlerts(): boolean {
    return this.permissionService.canManageEmailAlerts;
  }

  public getTabColour(tab: string) {
    if (this.$route.params.tab == tab) {
      return true;
    } else {
      return false;
    }
  }

  public getLengthRoute() {
    if (this.isApp(this.PRODUCT_TYPE_MILK)) {
      return ProductStore.currentRoute.milk
        ? ProductStore.currentRoute.milk.filter(
            item => item.split('-')[2] != '0000'
          ).length
        : 0;
    } else if (this.isApp(this.PRODUCT_TYPE_FUEL)) {
      return ProductStore.currentRoute.fuel
        ? ProductStore.currentRoute.fuel.filter(
            item => item.split('-')[2] != '0000'
          ).length
        : 0;
    } else if (this.isApp(this.PRODUCT_TYPE_FEED)) {
      return ProductStore.currentRoute.feed
        ? ProductStore.currentRoute.feed.filter(
            item => item.split('-')[2] != '0000'
          ).length
        : 0;
    } else if (this.isApp(this.PRODUCT_TYPE_WATER)) {
      return ProductStore.currentRoute.water
        ? ProductStore.currentRoute.water.filter(
            item => item.split('-')[2] != '0000'
          ).length
        : 0;
    } else if (this.isApp(this.PRODUCT_TYPE_GENERIC)) {
      return ProductStore.currentRoute.generic
        ? ProductStore.currentRoute.generic.filter(
            item => item.split('-')[2] != '0000'
          ).length
        : 0;
    } else {
      return 0;
    }
  }

  public lengthUnactionedAlerts() {
    return ProductStore.currentAllProducts
      ? getAppFilter().filterAlerts(ProductStore.currentAllProducts ?? [], [
          'unactioned'
        ]).length
      : 0;
  }

  public changeOrg(index: number) {
    const oldProductTypes = User.token.orgs[User._orgIndex].productTypes;
    User.updateOrgIndex(index);

    this.loadCustomSettings();

    const newOrg = User.token.orgs[index];
    GA.event<EventChangeOrg>(
      this.$gtag,
      new EventChangeOrg(User.orgId, newOrg.orgId)
    );

    const newProductTypes = newOrg.productTypes;
    if (!newProductTypes.includes(this.getAppName(false))) {
      this.$router.push(`/${newProductTypes[0]}-view`);
    }
    if (
      JSON.stringify(oldProductTypes.sort()) !=
      JSON.stringify(newProductTypes.sort())
    ) {
      window.location.reload();
    }
    this.$root.$emit('orgIndexChanged');
    this.$root.$emit('routeUpdated');
    this.$root.$emit('sortByUpdated');
    this.$root.$emit('customFieldsUpdated');
    this.$root.$emit('usersUpdated');

    // fix for issue for internal staff only; Aids switching between water_tanks to water_meters
    if (newProductTypes[0] == 'water') window.location.reload();
  }

  public getImage(page: string) {
    if (page == this.getPageName()) {
      return require(`../../assets/${page}-active-${this.getAppName(
        false
      )}.svg`);
    } else {
      return require(`../../assets/${page}-default.svg`);
    }
  }
}
