import {
  FeedMetrics,
  FuelMetricsTank,
  MilkMetrics,
  Product
} from '@/store/models/Product';
import ProductStore from '@/store/modules/ProductStore';
import { isApp } from '../AppName';
import { Constants } from '../Constants';
import { getListItem, setListItem } from '../LocalStorageUtils';
import { productType } from '../types/ProductType';

export class SelectedRoute {
  public routePlanProducts: number[] = [];
  public routePlanEntities: (number | string)[] = [];
  public checked: string[] = [];
  public app: productType;

  constructor(app: productType) {
    this.app = app;
    this.updateChecked();
    this.update();
  }

  public addAllEntitesToChecked(productId: string): string {
    ProductStore.currentProducts?.map((product: Product) => {
      if (product.productId == +productId) {
        if (
          !this.checked.includes(
            'productChecked-' + productId + '-' + product.entityId
          )
        ) {
          this.checked.push(
            'productChecked-' + productId + '-' + product.entityId
          );
        }
      }
    });
    if (!this.checked.includes('productChecked-' + productId + '-0000')) {
      this.checked.push('productChecked-' + productId + '-0000');
    }
    this.updateCookieFromChecked();

    return `${this.getNameOfProduct(
      'productChecked-' + productId + '-0000'
    )} has been added to your route`;
  }

  public addToRoute(item: string): string {
    const prodId = item.split('-')[1];
    if (!this.checked.includes(item)) {
      this.checked.push(item);
      if (
        isApp(Constants.PRODUCT_TYPE_MILK) &&
        !this.isInRoute('productChecked-' + prodId + '-0000')
      ) {
        //this is the first entity of this product, as milk has header entry that needs to be added as well
        this.addToRoute('productChecked-' + prodId + '-0000');
      }
      this.updateCookieFromChecked();
      return `${this.getNameOfProduct(item)} has been added to your route`;
    } else {
      let numEntries = 0; //to check whether it will only be the combined product level entry check after the current one is removed

      this.checked = this.checked.filter(check => {
        if (check.split('-')[1] == prodId) {
          numEntries += 1;
        }
        return check != item;
      });
      if (
        isApp(Constants.PRODUCT_TYPE_MILK) &&
        numEntries == 2 &&
        this.isInRoute('productChecked-' + prodId + '-0000')
      ) {
        //the last entity has been removed, as milk has header entry that needs to be removed as well
        this.addToRoute('productChecked-' + prodId + '-0000');
      }
      this.updateCookieFromChecked();
      return `${this.getNameOfProduct(item)} has been removed to your route`;
    }
  }

  public clear() {
    this.updateStoreRoute([]);

    setListItem(`${this.app}Route`, []);

    this.routePlanProducts = [];
    this.routePlanEntities = [];
    this.checked = [];
  }

  public getTotalVolume() {
    //Milk is volume, fuel and feed is fill volume

    const filtered = ProductStore.currentAllProducts?.filter(product => {
      const entityId = product.entityId ?? '';
      return (
        (this.routePlanProducts.includes(product.productId) &&
          this.routePlanEntities.includes(entityId.toString())) ||
        this.routePlanEntities.includes(+entityId)
      );
    });
    let totVol = 0;
    if (isApp(Constants.PRODUCT_TYPE_MILK)) {
      filtered?.forEach(prod => {
        totVol += (prod.metrics as MilkMetrics).containsMilk
          ? (prod.metrics as MilkMetrics).vatVolume
          : 0;
      });
    } else {
      filtered?.forEach(prod => {
        totVol += (prod.metrics as FuelMetricsTank | FeedMetrics).fillVolume;
      });
    }
    return totVol;
  }

  public getNameOfProduct(productChecked: string) {
    const productId = productChecked.split('-')[1];
    const entityId = productChecked.split('-')[2];
    const item = ProductStore.currentProducts?.filter((product: Product) => {
      if (entityId == '0000') {
        return product.productId == +productId;
      } else {
        return product.productId == +productId && product.entityId == +entityId;
      }
    });
    const name = item
      ? `${item[0].siteName}${
          item[0].name && entityId != '0000' ? ` - ${item[0].name}` : ''
        }`
      : 'Product';
    return name;
    //how to do below for fuel???
    // const tank = item
    //   ? ` ${(item[0].metrics as FuelMetricsTank).fuelType} ${
    //       (item[0].metrics as FuelMetricsTank).nominalVolume
    //     }L`
    //   : '';
    // return name + ' - ' + tank;
  }

  public isInRoute(item: string) {
    return this.checked.includes(item);
  }

  public removeAllEntitesFromChecked(productId: string): string {
    this.checked = this.checked.filter(
      item => !(item.split('-')[1] == productId)
    );

    this.updateCookieFromChecked();
    return `${this.getNameOfProduct(
      'productChecked-' + productId + '-0000'
    )} has been removed from your route`;
  }

  public toggleAllFiltered(selected: boolean, products: Product[]) {
    if (selected) {
      products.forEach((prod: Product) => {
        prod.entities?.forEach((tank: Product) => {
          if (
            !this.checked.includes(
              'productChecked-' + prod.productId + '-' + tank.entityId
            )
          ) {
            this.checked.push(
              'productChecked-' + prod.productId + '-' + tank.entityId
            );
          }
        });
      });
    } else {
      const filteredEnitites: number[] = [];
      products.forEach((prod: Product) => {
        prod.entities?.forEach((tank: Product) => {
          filteredEnitites.push(tank.entityId);
        });
      });
      this.checked = this.checked.filter(
        item => !filteredEnitites.includes(+item.split('-')[2])
      );
    }
    this.updateCookieFromChecked();
  }

  public update() {
    this.routePlanProducts =
      ProductStore.currentRoute[this.app]?.map(item => {
        return +item.split('-')[1];
      }) ?? [];
    this.routePlanEntities =
      ProductStore.currentRoute[this.app]?.map(item => {
        return +item.split('-')[2];
      }) ?? [];
  }

  public updateChecked() {
    this.checked = [...(ProductStore.currentRoute[this.app] ?? [])];
    if (this.checked.length == 0) {
      this.updateCheckedFromCookie();
    }
  }

  public updateCheckedFromCookie() {
    this.checked = getListItem(`${this.app}Route`);
    this.updateStoreRoute(this.checked);
  }

  public updateCheckedFromProduct(
    newProducts: number[],
    oldProducts: number[]
  ) {
    const add = newProducts.filter(prod => !oldProducts.includes(prod));
    const remove = oldProducts.filter(prod => !newProducts.includes(prod));
    if (add.length != 0 || remove.length != 0) {
      const updatedValue =
        add.length != 0
          ? `${this.getNameOfProduct(
              'productChecked-' + add[0] + '-0000'
            )} has been added to your route`
          : `${this.getNameOfProduct(
              'productChecked-' + remove[0] + '-0000'
            )} has been removed from your route`;
      add.length != 0
        ? this.addAllEntitesToChecked(add[0].toString())
        : this.removeAllEntitesFromChecked(remove[0].toString());
      this.updateCookieFromChecked();
      return updatedValue;
    }
    return '';
  }

  public updateCookieFromChecked() {
    setListItem(`${this.app}Route`, this.checked);
    this.updateStoreRoute(this.checked);
  }

  public updateStoreRoute(routeValue: string[]) {
    const route: { [key: string]: string[] } = {};
    route[this.app] = routeValue;
    ProductStore.updateRouteState(route);
  }

  public get updatedValue(): string {
    const add =
      this.checked.filter(
        item => !ProductStore.currentRoute[this.app]?.includes(item)
      ) ?? [];
    const remove =
      ProductStore.currentRoute[this.app]?.filter(
        item => !this.checked.includes(item)
      ) ?? [];
    if (add.length != 0 || remove.length != 0) {
      const updatedValue =
        add.length != 0
          ? `${this.getNameOfProduct(add[0])} has been added to your route`
          : `${this.getNameOfProduct(
              remove[0]
            )} has been removed from your route`;
      this.updateCookieFromChecked();
      return updatedValue;
    } else {
      return '';
    }
  }
}
