import { FeedFilters, feedFilterDefaults } from '@/store/models/Filters';
import { FeedMetrics, Product } from '@/store/models/Product';
import { getFeedAmountFilter, getFeedCapacityFilter } from '../FeedUtils';
import { isUpperBoundDefault } from '../FiltersUtils';
import { getObjectItem } from '../LocalStorageUtils';
import { FiltersClass } from './FiltersClass';

export class FeedFiltersClass extends FiltersClass<FeedFilters> {
  constructor() {
    super('feed', {
      storage: '',
      type: [],
      volumeRange: [0, 0],
      capacityRange: [0, 0],
      weightRange: [0, 0],
      weightCapacityRange: [0, 0],
      customSiteFilters: [],
      customEntityFilters: []
    });
  }

  public filterProducts(products: Product[]) {
    let filtered = [...products];

    if (this.filters.storage != '') {
      filtered = filtered?.filter(product => {
        return (product.metrics as FeedMetrics).storage == this.filters.storage;
      });
    }
    if (this.filters.type.length > 0) {
      filtered = filtered?.filter(product => {
        const type = (product.metrics as FeedMetrics).feedType;
        return this.filters.type.includes(type);
      });
    }
    if (this.filters[getFeedCapacityFilter()][0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as FeedMetrics).nominalVolume >=
          this.filters[getFeedCapacityFilter()][0]
      );
    }

    if (this.filters[getFeedCapacityFilter()][1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as FeedMetrics).nominalVolume <=
          this.filters[getFeedCapacityFilter()][1]
      );
    }

    if (this.filters[getFeedAmountFilter()][0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as FeedMetrics).volume >=
          this.filters[getFeedAmountFilter()][0]
      );
    }

    if (this.filters[getFeedAmountFilter()][1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as FeedMetrics).volume <=
          this.filters[getFeedAmountFilter()][1]
      );
    }

    filtered = super.filterProducts(filtered);

    return filtered;
  }

  public get filtersSet(): boolean {
    return (
      super.filtersSet ||
      this.lowerBoundSet ||
      this.upperBoundSet ||
      this.filters.type.length > 0
    );
  }

  public loadSavedFilters() {
    const saved = getObjectItem('feedFilters');
    if (saved && saved.filters.onlyFaults != 'null') {
      const alteredFilters = { ...feedFilterDefaults, ...saved.filters };
      if (alteredFilters[getFeedAmountFilter()][1] == null) {
        alteredFilters[getFeedAmountFilter()][1] = this.maxVolume;
      }
      if (alteredFilters[getFeedCapacityFilter()][1] == null) {
        alteredFilters[getFeedCapacityFilter()][1] = this.maxCapacity;
      }
      if (!Array.isArray(alteredFilters.type)) {
        alteredFilters.type =
          alteredFilters.type == '' ? [] : [alteredFilters.type];
      }
      if (
        alteredFilters.customEntityFilters == undefined &&
        alteredFilters.customTankFilters != undefined
      ) {
        alteredFilters.customEntityFilters = alteredFilters.customTankFilters;
        delete alteredFilters.customTankFilters;
      }
      this.filters = alteredFilters;
    }
  }

  public get lowerBoundSet(): boolean {
    return [
      this.filters[getFeedAmountFilter()][0],
      this.filters[getFeedCapacityFilter()][0]
    ].some(value => value != 0);
  }

  public setMaxCapacity(maxCap: number) {
    if (
      !this.filters[getFeedCapacityFilter()][1] ||
      this.filters[getFeedCapacityFilter()][1] == this.maxCapacity
    ) {
      this.filters[getFeedCapacityFilter()][1] = maxCap;
    }
    this.maxCapacity = maxCap;
  }

  public setMaxVolume(maxVol: number) {
    if (
      !this.filters[getFeedAmountFilter()][1] ||
      this.filters[getFeedAmountFilter()][1] == this.maxVolume
    ) {
      this.filters[getFeedAmountFilter()][1] = maxVol;
    }

    this.maxVolume = maxVol;
  }

  public get upperBoundSet(): boolean {
    return [
      isUpperBoundDefault(
        this.filters[getFeedAmountFilter()][1],
        this.maxVolume
      ),
      isUpperBoundDefault(
        this.filters[getFeedCapacityFilter()][1],
        this.maxCapacity
      )
    ].includes(false);
  }
}
