import { PermissionName } from "@/store/models/PermissionName";
import User from "@/store/modules/User";

export class PermissionService {

  public get canGenerateReports(): boolean {
    return this.hasPermission(PermissionName.generateReports);
  }

  public get canActionAlerts(): boolean {
    return this.hasPermission(PermissionName.actionAlerts);
  }

  public get canSeeRequests(): boolean {
    return this.hasPermission(PermissionName.viewRequests);
  }

  public get canSeeAlerts(): boolean {
    return this.hasPermission(PermissionName.viewAlerts);
  }

  public get canManageAlerts(): boolean {
    return this.hasPermission(PermissionName.editDefaultAlerts);
  }

  public get canManageDefaultAlerts(): boolean {
    return this.hasPermission(PermissionName.editDefaultAlerts);
  }

  public get canEditCustomFieldsData(): boolean {
    return this.hasPermission(PermissionName.editCustomFieldsData);
  }

  public get canEditSiteSettings(): boolean {
    return this.hasPermission(PermissionName.editSiteDetails);
  }

  public get canAddRoles(): boolean {
    return this.hasPermission(PermissionName.createNewSystemRoles);
  }

  public get canCreateRequests(): boolean {
    return this.hasPermission(PermissionName.createRequests)
  }

  public get canManageUsers(): boolean {
    return (
      this.hasPermission(PermissionName.editAndRemoveUsers) || 
      this.hasPermission(PermissionName.addUsers) ||
      this.hasPermission(PermissionName.setUserRoles)
    );
  }

  public get canManageCustomFields(): boolean {
    return this.hasPermission(PermissionName.manageCustomFieldDefinitions);
  }

  public get canAddUsers(): boolean {
    return this.hasPermission(PermissionName.addUsers);
  }

  public get canSetUserRoles(): boolean {
    return this.hasPermission(PermissionName.setUserRoles);
  }

  public get canEditAndRemoveUsers(): boolean {
    return this.hasPermission(PermissionName.editAndRemoveUsers);
  }

  public get canManageRoles(): boolean {
    return (
      this.hasPermission(PermissionName.editSystemRoles) || 
      this.hasPermission(PermissionName.createNewSystemRoles)
    );
  }

  public get canEditSystemRoles(): boolean {
    return this.hasPermission(PermissionName.editSystemRoles);
  }

  public get canManageEmailAlerts(): boolean {
    return this.hasPermission(PermissionName.manageEmailAlerts);
  }

  public get canEditGeneralSettings(): boolean {
    return this.hasPermission(PermissionName.editGeneralSettings);
  }

  public hasPermission(permission: PermissionName): boolean {
    return User.currentUser
      ?.orgs.find(o => o.id === User.orgId)
      ?.currentRole?.permissions
      ?.some(p => p.name === permission) ?? false;
  }

}

export default new PermissionService();