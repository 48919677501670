export interface DemoTimeseries {
  daily?: DemoTimeseriesReading[];
  refill?: DemoTimeseriesReading[];
  withdrawal?: DemoTimeseriesReading[];
  inletTemperature?: DemoTimeseriesReading[];
  milkingFinish?: DemoTimeseriesReading[];
  pickupFinish?: DemoTimeseriesReading[];
  plantWashFinish?: DemoTimeseriesReading[];
  stirrerState?: DemoTimeseriesReading[];
  vatTemperature?: DemoTimeseriesReading[];
  vatWashFinish?: DemoTimeseriesReading[];
  volume?: DemoTimeseriesReading[];
}

export interface DemoTimeseriesReading {
  startTime: number;
  startVolume?: number;
  timestamp: number;
  type?: string;
  value: number;
}

export const DemoTimeseriesBlank = {
  daily: [],
  refill: [],
  withdrawal: [],
  inletTemperature: [],
  milkingFinish: [],
  pickupFinish: [],
  plantWashFinish: [],
  stirrerState: [],
  vatTemperature: [],
  vatWashFinish: [],
  volume: []
};
