import router from '@/router';
import CustomStore from '@/store/modules/CustomStore';
import { FeedMetrics, FuelMetricsTank, Product } from '@/store/models/Product';
import { Constants, getUnitChar } from '@/utils/Constants';
import { getCountry } from '@/utils/LocationUtils';
import { getFormattedFeedAmount, isTonnes, isWeight } from './FeedUtils';
import { toTitleCase } from './FormattingUtils';
import { getAppEntityOrgCustomSetting } from './GenericUtils';
import { AmountMetric } from './types/AmountMetric';
import { Level } from './types/Level';
import { productType } from './types/ProductType';
import { returnOnEntityAccess } from './WaterUtils';

function getAppWord() {
  const route = router.currentRoute.path;
  const findTerm = (term: string) => {
    if (route.includes(term)) {
      return route;
    }
  };
  switch (route) {
    case findTerm(Constants.PRODUCT_TYPE_FUEL):
      return Constants.PRODUCT_TYPE_FUEL;
    case findTerm('product'):
      return Constants.PRODUCT_TYPE_MILK;
    case findTerm(Constants.PRODUCT_TYPE_MILK):
      return Constants.PRODUCT_TYPE_MILK;
    case findTerm(Constants.PRODUCT_TYPE_FEED):
      return Constants.PRODUCT_TYPE_FEED;
    case findTerm(Constants.PRODUCT_TYPE_WATER):
      return Constants.PRODUCT_TYPE_WATER;
    case findTerm(Constants.PRODUCT_TYPE_GENERIC):
      return Constants.PRODUCT_TYPE_GENERIC;
    case '/profile':
      return 'profile';
    default:
      return '';
  }
}

export function getAppName(isTitleCase: boolean) {
  return isTitleCase ? toTitleCase(getAppWord()) : getAppWord().toLowerCase();
}

export function getAppConstant(): productType {
  const appWord = getAppWord() as productType;
  return appWord;
}

export function getAppColour(isFormatBootstrap: boolean) {
  const app = getAppName(false);

  if (isFormatBootstrap) {
    switch (app) {
      case Constants.PRODUCT_TYPE_FUEL:
        return 'fuelColour';
      case Constants.PRODUCT_TYPE_MILK:
        return 'secondary';
      case Constants.PRODUCT_TYPE_FEED:
        return 'feedColour';
      case Constants.PRODUCT_TYPE_WATER:
        return 'waterColour';
      case Constants.PRODUCT_TYPE_GENERIC:
        return 'primary';
      case 'profile':
        return 'primary';
      default:
        return 'primary';
    }
  } else {
    switch (app) {
      case Constants.PRODUCT_TYPE_FUEL:
        return '#33B1A6';
      case Constants.PRODUCT_TYPE_MILK:
        return '#F6861F';
      case Constants.PRODUCT_TYPE_FEED:
        return '#3395B1';
      case Constants.PRODUCT_TYPE_WATER:
        return '#28479d';
      case Constants.PRODUCT_TYPE_GENERIC:
        return '#26283f ';
      case 'profile':
        return '#26283f ';
      default:
        return '#26283f';
    }
  }
}

export function getAppBackgroundColour() {
  const app = getAppName(false);

  switch (app) {
    case Constants.PRODUCT_TYPE_FUEL:
      return '#C1E7E4';
    case Constants.PRODUCT_TYPE_MILK:
      return '#FCDABB';
    case Constants.PRODUCT_TYPE_FEED:
      return '#C1DFE7';
    case Constants.PRODUCT_TYPE_WATER:
      return '#BEC7E1';
    case Constants.PRODUCT_TYPE_GENERIC:
      return '#ffffff';
    case 'profile':
      return '#ffffff';
    default:
      return '#ffffff';
  }
}

export function getAppColourRGB() {
  const app = getAppName(false);

  switch (app) {
    case Constants.PRODUCT_TYPE_FUEL:
      return 'rgb(51,177,166)';
    case Constants.PRODUCT_TYPE_MILK:
      return 'rgb(230, 115, 9)';
    case Constants.PRODUCT_TYPE_FEED:
      return 'rgb(51,149,177)';
    case Constants.PRODUCT_TYPE_WATER:
      return 'rgb(40, 71, 157)';
    case Constants.PRODUCT_TYPE_GENERIC:
      return 'rgb(38, 40, 62)';
    case 'profile':
      return 'rgb(38, 40, 62)';
    default:
      return 'rgb(38, 40, 62)';
  }
}

export function getEntityApiWord() {
  const app = getAppName(false);
  switch (app) {
    case Constants.PRODUCT_TYPE_FUEL:
      return Constants.API_ENTITY_TYPE_FUEL;
    case Constants.PRODUCT_TYPE_MILK:
      return Constants.API_ENTITY_TYPE_MILK;
    case Constants.PRODUCT_TYPE_FEED:
      return Constants.API_ENTITY_TYPE_FEED;
    case Constants.PRODUCT_TYPE_WATER:
      return returnOnEntityAccess(
        Constants.API_ENTITY_TYPE_WATER_TANK,
        Constants.API_ENTITY_TYPE_WATER_METER
      );
    default:
      return Constants.API_ENTITY_TYPE_FUEL;
  }
}

export function isApp(app: string) {
  return getAppName(false) == app;
}

export function getPreferredMetricWord(
  levelMetric?: AmountMetric
): AmountMetric {
  if (isApp(Constants.PRODUCT_TYPE_FEED) && levelMetric) {
    return levelMetric;
  } else {
    if (getAppEntityOrgCustomSetting() == undefined) {
      return Constants.METRIC_VOLUME;
    } else {
      return (
        getAppEntityOrgCustomSetting()?.preferredLevelMetric ??
        Constants.METRIC_VOLUME
      );
    }
  }
}

export function getUnitWord(entity?: Product, forceTonnes?: boolean) {
  return getPreferredMetricWord(
    (entity?.metrics as FeedMetrics)?.feedTypeLevelMetric
  ) == Constants.METRIC_VOLUME
    ? Constants.UNIT_LITRES
    : forceTonnes
    ? Constants.UNIT_TONNES
    : Constants.UNIT_KILOGRAM;
}

export function getUnit(entity?: Product) {
  return getUnitChar(getUnitWord(entity));
}

export function getUnitWithTonnes(
  value: number,
  entity?: Product,
  forceTonnes?: boolean
) {
  return isWeight(entity) &&
    ((isTonnes(value, entity) && forceTonnes !== false) || forceTonnes)
    ? getUnitChar(Constants.UNIT_TONNES)
    : getUnit(entity);
}

function getEntityWord(entity?: Product) {
  let defaultName = undefined;
  const defaultBackup = toTitleCase(Constants.ENTITY_LABEL_TANK);
  if (getAppEntityOrgCustomSetting() != undefined) {
    defaultName = toTitleCase(
      getAppEntityOrgCustomSetting()?.preferredLabel ?? defaultBackup
    );
  }
  const app = getAppName(false);

  switch (app) {
    case Constants.PRODUCT_TYPE_FUEL:
      return defaultName ?? defaultBackup;
    case Constants.PRODUCT_TYPE_MILK:
      return (
        defaultName ??
        toTitleCase(
          getCountry() == Constants.IRELAND
            ? Constants.ENTITY_LABEL_TANK
            : Constants.ENTITY_LABEL_VAT
        )
      );
    case Constants.PRODUCT_TYPE_FEED:
      return entity && (entity.metrics as FeedMetrics).storage
        ? toTitleCase((entity.metrics as FeedMetrics).storage)
        : defaultName ?? defaultBackup;
    case Constants.PRODUCT_TYPE_WATER: {
      return returnOnEntityAccess('Tank', 'Supply');
    }
    default:
      return defaultName ?? defaultBackup;
  }
}

export function getEntityName(isTitleCase: boolean, entity?: Product) {
  return isTitleCase
    ? getEntityWord(entity)
    : getEntityWord(entity).toLowerCase();
}

export function getPluralEntityName(isTitleCase: boolean, entity?: Product) {
  const name = getEntityName(isTitleCase, entity);
  if (name.endsWith('y')) {
    //e.g. supply should be supplies not supplys so replace the y with ies
    return name.slice(0, -1) + 'ies';
  } else {
    return name + 's';
  }
}

export function getEntityApiCamelCaseWord() {
  if (isApp(Constants.PRODUCT_TYPE_FEED)) {
    return 'feed_tanks';
  } else {
    return getEntityApiWord();
  }
}

export function getEntityTitle(entity: any) {
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      return `${entity.name || ''}`;
    case Constants.PRODUCT_TYPE_FUEL:
      return `${entity.metrics.fuelType || ''} ${
        entity.capacity
          ? `(${entity.capacity.toLocaleString()}${getUnit(entity)})`
          : ''
      }`;
    case Constants.PRODUCT_TYPE_FEED:
      return `${entity.metrics.feedType || ''} ${
        entity.capacity
          ? `(${getFormattedFeedAmount(entity.capacity, entity)})`
          : ''
      }`;
    case Constants.PRODUCT_TYPE_WATER:
      return `${entity.name || ''}`;
    default:
      return `${entity.name || ''}`;
  }
}

export function getEntityHeaderInfo(
  entity: any
): {
  title: string;
  subtitle: string;
  secondSubtitle: string;
} {
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      return {
        title: entity?.name ?? getEntityName(true),
        subtitle: entity?.capacity
          ? `${entity?.capacity}${getUnit(entity)}`
          : '',
        secondSubtitle: ''
      };
    case Constants.PRODUCT_TYPE_FUEL:
      return {
        title:
          entity?.metrics &&
          (entity?.metrics as FuelMetricsTank)?.fuelType &&
          (entity?.metrics as FuelMetricsTank)?.nominalVolume
            ? `${(entity?.metrics as FuelMetricsTank)?.fuelType ?? ''} ${(
                (entity?.metrics as FuelMetricsTank)?.nominalVolume ?? 0
              ).toLocaleString()}${getUnit(entity)}`
            : getEntityName(true),
        subtitle:
          entity?.metrics &&
          ((entity?.metrics as FuelMetricsTank)?.safeFillVolume ||
            ((entity?.metrics as FuelMetricsTank)?.volume &&
              (entity?.metrics as FuelMetricsTank)?.fillVolume))
            ? `Safe Fill: ${((entity?.metrics as FuelMetricsTank)
                ?.safeFillVolume
                ? (entity?.metrics as FuelMetricsTank)?.safeFillVolume
                : (entity?.metrics as FuelMetricsTank)?.volume +
                  (entity?.metrics as FuelMetricsTank)?.fillVolume
              )?.toLocaleString()}${getUnit(entity)}`
            : '',
        secondSubtitle: entity?.name ?? ''
      };
    case Constants.PRODUCT_TYPE_FEED:
      return {
        title:
          entity?.metrics && (entity?.metrics as FeedMetrics)?.feedType
            ? `${(entity?.metrics as FeedMetrics)?.feedType}`
            : entity?.name ?? getEntityName(true),
        subtitle: `Capacity: ${
          (entity?.metrics as FuelMetricsTank)?.nominalVolume
            ? `${getFormattedFeedAmount(
                (entity?.metrics as FuelMetricsTank)?.nominalVolume,
                entity
              )}`
            : ''
        }`,
        secondSubtitle:
          entity?.metrics && (entity?.metrics as FeedMetrics)?.feedType
            ? entity?.name ?? ''
            : ''
      };
    case Constants.PRODUCT_TYPE_WATER:
      return {
        title: entity?.name ?? getEntityName(true),
        subtitle: '',
        secondSubtitle: ''
      };
    default:
      return {
        title: entity?.name ?? getEntityName(true),
        subtitle: `${entity?.capacity}${getUnit(entity)}`,
        secondSubtitle: ''
      };
  }
}

export function getGraphColours(disabled: boolean) {
  const app = getAppName(false);

  if (disabled) {
    return ['#F1F2F2', '#D1D2D4'];
  } else {
    switch (app) {
      case Constants.PRODUCT_TYPE_FUEL:
        return ['#B4E2DE', '#33B1A6'];
      case Constants.PRODUCT_TYPE_MILK:
        return ['#F6861F', '#FCDDC0'];
      case Constants.PRODUCT_TYPE_FEED:
        return ['#99CAD8', '#3395B1'];
      case Constants.PRODUCT_TYPE_WATER:
        return ['#7e91c4', '#28479d'];
      default:
        return ['#F6861F', '#FCDDC0'];
    }
  }
}

export function getLevelText(level: Level) {
  return level == 'products' ? 'Site' : getEntityName(true);
}
