import { Product } from '@/store/models/Product';
import { isApp } from './AppName';
import { isPage } from './PageUtils';
import { Constants } from './Constants';

export function isEntitySupplying(entity: Product) {
  if (!isApp(Constants.PRODUCT_TYPE_MILK) || isPage('sites')) return true;
  return entity.state === 'supply';
}

export function isSupplying(product: Product) {
  if (product.entities) {
    return product.entities.some((entity: Product) =>
      isEntitySupplying(entity)
    );
  } else {
    return isEntitySupplying(product);
  }
}
