import CustomFieldResponse from './models/responses/CustomFieldResponse';
import HttpClient from '@/services/api/ApiBase';
import CustomField, {
  CustomFieldDefinitions
} from '@/store/models/CustomField';
import CustomFieldData from '@/store/models/CustomFieldData';
import CustomFieldRequest from './models/requests/CustomFieldRequest';

export const GetCustomFields = async (request: {
  partnerId: number;
  entityType: string;
}): Promise<CustomField[]> => {
  const response = await HttpClient.get<CustomFieldResponse>(
    `/partner/${request.partnerId}/customFieldDefinitions`
  );

  return (
    response.data.data.find(item => item.entityType == request.entityType)
      ?.customFields ?? []
  );
};

export const GetCustomFieldDefinitions = async (
  orgId: number
): Promise<CustomFieldDefinitions> => {
  try {
    const response = await HttpClient.get<CustomFieldResponse>(
      `/partner/${orgId}/customFieldDefinitions`
    );
    const customFieldDefinitions: CustomFieldDefinitions = Object.fromEntries(
      response.data.data.map(data => [data.entityType, data.customFields])
    );
    return customFieldDefinitions;
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const AddCustomField = async (request: {
  partnerId: number;
  data: {
    name: string;
    type: string;
    sortable: boolean;
  };
}): Promise<CustomField[]> => {
  const data = await HttpClient.post<CustomFieldResponse>(
    `/partner/${request.partnerId}/addCustomFieldDefinition`,
    { data: request.data }
  );
  const singleLayer: CustomField[] = [];
  data.data.data.forEach(item => {
    singleLayer.push(...item.customFields);
  });
  return singleLayer;
};

export const UpdateCustomField = async (request: {
  partnerId: number;
  data: {
    name: string;
    type: string;
    sortable: boolean;
  };
}): Promise<CustomField[]> => {
  const data = await HttpClient.post<CustomFieldResponse>(
    `/partner/${request.partnerId}/updateCustomFieldDefinition`,
    { data: request.data }
  );
  const singleLayer: CustomField[] = [];
  data.data.data.forEach(item => {
    singleLayer.push(...item.customFields);
  });
  return singleLayer;
};

export const DeleteCustomField = async (request: {
  partnerId: number;
  data: {
    name: string;
    type: string;
  };
}): Promise<CustomField[]> => {
  const data = await HttpClient.post<CustomFieldResponse>(
    `/partner/${request.partnerId}/deleteCustomFieldDefinition`,
    { data: request.data }
  );
  const singleLayer: CustomField[] = [];
  data.data.data.forEach(item => {
    singleLayer.push(...item.customFields);
  });
  return singleLayer;
};

export const AddCustomFieldDefinition = async (request: {
  partnerId: number;
  entityType: string;
  data: {
    name: string;
    type: string;
    sortable: boolean;
  };
}): Promise<CustomField[]> => {
  const data = await HttpClient.post<CustomFieldResponse>(
    `/partner/${request.partnerId}/addCustomFieldDefinition/${request.entityType}`,
    { data: request.data }
  );
  return (
    data.data.data.find(item => item.entityType == request.entityType)
      ?.customFields ?? []
  );
};

export const UpdateCustomFieldDefinition = async (request: {
  partnerId: number;
  entityType: string;
  data: CustomField;
}): Promise<CustomField[]> => {
  const data = await HttpClient.post<CustomFieldResponse>(
    `/partner/${request.partnerId}/updateCustomFieldDefinition/${request.entityType}`,
    { data: request.data }
  );
  return (
    data.data.data.find(item => item.entityType == request.entityType)
      ?.customFields ?? []
  );
};

export const DeleteCustomFieldDefinition = async (request: {
  partnerId: number;
  entityType: string;
  data: {
    name: string;
    type: string;
  };
}): Promise<CustomField[]> => {
  const data = await HttpClient.post<CustomFieldResponse>(
    `/partner/${request.partnerId}/deleteCustomFieldDefinition/${request.entityType}`,
    { data: request.data }
  );
  return (
    data.data.data.find(item => item.entityType == request.entityType)
      ?.customFields ?? []
  );
};

export const EditCustomFieldDefinition = async (
  request: CustomFieldRequest
): Promise<CustomFieldDefinitions> => {
  try {
    const response = await HttpClient.post<CustomFieldResponse>(
      `/partner/${request.partnerId}/${request.editType}CustomFieldDefinition/${request.entityType}`,
      { data: request.data }
    );
    const customFieldDefinitions: CustomFieldDefinitions = Object.fromEntries(
      response.data.data.map(data => [data.entityType, data.customFields])
    );
    return customFieldDefinitions;
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const UpdateCustomFieldData = async (request: {
  partnerId: number;
  productId: number;
  data: {
    name: string;
    value: string;
  }[];
}): Promise<any> => {
  const data = await HttpClient.post<CustomFieldResponse>(
    `/partner/${request.partnerId}/customFieldData/${request.productId}`,
    { data: request.data }
  );
  return data.data.data;
};

export const UpdateEntityCustomFieldData = async (request: {
  partnerId: number;
  entityId: number;
  entityType: string;
  data: CustomFieldData[];
}): Promise<any> => {
  const data = await HttpClient.post<CustomFieldResponse>(
    `/partner/${request.partnerId}/customFieldData/${request.entityType}/${request.entityId}`,
    { data: request.data }
  );
  return data.data.data;
};

export const UpdateCustomLabelNameData = async (request: {
  partnerId: number;
  entityId: number;
  entityType: string;
  data: string | null;
}): Promise<any> => {
  const data = await HttpClient.post<CustomFieldResponse>(
    `/partner/${request.partnerId}/name/${request.entityType}/${request.entityId}`,
    { data: request.data }
  );
  return data.data.data;
};

export const UpdateCustomLabelIdData = async (request: {
  partnerId: number;
  entityId: number;
  entityType: string;
  data: string;
}): Promise<any> => {
  const data = await HttpClient.post<CustomFieldResponse>(
    `/partner/${request.partnerId}/id/${request.entityType}/${request.entityId}`,
    { data: request.data }
  );
  return data.data.data;
};
