import ProductAPI from '@/services/api/models/ProductAPI';
import Comment from '@/store/models/Comment';
import {
  Product,
  WaterMeterMetricsDefault,
  WaterTankMetricsDefault
} from '@/store/models/Product';
import { formatComment, formatWaterAlert } from '../TableFormatters';
import {
  formatEntitiesAPI,
  formatGroupedEntity,
  getCombinedEntityCustomFields,
  getMaxMeterValue,
  getMaxTankValue
} from './EntitiesFormatter';
import { isWaterMeter, returnOnEntityAccess } from '../WaterUtils';

/**
 * Formats water entity list from API: JSON parsing, formatting fields and handling UAT data
 */
export function formatWaterEntitiesAPI(entities: ProductAPI[]) {
  const formattedEntities = entities.map((entity: ProductAPI) => {
    let metrics;

    if (!entity.metrics) {
      metrics = isWaterMeter()
        ? WaterMeterMetricsDefault
        : WaterTankMetricsDefault;
    } else {
      metrics =
        typeof entity.metrics === 'string'
          ? JSON.parse(entity.metrics)
          : entity.metrics;
    }

    return {
      ...formatEntitiesAPI(entity),
      entityComments: formatComment(entity, null),
      metrics: entity
        ? isWaterMeter()
          ? {
              currentUsage: metrics.currentUsage,
              usageToday: metrics.volumeToday,
              usageYesterday: metrics.volumeYesterday,
              alert: formatWaterAlert(metrics.lastReading?.flow)
            }
          : {
              volume: metrics.volume,
              percent: metrics.percent,
              volumeTimestamp: metrics.volumeTs,
              percentTimestamp: metrics.percentTs,
              alert: formatWaterAlert(metrics.lastReading?.flow)
            }
        : isWaterMeter()
        ? WaterMeterMetricsDefault
        : WaterTankMetricsDefault
    } as Product;
  });
  return formattedEntities;
}

/**
 * Groups list of water entities by product id to create a list of products with entities
 * Returns map
 *
 */
export function getGroupedWaterTankProducts(entities: Product[]) {
  const map = new Map();
  entities.forEach((item: Product) => {
    const collection = map.get(item.productId);
    if (!collection) {
      map.set(item.productId, {
        ...item,

        maxPercent: getMaxTankValue(item, 0, null, 'percent'),
        entities: [
          {
            ...formatGroupedEntity(item)
          }
        ]
      });
    } else {
      const index = collection.entities.length;
      collection.entities.push({
        ...formatGroupedEntity(item)
      });
      collection.maxPercent = getMaxTankValue(
        item,
        index,
        collection.maxPercent,
        'percent'
      );
      collection.entityCustomFieldData = getCombinedEntityCustomFields(
        collection.entityCustomFieldData,
        item.entityCustomFieldData
      );
    }
  });
  return map;
}

export function getGroupedWaterMeterProducts(entities: Product[]) {
  const map = new Map();
  entities.forEach((item: Product) => {
    const collection = map.get(item.productId);
    if (!collection) {
      map.set(item.productId, {
        ...item,

        maxCurrentUsage: getMaxMeterValue(item, 0, null, 'currentUsage'),
        entities: [
          {
            ...formatGroupedEntity(item)
          }
        ]
      });
    } else {
      const index = collection.entities.length;
      collection.entities.push({
        ...formatGroupedEntity(item)
      });
      collection.maxCurrentUsage = getMaxMeterValue(
        item,
        index,
        collection.maxCurrentUsage,
        'currentUsage'
      );
      collection.entityCustomFieldData = getCombinedEntityCustomFields(
        collection.entityCustomFieldData,
        item.entityCustomFieldData
      );
    }
  });
  return map;
}

export function formatWaterProducts(entities: Product[]): Product[] {
  return returnOnEntityAccess(
    Array.from(getGroupedWaterTankProducts(entities)).map(
      product => product[1]
    ),
    Array.from(getGroupedWaterMeterProducts(entities)).map(
      product => product[1]
    )
  );
}
