import { VueGtag } from 'vue-gtag';
import { GAEvent } from '@/services/ga/events/GAEvent';

function isUat(): boolean {
  return !(process.env.VUE_APP_GOOGLE_ANALYTICS_TOKEN?.length > 0);
}

export class GA {
  public static event<T extends GAEvent>(gtag: VueGtag, event: T): void {
    if (!isUat()) {
      const { eventName } = event;
      if (!eventName) return;
      delete event.eventName;
      const params: any = {};
      Object.entries(event).forEach(entry => {
        params[`${entry[0]}`] = JSON.stringify(entry[1]);
      });
      gtag.event(eventName, params);
    }
  }
}
