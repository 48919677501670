interface ModuleNames {
  user: string;
  product: string;
  custom: string;
}

const modulesNames: Readonly<ModuleNames> = {
  user: 'user',
  product: 'product',
  custom: 'custom'
};

export default modulesNames;
