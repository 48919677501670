/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FuelMetricsTank, Product } from '@/store/models/Product';
import { TableColumnFormatters } from '@/store/models/TableColumn';
import {
  formatFillVolumeDisplay,
  formatDailyUseDisplay,
  formatDaysTillEmptyDisplay,
  isLive
} from '../formatters/DisplayValueFormatters';
import { AllowedField, FuelAllowedField } from '../types/AllowedFields';
import {
  GetVisibleCustomColumnsFormatters,
  tableColumnFormatters
} from './TableFields';
import Vue from 'vue';
import { GetDefaultTableColumns } from '../TableColumns';
import { relativeDay } from '../DateUtils';
import { SetVisibilityOfTableColumns } from '../TableColumnVisibility';

export const fuelSpecificTableColumnsFormatters: {
  [key in FuelAllowedField]: TableColumnFormatters;
} = {
  [FuelAllowedField.CUSTOM_ID]: {
    name: FuelAllowedField.CUSTOM_ID,
    label: () => 'Id',
    visible: true,
    width: 100,
    filterable: false,
    searchable: true,
    sortable: true,
    searchKey: (item: Product) => {
      return item.partnerDefinedId;
    },
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by partner defined id and entities by entity id
      return sortType == 'child' ? item.entityId : item.partnerDefinedId;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item.partnerDefinedId ? item.partnerDefinedId : '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return ''; //blank
    },
    textStyling: 'textOverflowEllipsis'
  },
  [FuelAllowedField.TYPE]: {
    name: FuelAllowedField.TYPE,
    label: () => 'Type',
    visible: true,
    width: 100,
    filterable: true,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return entity.metrics.fuelType ?? '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return entity.metrics.fuelType ?? '';
    }
  },
  [FuelAllowedField.VOLUME]: {
    name: FuelAllowedField.VOLUME,
    label: () => 'Volume (L)',
    visible: true,
    width: 200,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min volume of entities
      //Sort entities by volume value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as FuelMetricsTank).volume;
      } else {
        return (item.entities[0].metrics as FuelMetricsTank).volume;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return ''; //SPECIAL - volume with progress bar
    },
    childFormattedValue: (item: Product, entity: any) => {
      return ''; //SPECIAL - volume with progress bar
    },
    parentComponent: () => 'VolumeCapacityField',
    childComponent: () => 'VolumeCapacityField',
    textStyling: 'text-center'
  },
  [FuelAllowedField.FILL_VOLUME]: {
    name: FuelAllowedField.FILL_VOLUME,
    label: () => 'Ullage',
    visible: true,
    width: 140,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min ullage of entities
      //Sort entities by ullage value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as FuelMetricsTank).fillVolume;
      } else {
        return (item.entities[0].metrics as FuelMetricsTank).fillVolume;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return isLive(entity) ? formatFillVolumeDisplay(entity) : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return isLive(entity) ? formatFillVolumeDisplay(entity) : '-';
    }
  },
  [FuelAllowedField.USAGE]: {
    name: FuelAllowedField.USAGE,
    label: () => 'Usage',
    visible: true,
    width: 100,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return isLive(entity) ? formatDailyUseDisplay(entity) : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return isLive(entity) ? formatDailyUseDisplay(entity) : '-';
    }
  },
  [FuelAllowedField.REMAINING]: {
    name: FuelAllowedField.REMAINING,
    label: () => 'Remaining',
    visible: true,
    width: 140,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min days remaining of entities
      //Sort entities by daus remaining value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as FuelMetricsTank).daysTillEmpty;
      } else {
        return (item.entities[0].metrics as FuelMetricsTank).daysTillEmpty;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return isLive(entity)
        ? formatDaysTillEmptyDisplay(entity).toString()
        : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return isLive(entity)
        ? formatDaysTillEmptyDisplay(entity).toString()
        : '-';
    }
  },
  [FuelAllowedField.LARGEST_TANK]: {
    name: FuelAllowedField.LARGEST_TANK,
    label: () => 'Largest Tank',
    visible: true,
    width: 140,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      return item && item.largestCapacity ? item.largestCapacity : undefined;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item && item.largestCapacity
        ? `${item.largestCapacity.toLocaleString()}L`
        : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    }
  },
  [FuelAllowedField.ALERT_TIME]: {
    name: FuelAllowedField.ALERT_TIME,
    label: () => 'Time',
    visible: true,
    width: 140,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      return item && (item.metrics as FuelMetricsTank)?.alertTs
        ? (item.metrics as FuelMetricsTank).alertTs
        : undefined;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item && (item.metrics as FuelMetricsTank)?.alertTs
        ? relativeDay((item.metrics as FuelMetricsTank).alertTs)
        : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return item && (item.metrics as FuelMetricsTank).alertTs
        ? relativeDay((item.metrics as FuelMetricsTank).alertTs)
        : '-';
    }
  }
};

export const fuelTableColumnsFormatters = {
  ...tableColumnFormatters,
  ...fuelSpecificTableColumnsFormatters
};

export function GetFuelTableColumnFormatters(
  fields: (FuelAllowedField | AllowedField)[]
) {
  const baseColumnsWithFormatters = fields.map(column => {
    return fuelTableColumnsFormatters[column];
  });
  return baseColumnsWithFormatters;
}

export async function GetVisibleFuelTableColumnFormatters(
  fields: (FuelAllowedField | AllowedField)[],
  addCustomFields: boolean
) {
  const defaultColumnsWithVisibility = GetDefaultTableColumns();
  const baseColumnsWithFormatters = SetVisibilityOfTableColumns(
    fields.map(column => {
      return fuelTableColumnsFormatters[column];
    })
  ).filter(column => {
    if (column.name == AllowedField.VOLUME_PERCENT_BAR) {
      return defaultColumnsWithVisibility[AllowedField.PERCENT_FULL].visible;
    }
    return column.visible;
  });

  if (addCustomFields) {
    const customColumnsWithFormatters = await GetVisibleCustomColumnsFormatters();
    return [...baseColumnsWithFormatters, ...customColumnsWithFormatters];
  } else {
    return baseColumnsWithFormatters;
  }
}

export function GetDefaultFuelColumns() {
  return Object.fromEntries(
    Object.entries(fuelTableColumnsFormatters).filter(column => {
      return column[0].includes('Default');
    })
  );
}
