import { PermissionName } from "@/store/models/PermissionName";
import permissionService from '@/services/PermissionService';
import { NavigationGuardNext, Route } from "vue-router";

export const PermissionGuard = (
  to: Route, 
  from: Route, 
  next: NavigationGuardNext<Vue>
) => {
  const requiresOneOf: PermissionName[] = to.meta?.requiresOnePermissionOf ?? [];
  if (requiresOneOf.length > 0) {
    const hasAnyRequiredPermission = requiresOneOf
      .some((pn: PermissionName) => permissionService.hasPermission(pn));
    
    hasAnyRequiredPermission 
      ? next()
      : next('/');
    return;
  }

  const permissionName = to.meta?.requiredPermission;
  if (!permissionName || !permissionService.hasPermission(permissionName)) {
    next('/');
  }
  next();
}