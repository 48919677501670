
import { Org } from '@/services/api/models/responses/LoginResponse';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ChangeOrgModal extends Vue {
  @Prop() orgs!: Org[];
  @Prop() selectedOrg!: string;

  public selected(index: number) {
    this.$emit('selected', index);
    this.closeModal();
  }

  public closeModal() {
    this.$root.$emit('bv::hide::modal', 'changeOrgModal', '#btnHide');
  }
}
