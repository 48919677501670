import MilkScore, { ComplianceSettings } from '@/store/models/MilkScore';
import CustomStore from '@/store/modules/CustomStore';
import { Constants } from './Constants';
import { convertSecsToHrs } from './FormattingUtils';

export const defaultComplianceMethod = () => {
  const defaultMethod = CustomStore.complianceMethods.find(
    orgMethod => orgMethod.default
  );
  if (defaultMethod) {
    return defaultMethod.complianceMethod;
  } else if (CustomStore.complianceMethods.length > 0) {
    return CustomStore.complianceMethods[0].complianceMethod;
  } else {
    return Constants.COMPLIANCE_METHOD_MPI;
  }
};

export function getMethod(customSettings: ComplianceSettings | null): string {
  if (
    customSettings?.complianceMethod &&
    customSettings.complianceMethod != Constants.COMPLIANCE_METHOD_NOTSET
  ) {
    return customSettings?.complianceMethod;
  } else {
    return defaultComplianceMethod();
  }
}

export function getScoreThreshold(method: string) {
  return CustomStore.complianceMethods.find(
    orgMethod => orgMethod.complianceMethod == method
  )?.complianceMethodThreshold;
}

export function getAgeThresholdHrs(method: string) {
  return convertSecsToHrs(
    CustomStore.complianceMethods.find(
      orgMethod => orgMethod.complianceMethod == method
    )?.maxMilkAgeSecs
  );
}

export function getScore(milkScores: MilkScore[] | null, method: string) {
  return (milkScores ?? []).find(score => score.complianceMethod == method);
}

export function getScoreTs(
  milkScores: MilkScore[] | null,
  customSettings: ComplianceSettings | null
) {
  const score = getScore(milkScores ?? [], getMethod(customSettings)) ?? null;
  if (score && score.rating) {
    return score.ratingHistory[score.rating];
  } else {
    return null;
  }
}

export function getComplianceScore(
  milkScores: MilkScore[] | null,
  customSettings: ComplianceSettings | null
) {
  if (milkScores) {
    const method = getMethod(customSettings);
    return getScore(milkScores, method) ?? null;
  }
  return null;
}

export function isScoreCrossThreshold(
  milkScores: MilkScore[] | null,
  customSettings: ComplianceSettings | null
) {
  if (milkScores) {
    const method = getMethod(customSettings);
    const threshold = getScoreThreshold(method);
    if (threshold) {
      const score = getScore(milkScores, method);
      if (score && score.rating) {
        return score.rating >= threshold;
      }
    }
  }
  return null;
}

export function isFonterraScoreCrossThreshold(
  milkScore: number | null,
  customSettings: ComplianceSettings | null
): boolean {
  if (milkScore != null) {
    const method = getMethod(customSettings);
    const threshold = getScoreThreshold(method);
    if (threshold) {
      return milkScore >= threshold;
    }
  }
  return false;
}

export function isAgeCrossThreshold(
  milkAge: number | null,
  customSettings: ComplianceSettings | null
) {
  if (milkAge) {
    const method = getMethod(customSettings);
    const threshold = getAgeThresholdHrs(method);
    if (threshold) {
      return milkAge >= threshold;
    }
  }
  return null;
}

export function complianceMethodLabel(methodName: string) {
  return (
    CustomStore.groupedComplianceMethods?.get(methodName)?.label ??
    methodName.toUpperCase()
  );
}

export function complianceMethodDescription(methodName: string) {
  return (
    CustomStore.groupedComplianceMethods?.get(methodName)?.description ??
    undefined
  );
}

export function complianceMethodRanges(methodName: string) {
  const thresholdRanges: number[] = [0];
  CustomStore.groupedComplianceMethods
    ?.get(methodName)
    ?.settings?.ranges?.forEach(range => {
      if (range.upperBound) {
        thresholdRanges.push(+range.upperBound);
      }
    });
  if (methodName == Constants.COMPLIANCE_METHOD_SAMR) {
    return [0, 0, 0, 1, 2];
  }
  return thresholdRanges;
}
