import ProductAPI from '@/services/api/models/ProductAPI';
import Comment from '@/store/models/Comment';
import {
  FeedMetrics,
  FeedMetricsDefault,
  Product
} from '@/store/models/Product';
import dayjs from 'dayjs';
import {
  formatComment,
  formatFuelAlert,
  formatTimeOfAlert
} from '../TableFormatters';
import {
  formatEntitiesAPI,
  formatGroupedEntity,
  getCombinedEntityCustomFields,
  getMinValue
} from './EntitiesFormatter';
import { getPreferredMetricWord } from '../AppName';
import { Constants } from '../Constants';
import { toTitleCase } from '../FormattingUtils';

/**
 * Formats feed entity list from API: JSON parsing, formatting fields and handling UAT data
 */
export function formatFeedEntitiesAPI(entities: ProductAPI[]) {
  const formattedEntities = entities.map((entity: ProductAPI) => {
    let metrics;

    if (!entity.metrics) {
      metrics = FeedMetricsDefault;
    } else {
      metrics =
        typeof entity.metrics === 'string'
          ? JSON.parse(entity.metrics)
          : entity.metrics;
    }

    const formattedBaseEntity = formatEntitiesAPI(entity);
    const amount =
      metrics[getPreferredMetricWord(entity.feedTypeLevelMetric)] ??
      metrics.volume ??
      null;
    const nominalAmount =
      entity[
        getPreferredMetricWord(entity.feedTypeLevelMetric) ==
        Constants.METRIC_VOLUME
          ? 'capacity'
          : 'weight'
      ] ??
      entity.capacity ??
      null;
    const percentFull =
      metrics[
        `percent${toTitleCase(
          getPreferredMetricWord(entity.feedTypeLevelMetric)
        )}`
      ] ??
      metrics.percentFull ??
      (nominalAmount != null && amount != null
        ? (+amount / +nominalAmount) * 100
        : null);
    return {
      ...formattedBaseEntity,
      entityComments: formatComment(entity, metrics.lastDeliveryTimestamp),
      capacity: nominalAmount ? +nominalAmount : null,
      metrics: entity
        ? {
            ...(metrics as FeedMetrics),

            feedType: entity.feedType,
            feedTypeLevelMetric: entity.feedTypeLevelMetric,
            storage: entity.storageType,
            daysTillEmpty: metrics.shortEstimatedEmptyDate
              ? dayjs
                  .unix(+metrics.shortEstimatedEmptyDate)
                  .diff(dayjs(), 'day')
              : null,
            estimatedEmptyTimestamp: metrics.shortEstimatedEmptyDate
              ? +metrics.shortEstimatedEmptyDate
              : null,
            dailyUse: metrics.shortDailyUse || null,
            dailyUseTs: metrics.shortDailyUseTs || null,
            volume: +amount,
            nominalVolume: nominalAmount ? +nominalAmount : null,
            fillVolume: nominalAmount ? +nominalAmount - +amount : null,
            percentFull: percentFull,
            alert: formatFuelAlert(
              formattedBaseEntity.entityCustomSettingsData,
              metrics.percentFull
            ),
            alertTs: formatTimeOfAlert(
              formattedBaseEntity.entityCustomSettingsData,
              metrics.percentFull,
              metrics.levelHistory
            )
          }
        : FeedMetricsDefault
    } as Product;
  });
  return formattedEntities;
}

/**
 * Groups list of feed entities by product id to create a list of products with entites
 * Returns as a map
 */
export function getGroupedFeedProducts(entities: Product[]) {
  const map = new Map();
  entities.forEach((item: Product) => {
    const collection = map.get(item.productId);
    if (!collection) {
      map.set(item.productId, {
        ...item,
        minDays: getMinValue(item, 0, null, 'daysTillEmpty'),
        minPercent: getMinValue(item, 0, null, 'percentFull'),
        entities: [
          {
            ...formatGroupedEntity(item)
          }
        ]
      });
    } else {
      const index = collection.entities.length;
      collection.entities.push({
        ...formatGroupedEntity(item)
      });
      collection.minDays = getMinValue(
        item,
        index,
        collection.minDays,
        'daysTillEmpty'
      );

      collection.minPercent = getMinValue(
        item,
        index,
        collection.minPercent,
        'percentFull'
      );
      collection.entityCustomFieldData = getCombinedEntityCustomFields(
        collection.entityCustomFieldData,
        item.entityCustomFieldData
      );
    }
  });
  return map;
}

/**
 * Groups list of feed entities by product id to create a list of products with entites
 */
export function formatFeedProducts(entities: Product[]): Product[] {
  return Array.from(getGroupedFeedProducts(entities)).map(
    product => product[1]
  );
}
