/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ComplianceSettings } from '@/store/models/MilkScore';
import {
  FeedMetrics,
  FuelMetricsTank,
  MilkMetrics,
  Product
} from '@/store/models/Product';
import TableColumn, { TableColumnFormatters } from '@/store/models/TableColumn';
import {
  getEntityName,
  getEntityTitle,
  getPluralEntityName,
  isApp
} from '../AppName';
import { Constants } from '../Constants';
import {
  GetEntityCustomColumns,
  GetSiteCustomColumns,
  GetVisibleEntityCustomColumns,
  GetVisibleSiteCustomColumns
} from '../CustomColumns';
import {
  formatMilkPercentFullDisplay,
  formatPercentFullDisplay,
  isLive
} from '../formatters/DisplayValueFormatters';
import { getComplianceScore } from '../MilkScoreUtils';
import { isPage } from '../PageUtils';
import { decamelizeFunction } from '../TableFormatters';
import { AllowedField } from '../types/AllowedFields';
import dayjs from 'dayjs';
import { isWarningMilkAge } from '../AlertUtils';
import { isSupplying } from '../MilkSupplyUtils';
export interface TableField {
  id: string;
  name: string;
  width: number;
  formattedValue?: (item: Product, entity: any) => string;
  sortable: boolean;
  columnStyling: string;
  textStyling: string;
  headerTextStyling?: string;
  visible: boolean;
}

export interface TableColumnFormatters2 {
  width?: number;
  searchKey?: (item: Product) => any;
  sortKey?: (item: Product, sortType: 'child' | 'parent') => any;
  parentFormattedValue: (item: Product, entity: any) => string;
  childFormattedValue: (item: Product, entity: any) => string;
  component?: Function;
  columnStyling?: string;
  textStyling?: string;
  headerTextStyling?: string;
}

export const tableColumnFormatters: {
  [key in AllowedField]: TableColumnFormatters;
} = {
  [AllowedField.CHECKBOX]: {
    name: AllowedField.CHECKBOX,
    label: () => '',
    visible: true,
    width: 40,
    parentFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    parentComponent: () => 'RouteCheckbox',
    childComponent: () => 'RouteCheckbox'
  },
  [AllowedField.SITE]: {
    name: AllowedField.SITE,
    label: () => 'Site',
    visible: true,
    width: 240,
    filterable: false,
    searchable: true,
    sortable: true,
    searchKey: (item: Product) => {
      return item.siteName;
    },
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by site name and entities by entity name
      return sortType == 'child'
        ? item.name?.toLowerCase()
        : item.siteName?.toLowerCase();
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    parentComponent: () => 'SiteNameField',
    childComponent: () => 'NestedEntity',
    textStyling: 'textOverflowEllipsis'
  },
  [AllowedField.VOLUME_PERCENT_BAR]: {
    name: AllowedField.VOLUME_PERCENT_BAR,
    label: () => '',
    visible: true,
    width: 240,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    parentComponent: () => 'VolumePercentBar',
    childComponent: () => 'VolumePercentBar'
  },
  [AllowedField.PERCENT_FULL]: {
    name: AllowedField.PERCENT_FULL,
    label: () => '%',
    visible: true,
    width: 60,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'parent' | 'child') => {
      //Sort product level by the combined percent value (milk) or max/min percent of entities (fuel/feed)
      //Sort entities by percent value
      if (
        isApp(Constants.PRODUCT_TYPE_MILK) &&
        ((!item.entities && !(item.metrics as MilkMetrics).containsMilk) ||
          (item.entities &&
            !(item.entities[0].metrics as MilkMetrics).containsMilk))
      ) {
        return 0;
      }
      if (
        sortType == 'child' ||
        isApp(Constants.PRODUCT_TYPE_MILK) ||
        !item.entities
      ) {
        return (item.metrics as MilkMetrics | FuelMetricsTank | FeedMetrics)
          .percentFull;
      } else {
        return (item.entities[0].metrics as FuelMetricsTank | FeedMetrics)
          .percentFull;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return isLive(entity)
        ? isApp(Constants.PRODUCT_TYPE_MILK)
          ? formatMilkPercentFullDisplay(entity)
          : formatPercentFullDisplay(entity)
        : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return isLive(entity)
        ? isApp(Constants.PRODUCT_TYPE_MILK)
          ? formatMilkPercentFullDisplay(entity)
          : formatPercentFullDisplay(entity)
        : '-';
    }
  },
  [AllowedField.WARNINGS]: {
    name: AllowedField.WARNINGS,
    label: () => 'Warnings',
    visible: true,
    width: 120,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'parent' | 'child') => {
      let sortSpot = 0;
      if (
        sortType == 'child' ||
        isApp(Constants.PRODUCT_TYPE_MILK) ||
        !item.entities
      ) {
        if (isApp(Constants.PRODUCT_TYPE_MILK)) {
          const metrics = item.metrics as MilkMetrics;
          const MPIOrSAMRScore = getComplianceScore(
            metrics.milkScores,
            item.entityCustomSettingsData as ComplianceSettings
          )?.rating;
          const fonterraScore = metrics.milkScore;
          const scoreToAdd = isSupplying(item)
            ? MPIOrSAMRScore ?? fonterraScore
            : 0;
          sortSpot += (scoreToAdd ?? 0) * 100;
          sortSpot += metrics.milkEnteredVat
            ? isWarningMilkAge(item)
              ? 5
              : 0
            : 0;
          sortSpot += item.faultStatus == Constants.FAULT_STATUS_REPAIR ? 1 : 0;
          sortSpot += metrics.state == 'supply' ? 0 : 3;
        } else {
          sortSpot +=
            (item.metrics as FuelMetricsTank | FeedMetrics).alert ?? 0;
        }
      } else {
        sortSpot +=
          (item.entities[0].metrics as FuelMetricsTank | FeedMetrics).alert ??
          0;
      }
      return sortSpot;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return isLive(entity) ? '' : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return isLive(entity) ? '' : '-';
    },
    parentComponent: () => 'WarningsIconsGroup',
    childComponent: () => 'WarningsIconsGroup',
    textStyling: 'text-center'
  },
  [AllowedField.ADDRESS]: {
    name: AllowedField.ADDRESS,
    label: () => 'Address',
    visible: true,
    width: 240,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      if (isPage('requests')) {
        return '';
      }
      return item.address ? item.address : 'No address to display';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return item.address ? item.address : '';
    }
  },
  [AllowedField.ENTITY_STATUS]: {
    name: AllowedField.ENTITY_STATUS,
    label: () => 'Status',
    visible: true,
    width: 140,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    parentComponent: (
      item: Product,
      entity: any,
      active: string,
      index: number
    ) => (index != -1 ? 'StatusBadge' : null),
    childComponent: () => 'StatusBadge'
  },
  [AllowedField.ENTITY_STATUS_CHANGE_DATE]: {
    name: AllowedField.ENTITY_STATUS_CHANGE_DATE,
    label: () => 'Status Changed At',
    visible: true,
    width: 250,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return (entity.entityStatusChangeDate ?? entity.liveAt) != null
        ? dayjs
            .unix(
              new Date(
                entity.entityStatusChangeDate ?? entity.liveAt
              ).getTime() / 1000
            )
            .format('D MMM h:mma')
        : '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return (entity.entityStatusChangeDate ?? entity.liveAt) != null
        ? dayjs
            .unix(
              new Date(
                entity.entityStatusChangeDate ?? entity.liveAt
              ).getTime() / 1000
            )
            .format('D MMM h:mma')
        : '';
    }
  },
  [AllowedField.NUM_ENTITIES]: {
    name: AllowedField.NUM_ENTITIES,
    label: () => {
      return `${getPluralEntityName(true)}`;
    },
    visible: true,
    width: 100,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'parent' | 'child') => {
      return item && item.entities ? item.entities.length : undefined;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item && item.entities ? item.entities.length.toString() : '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    }
  },
  [AllowedField.CONTRIBUTION_TIER]: {
    name: AllowedField.CONTRIBUTION_TIER,
    label: () => 'Contribution Tier',
    visible: true,
    width: 160,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'parent' | 'child') => {
      return item && item.contributionTier
        ? decamelizeFunction(item.contributionTier)
        : undefined;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item && item.contributionTier
        ? decamelizeFunction(item.contributionTier)
        : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    }
  },
  [AllowedField.REPAIR_ICON]: {
    name: AllowedField.REPAIR_ICON,
    label: () => 'Warnings',
    visible: true,
    width: 80,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    parentComponent: () => 'RepairIcon',
    childComponent: () => 'RepairIcon',
    textStyling: 'text-center'
  },
  [AllowedField.ACTION_TAKEN]: {
    name: AllowedField.ACTION_TAKEN,
    label: () => 'Action taken',
    visible: true,
    width: 500,
    filterable: false,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'parent' | 'child') => {
      return item?.entityComments != undefined || item?.entityComments != null;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return '';
    },
    parentComponent: () => 'Comment',
    childComponent: () => 'Comment'
  },
  [AllowedField.ENTITY_NAME]: {
    name: AllowedField.ENTITY_NAME,
    label: () => {
      return `${getEntityName(true)}`;
    },
    visible: true,
    width: 100,
    filterable: false,
    searchable: false,
    sortable: false,
    parentFormattedValue: (item: Product, entity: any) => {
      return entity ? getEntityTitle(entity) : '-';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return entity ? getEntityTitle(entity) : '-';
    }
  }
};

const defaultFormatter: TableColumnFormatters2 = {
  parentFormattedValue: (item: Product, entity: any) => {
    return '';
  },
  childFormattedValue: (item: Product, entity: any) => {
    return '';
  }
};

export function customSiteColumnsFormatter(
  columnName: string,
  type: string
): TableColumnFormatters2 {
  return {
    width: 160,
    searchKey: (item: Product) => {
      if (item.customFieldData)
        return item.customFieldData[columnName] ?? undefined;
    },
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by custom field (number/string) and entities by entity name
      if (sortType == 'child') {
        return item.name?.toLowerCase();
      } else {
        if (item.customFieldData) {
          return type == 'Numeric entry'
            ? +item.customFieldData[columnName] ?? undefined
            : item.customFieldData[columnName] ?? undefined;
        }
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item.customFieldData && item.customFieldData[columnName] != null
        ? item.customFieldData[columnName]
        : '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return !isApp(Constants.PRODUCT_TYPE_MILK) &&
        item.customFieldData &&
        item.customFieldData[columnName] != null
        ? item.customFieldData[columnName]
        : '';
    }
  };
}

export function customEntityColumnsFormatter(
  columnName: string,
  type: string
): TableColumnFormatters2 {
  return {
    width: 160,
    searchKey: (item: Product) => {
      if (item.entityCustomFieldData)
        return item.entityCustomFieldData[columnName] ?? undefined;
    },
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by max/min custom field (number/string) of entities
      //Sort entities by custom field (number/string)
      if (sortType == 'child') {
        if (item.entityCustomFieldData) {
          return type == 'Numeric entry'
            ? item.entityCustomFieldData[columnName]
              ? +item.entityCustomFieldData[columnName]
              : undefined
            : item.entityCustomFieldData[columnName] ?? undefined;
        }
      } else {
        if (item.entities && item.entities[0].entityCustomFieldData) {
          return type == 'Numeric entry'
            ? item.entities[0].entityCustomFieldData[columnName]
              ? +item.entities[0].entityCustomFieldData[columnName]
              : undefined
            : item.entities[0].entityCustomFieldData[columnName] ?? undefined;
        }
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return entity.entityCustomFieldData &&
        entity.entityCustomFieldData[columnName] != null
        ? entity.entityCustomFieldData[columnName]
        : '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return entity.entityCustomFieldData &&
        entity.entityCustomFieldData[columnName] != null
        ? entity.entityCustomFieldData[columnName]
        : '';
    }
  };
}

export function GetCustomColumnsFormatters(
  formatter: Function,
  columns: TableColumn[]
) {
  return columns.reduce(function(map: any, column) {
    map[column.name] = {
      ...column,
      label: () => column.label,
      ...formatter(column.name, column.type ?? '')
    };
    return map;
  }, {});
}

export async function GetVisibleCustomColumnsFormattersDict() {
  const customSiteColumns = await GetVisibleSiteCustomColumns();
  const customEntityColumns = await GetVisibleEntityCustomColumns();
  const customSiteColumnsWithFormatters = GetCustomColumnsFormatters(
    customSiteColumnsFormatter,
    customSiteColumns
  );
  const customEntityColumnsWithFormatters = GetCustomColumnsFormatters(
    customEntityColumnsFormatter,
    customEntityColumns
  );

  return {
    ...customSiteColumnsWithFormatters,
    ...customEntityColumnsWithFormatters
  };
}

export async function GetVisibleCustomColumnsFormatters() {
  const columns = await GetVisibleCustomColumnsFormattersDict();
  return Object.values(columns);
}

export async function GetSiteCustomColumnsWithFormatters() {
  const customSiteColumns = await GetSiteCustomColumns();
  return GetCustomColumnsFormatters(
    customSiteColumnsFormatter,
    customSiteColumns
  );
}

export async function GetEntityCustomColumnsWithFormatters() {
  const customEntityColumns = await GetEntityCustomColumns();
  return GetCustomColumnsFormatters(
    customEntityColumnsFormatter,
    customEntityColumns
  );
}

export function GetDefaultColumns() {
  return Object.fromEntries(
    Object.entries(tableColumnFormatters).filter(column => {
      return column[0].includes('Default');
    })
  );
}

export function GetTableColumnFormatters(fields: AllowedField[]) {
  const baseColumnsWithFormatters = fields.map(column => {
    return tableColumnFormatters[column];
  });
  return baseColumnsWithFormatters;
}

export async function GetVisibleTableColumnFormatters(
  fields: AllowedField[],
  addCustomFields: boolean
) {
  const baseColumnsWithFormatters = fields.map(column => {
    return tableColumnFormatters[column];
  });
  if (addCustomFields) {
    const customColumnsWithFormatters = await GetVisibleCustomColumnsFormatters();
    return [...baseColumnsWithFormatters, ...customColumnsWithFormatters];
  } else {
    return baseColumnsWithFormatters;
  }
}
