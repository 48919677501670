import { WaterFilters } from '@/store/models/Filters';
import {
  Product,
  WaterMeterMetrics,
  WaterTankMetrics
} from '@/store/models/Product';
import { FiltersClass } from './FiltersClass';
import { reduceMaxValue } from '../formatters/EntitiesFormatter';
import { isWaterMeter, isWaterTank } from '../WaterUtils';

export class WaterFiltersClass extends FiltersClass<WaterFilters> {
  public maxUsageToday = 0;
  public maxUsageYesterday = 0;
  public maxCurrentUsage = 0;
  public maxPercent = 0;
  constructor() {
    super('water', {
      volumeRange: [0, 0],
      percentRange: [0, 0],
      currentUsageRange: [0, 0],
      usageTodayRange: [0, 0],
      usageYesterdayRange: [0, 0],
      customSiteFilters: [],
      customEntityFilters: [],
      capacityRange: []
    });
  }

  public filterProducts(products: Product[]) {
    let filtered = [...products];
    if (this.filters.percentRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterTankMetrics).percent >=
          this.filters.percentRange[0]
      );
    }

    if (this.filters.percentRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterTankMetrics).percent <=
          this.filters.percentRange[1]
      );
    }

    if (this.filters.currentUsageRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMeterMetrics).currentUsage >=
          this.filters.currentUsageRange[0]
      );
    }

    if (this.filters.currentUsageRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMeterMetrics).currentUsage <=
          this.filters.currentUsageRange[1]
      );
    }

    if (this.filters.usageTodayRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMeterMetrics).usageToday >=
          this.filters.usageTodayRange[0]
      );
    }

    if (this.filters.usageTodayRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMeterMetrics).usageToday <=
          this.filters.usageTodayRange[1]
      );
    }
    if (this.filters.usageYesterdayRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMeterMetrics).usageYesterday >=
          this.filters.usageYesterdayRange[0]
      );
    }

    if (this.filters.usageYesterdayRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMeterMetrics).usageYesterday <=
          this.filters.usageYesterdayRange[1]
      );
    }
    filtered = super.filterProducts(filtered);
    return filtered;
  }

  public get filtersSet(): boolean {
    return (
      (this.anyFiltersSet || this.lowerBoundSet || this.upperBoundSet) &&
      this.tankParametersSet
    );
  }

  public get lowerBoundSet(): boolean {
    return [
      this.filters.percentRange[0],
      this.filters.currentUsageRange[0],
      this.filters.usageTodayRange[0],
      this.filters.usageYesterdayRange[0]
    ].some(value => value != 0);
  }

  public get upperBoundSet(): boolean {
    return [
      this.checkRange(this.filters.percentRange, this.maxPercent),
      this.checkRange(this.filters.currentUsageRange, this.maxCurrentUsage),
      this.checkRange(this.filters.usageTodayRange, this.maxUsageToday),
      this.checkRange(this.filters.usageYesterdayRange, this.maxUsageYesterday)
    ].includes(true);
  }

  public setMaxUsageToday(maxVal: number) {
    if (
      this.filters.usageTodayRange[1] == this.maxUsageToday ||
      this.filters.usageTodayRange[1] == null ||
      this.filters.usageTodayRange[1] == 0
    ) {
      this.filters.usageTodayRange[1] = maxVal;
    }

    this.maxUsageToday = maxVal;
  }

  public setMaxUsageYesterday(maxVal: number) {
    if (
      this.filters.usageYesterdayRange[1] == this.maxUsageYesterday ||
      this.filters.usageYesterdayRange[1] == null ||
      this.filters.usageYesterdayRange[1] == 0
    ) {
      this.filters.usageYesterdayRange[1] = maxVal;
    }

    this.maxUsageYesterday = maxVal;
  }

  public setMaxCurrentUsage(maxVal: number) {
    if (
      this.filters.currentUsageRange[1] == this.maxCurrentUsage ||
      this.filters.currentUsageRange[1] == null ||
      this.filters.currentUsageRange[1] == 0
    ) {
      this.filters.currentUsageRange[1] = maxVal;
    }

    this.maxCurrentUsage = maxVal;
  }

  public setMaxPercent(maxVal: number) {
    if (
      this.filters.percentRange[1] == this.maxPercent ||
      this.filters.percentRange[1] == null ||
      this.filters.percentRange[1] == 0
    ) {
      this.filters.percentRange[1] = maxVal;
    }

    this.maxPercent = maxVal;
  }

  public setMaxRanges(products: Product[]) {
    if (isWaterMeter()) {
      this.setMaxUsageToday(Math.ceil(reduceMaxValue(products, 'usageToday')));
      this.setMaxUsageYesterday(
        Math.ceil(reduceMaxValue(products, 'usageYesterday'))
      );
      this.setMaxCurrentUsage(
        Math.ceil(reduceMaxValue(products, 'currentUsage'))
      );
    } else if (isWaterTank()) {
      this.setMaxPercent(Math.ceil(reduceMaxValue(products, 'percent')));
    }
  }

  public get tankParametersSet(): boolean {
    if (isWaterMeter()) {
      return (
        this.maxUsageToday != 0 &&
        this.maxUsageYesterday != 0 &&
        this.maxCurrentUsage != 0
      );
    } else if (isWaterTank()) {
      return this.maxPercent != 0;
    }
    return false;
  }
}
