import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig
} from 'axios';
import User from '@/store/modules/User';
import { formatError } from './Utils';
import ApiError from './models/ApiError';

// TODO: move this to interceptors folder, along with an error interceptor.
const AuthInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = User.token.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const OnResponseSuccess = (response: AxiosResponse): AxiosResponse => response;

const OnResponseFailure = (error: AxiosError): Promise<ApiError> => {
  return Promise.reject(formatError(error));
};

const httpClient: Readonly<AxiosInstance> = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

httpClient.interceptors.request.use(AuthInterceptor);
httpClient.interceptors.response.use(OnResponseSuccess, OnResponseFailure);

export default httpClient;
